<template>
  <div>
    <v-row justify="center">
      <v-img
        contain
        src="../assets/no_access.png"
        height="200"
        width="200"
      ></v-img>
    </v-row>
    <v-row justify="center">
      <strong>No Previlages given at the moment</strong>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "noAccess",
};
</script>