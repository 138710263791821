<template>
  <div>
    <v-row justify="center">
      <v-img
        contain
        src="../assets/no_access.png"
        height="200"
        width="200"
      ></v-img>
    </v-row>
    <v-row justify="center">
      <strong>Not Authorized, Please login or signup</strong>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="ma-2"
        outlined
        small
        color="indigo"
        @click="
          $router.push({ name: 'login' }).catch((err) => {
            throw err;
          })
        "
      >
        Login
      </v-btn>
      <v-btn
        class="ma-2"
        outlined
        small
        color="indigo"
        @click="
          $router.push({ name: 'signup' }).catch((err) => {
            throw err;
          })
        "
      >
        signup
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "noAccess",
};
</script>