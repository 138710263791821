<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Settings</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white text-center pt-4 pb-10 pa-2"
          id="percent"
          style="border-radius: 3px"
        >
          <v-layout row wrap id="customPercent">
            <v-flex xs12 class="mb-4">
              <v-menu rounded="lg">
                <template v-slot:activator="{ attrs, on }">
                  <span class="mr-3 pt-3">Choose Game: </span>
                  <v-btn
                    text
                    class="pl-16 pr-16 text-capitalize"
                    v-bind="attrs"
                    v-on="on"
                    outlined=""
                  >
                    {{ choosedGame }}
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item-group
                    v-model="group"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <v-list-item
                      v-for="item in items"
                      :key="item"
                      @click="choosedGame = item"
                    >
                      <v-list-item-title v-text="item"></v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-flex>
            <v-flex xs12>
              <v-btn
                :loading="clearLoading"
                small
                text
                class="primary pl-5 pr-5 mr-10"
                @click="clearData"
              >
                <span class="text-capitalize">Clear Data</span>
              </v-btn>

              <v-btn
                dark
                small
                text
                class="orange darken-2 pl-5 pr-5"
                @click="
                  constantDialog = true;
                  brandName = commonFeatures[0].brand_name;
                  phoneNumber = commonFeatures[0].phone;
                  others = commonFeatures[0].others;
                "
              >
                <span class="text-capitalize">Change constants</span>
              </v-btn>
              <p
                class="text-center mt-6 grey--text text--darken-1 pl-2 pr-2"
                style="font-size: 17px"
              >
                To change the chance, select from the custom percentiles or fill
                the field with your interest
              </p>
              <div id="chooseDate" class="ml-2 mr-2 pl-5 pr-5">
                <v-layout row wrap justify-space-around class="mt-3">
                  <v-flex xs10 class="pr-2">
                    <v-text-field
                      label="Current Keno Chance (%)"
                      required
                      dense
                      outlined
                      v-model="currentPercentField"
                      class="mt-1 text-center"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pt-2">
                    <v-btn
                      :loading="percentLoading"
                      small
                      text
                      class="primary pl-5 pr-5"
                      @click="savePercent(currentPercentField)"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>

                  <v-flex xs10 class="pr-2">
                    <v-text-field
                      label="Current Spin Chance (%)"
                      required
                      dense
                      outlined
                      v-model="spinCurrentPercentField"
                      class="mt-1 text-center"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 class="pt-2">
                    <v-btn
                      :loading="percentLoading"
                      small
                      text
                      class="primary pl-5 pr-5"
                      @click="savePercent(spinCurrentPercentField)"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </div>
              <v-layout row wrap class="text-center mt-2 ma-1">
                <v-flex
                  xs4
                  md3
                  v-for="number in customPercent"
                  :key="number"
                  class="mt-0 mb-1"
                >
                  <v-card class="mr-1" id="numbers">
                    <v-card-text>
                      <div>
                        <a @click="savePercent(number)">
                          <div class="pt-1">
                            <span class="pa-1">{{ number }}%</span>
                          </div>
                        </a>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>

        <v-dialog v-model="constantDialog" persistent max-width="420">
          <v-card class="text-center pt-1">
            <v-card-title> Change constants </v-card-title>
            <v-form @submit.prevent="">
              <v-card-text>
                <v-text-field
                  style="font-size: 11px"
                  v-model="brandName"
                  label="Brand Name"
                  dense
                  outlined
                  hide-details="true"
                  class="ma-4"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="phoneNumber"
                  label="Phone Number"
                  dense
                  outlined
                  hide-details="true"
                  class="ma-4"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="others"
                  label="Write Description"
                  dense
                  outlined
                  hide-details="true"
                  class="ma-4"
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="constantLoading"
                  color="green darken-1"
                  class="text-capitalize"
                  text
                  type="submit"
                  @click="saveConstants"
                >
                  Save Changes
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="constantDialog = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,

      currentPercentField: "",
      spinCurrentPercentField: "",
      percentLoading: false,
      clearLoading: false,
      commonFeatures: [],
      constantDialog: false,
      constantLoading: false,
      brandName: "",
      phoneNumber: "",
      others: "",
      customPercent: [
        50, 60, 70, 75, 80, 85, 90, 95, 100, 110, 120, 125, 130, 140, 150, 160,
        170, 180, 190, 200, 250, 300, 350, 400, 500, 600, 700, 800, 900, 1000,
        1100, 1200, 1300, 1400, 1500,
      ],
      items: ["All", "Keno", "Spin"],
      choosedGame: "All",
      group: null,
      loadingCount: 0,
    };
  },

  methods: {
    async clearData() {
      if (this.$store.state.whichToShow == "keno") {
        const clearConfirm = confirm(
          "Are you sure you want to clear all Keno games?"
        );
        if (clearConfirm) {
          this.clearLoading = true;
          try {
            var clearDataResult = await axios({
              method: "POST",
              url: this.$store.state.adminURL,

              data: {
                query: `mutation{
               clearData{
               statusMessage

               }
              }
              `,
              },
            });

            if (
              clearDataResult.data.data.clearData[0].statusMessage.toString() ==
              "true"
            ) {
              alert("Data cleared completely!");
            } else alert("Not cleared try again");
          } catch (err) {
            alert(err);
          }
          this.clearLoading = false;
        }
      } else {
        const clearConfirm = confirm(
          "Are you sure you want to clear all Spin games?"
        );
        if (clearConfirm) {
          this.clearLoading = true;
          try {
            var clearDataResult = await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
               clearData{
               statusMessage

               }
              }
              `,
              },
            });

            if (
              clearDataResult.data.data.clearData[0].statusMessage.toString() ==
              "true"
            ) {
              alert("Data cleared completely!");
            } else alert("Not cleared try again");
          } catch (err) {
            alert(err);
          }
          this.clearLoading = false;
        }
      }
    },
    async savePercent(percent) {
      if (percent != "") {
        this.percentLoading = true;
        var changePercentResult = "";
        try {
          if (this.choosedGame == "Keno") {
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.adminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
          } else if (this.choosedGame == "Spin") {
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
          } else if (this.choosedGame == "All") {
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.adminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
            changePercentResult = await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
               changePercent(percent_value:${percent}){
               statusMessage

               }
              }
              `,
              },
            });
          }

          if (changePercentResult != "") {
            if (
              changePercentResult.data.data.changePercent[0].statusMessage.toString() !=
              "true"
            ) {
              alert("No change, please try again");
            } else await this.getCurrentPercent();
          }
        } catch (err) {
          alert(err);
        }
        this.percentLoading = false;
      } else alert("Please fill the field");
    },
    async saveConstants() {
      this.constantLoading = true;
      try {
        var changeConstatnsResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
               changeConstants(
                brand_name:"${this.brandName}",
                phone:"${this.phoneNumber}",
                others:"${this.others}",
                ){
               statusMessage

               }
              }
              `,
          },
        });

        if (
          changeConstatnsResult.data.data.changeConstants[0].statusMessage.toString() ==
          "true"
        ) {
          this.constantDialog = false;
          this.getCurrentPercent();
        } else alert("No change, please try again");
      } catch (err) {
        alert(err);
      }
      this.constantLoading = false;
    },
    async getCurrentPercent() {
      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var currentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getPercent{
               percent_id
               percent_value
               }
              }
              `,
          },
        });

        this.currentPercentField =
          currentPercentResult.data.data.getPercent[0].percent_value;
      } catch (err) {
        alert(err);
      }

      try {
        var spinCurrentPercentResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
               getPercent{
               percent_id
               percent_value
               }
              }
              `,
          },
        });

        this.spinCurrentPercentField =
          spinCurrentPercentResult.data.data.getPercent[0].percent_value;
      } catch (err) {
        alert(err);
      }

      try {
        var commonResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getCommonFeatures{
                 common_features_id
                 brand_name
                 phone
                 others
                }
                

              }
              `,
          },
        });

        this.commonFeatures = commonResult.data.data.getCommonFeatures;
        this.brandName = this.commonFeatures[0].brand_name;
        this.phoneNumber = this.commonFeatures[0].phone;
        this.others = this.commonFeatures[0].others;
      } catch (err) {
        alert(err);
      }

      this.loadingCount++;
      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getCurrentPercent();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#percent {
  max-width: 800px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

#numbers:hover {
  background-color: rgb(235, 228, 228);
}
#customPercent {
  max-width: 450px;
  margin: auto;
}
</style>
