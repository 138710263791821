import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/components/home";
import login from "@/components/login";
import categories from "@/components/categories";

import setting from "@/components/setting";

import help from "@/components/help";

import products from "@/components/postedProducts";
import addProducts from "@/components/addProduct";
import productDetails from "@/components/productDetails";
import editProduct from "@/components/editProduct";
import ui from "@/components/ui";
import uiAdder from "@/components/uiAdder";
import trendy from "@/components/trendyProducts";
import orders from "@/components/orders";
import users from "@/components/users";
import blog from "@/components/blog";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/Dashboard",
    name: "home",
    component: home,
  },

  {
    path: "/categories",
    name: "categories",
    component: categories,
  },

  {
    path: "/products",
    name: "products",
    component: products,
  },

  {
    path: "/addProduct",
    name: "addProduct",
    component: addProducts,
  },
  {
    path: "/products/details",
    name: "productDetails",
    component: productDetails,
    props: true,
  },
  {
    path: "/products/editProduct",
    name: "editProduct",
    component: editProduct,
    props: true,
  },

  {
    path: "/ui",
    name: "ui",
    component: ui,
  },

  {
    path: "/add-ui",
    name: "uiAdder",
    component: uiAdder,
  },
  {
    path: "/trendy-products",
    name: "trendy",
    component: trendy,
  },

  {
    path: "/orders",
    name: "orders",
    component: orders,
  },

  {
    path: "/Blog",
    name: "blog",
    component: blog,
  },

  {
    path: "/users",
    name: "users",
    component: users,
  },

  {
    path: "/settings",
    name: "setting",
    component: setting,
  },

  {
    path: "/help",
    name: "help",
    component: help,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
