var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$store.state.admin.privilage == 'Super Admin' ||
    _vm.$store.state.admin.privilage == 'Sales Manager' ||
    _vm.$store.state.admin.privilage == 'Sales'
  )?_c('v-container',{staticClass:"container",staticStyle:{"font-size":"13px"}},[_c('p',{staticClass:"headline white--text ml-5"},[_vm._v("Products")]),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deletePopUp),callback:function ($$v) {_vm.deletePopUp=$$v},expression:"deletePopUp"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('strong',[_vm._v(" Delete Product ")])]),_c('v-card-text',[_vm._v("Are You Sure to delete this product ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.deleteProduct()}}},[_c('v-icon',[_vm._v("delete")]),_vm._v("Yes ")],1),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.deletePopUp = false}}},[_c('v-icon',[_vm._v("arrow_back_ios")]),_vm._v(" No ")],1)],1)],1)],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){(_vm.snackbar = false), (_vm.status = '')}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}],null,false,722954859),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]),_c('div',{staticClass:"container white pa-16 pl-0 pr-2 pt-1",staticStyle:{"border-radius":"3px","font-size":"13px"},attrs:{"id":"report"}},[_c('v-row',{staticClass:"pt-4"},[_c('v-flex',{staticClass:"text-center",attrs:{"xs12":"","sm6":"","md3":""}},[_c('span',{staticClass:"headline text-center"},[_vm._v(" "+_vm._s(_vm.postedProducts)+" ")]),_c('br'),_c('span',[_vm._v("Posted Products")])]),_c('v-flex',{staticClass:"text-center pt-2",attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-btn',{staticClass:"grey lighten-2 text-capitalize",attrs:{"text":""},on:{"click":function($event){_vm.$router
              .push({
                name: 'addProduct',
              })
              .catch((err) => {
                throw err;
              })}}},[_vm._v("Add Product")])],1)],1),_c('v-layout',{attrs:{"justify-space-around":""}},[_c('v-flex',{staticClass:"mt-2 align-content-center pt-5",attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-text-field',{attrs:{"color":"deep-purple accent-4","label":"Search products","append-icon":"search","hide-details":true,"clearable":"","outlined":"","required":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-flex',{staticClass:"mt-2 align-content-center pt-5",attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-select',{attrs:{"items":_vm.sortingMethods,"label":"sort","append-icon":"sort","hide-details":true,"clearable":"","outlined":"","required":"","dense":""},on:{"change":_vm.sortProducts},model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingProducts),expression:"loadingProducts"}],staticClass:"text-center"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"grey","indeterminate":""}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadingProducts),expression:"!loadingProducts"}],staticClass:"pa-2 pl-4"},_vm._l((_vm.allProducts),function(product,i){return _c('v-flex',{key:i,staticClass:"pl-1 mt-2 pr-1",attrs:{"xs12":"","sm4":"","md2":""}},[_c('v-card',{attrs:{"loading":false}},[_c('div',{staticClass:"pl-1 text-left",on:{"click":function($event){return _vm.productDetails(product)},"mouseenter":_vm.changeCursor}},[_c('blur-hash-image',{attrs:{"hash":product.blurhash
                  .split('*product#')[0]
                  .split(',pimg')[0]
                  .trim(),"src":product.image.split('*product#')[0].split(',pimg')[0].trim(),"width":"110px"}}),_c('v-card-text',{staticClass:"text-truncate",staticStyle:{"max-width":"170px","font-size":"16px"}},[_vm._v(_vm._s(product.title_english)+" "),_c('br'),_vm._v(" "+_vm._s(product.title_amharic)+" "),_c('br'),_vm._v(" "+_vm._s(product.getSubCategory[0].getCategory[0].title_english)+" "),_c('br'),_c('br'),_vm._v(" Price:   "),_c('strong',[_vm._v(_vm._s(product.price)+" ETB")]),_c('br'),_vm._v(" Total Stock:   "),_c('strong',[_vm._v(" "+_vm._s(product.stock))])])],1),_c('v-divider',{}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"red--text",attrs:{"text":"","fab":"","small":""},on:{"click":function($event){(_vm.deletePopUp = true), (_vm.deletingProduct = product)}}},[_c('v-icon',[_vm._v("delete")])],1),_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){return _vm.editProduct(product)}}},[_c('v-icon',{staticClass:"blue--text"},[_vm._v("edit")])],1)],1),_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1)],2)],1)}),1),_c('v-layout',{attrs:{"justify-space-around":""}},[(!_vm.thereisMore)?_c('v-flex',{staticClass:"mt-5",attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-card',[_c('v-card-text',[_vm._v(" No More Products ")])],1)],1):_vm._e()],1)],1)],1):_c('div',{staticClass:"text-center mt-16",attrs:{"id":"error"}},[_c('p',{staticClass:"red--text text-center"},[_vm._v("Access Denied")])])
}
var staticRenderFns = []

export { render, staticRenderFns }