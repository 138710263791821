<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager' ||
      $store.state.admin.privilage == 'Sales'
    "
  >
    <p class="headline white--text ml-5">Add Product</p>

    <v-flex
      xs12
      class="container white pa-16 pl-2 pr-2 pt-1"
      id="report"
      style="border-radius: 3px; font-size: 13px"
    >
      <v-form class="ma-2" ref="form" v-model="valid" lazy-validation>
        <!-- <p :class="color" >{{ status }}</p> -->

        <!-- snackbar -->
        <v-snackbar v-model="errors" timeout="20000000">
          {{ status }}

          <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="errors = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
        <!-- snackbar ends -->

        <!-- dialog for adding -->
        <v-dialog v-model="dialog" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              {{ addingStatus }}
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- dialog for adding ends -->

        <!-- dialog for selecting measures -->
        <v-dialog v-model="saleTypesDialog" width="300">
          <v-card class="mx-auto" max-width="300" tile>
            <v-list>
              <v-subheader>Measures</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in saleTypes"
                  :key="i"
                  @click="selectMeasures(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.measure_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- dialog for selecting measures ends -->

        <!-- dialog for selecting measures2 -->
        <v-dialog v-model="saleTypesDialog2" width="300">
          <v-card class="mx-auto" max-width="300" tile>
            <v-list>
              <v-subheader>Measures</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in saleTypes"
                  :key="i"
                  @click="selectMeasures2(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.measure_name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- dialog for selecting measures2 ends -->

        <!-- dialog for selecting spec1 -->
        <v-dialog v-model="spec1dialog" width="300">
          <v-card class="mx-auto" max-width="300" tile>
            <v-list>
              <v-subheader>Specs</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in measure1Specs"
                  :key="i"
                  @click="selectSpec1(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- dialog for selecting spec1 ends -->

        <!-- dialog for selecting spec2 -->
        <v-dialog v-model="spec2dialog" width="300">
          <v-card class="mx-auto" max-width="300" tile>
            <v-list>
              <v-subheader>Specs</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in measure2Specs"
                  :key="i"
                  @click="selectSpec2(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- dialog for selecting spec2 ends -->

        <!-- dialog for selecting defaultSpec1 -->
        <v-dialog v-model="defaultspec1dialog" width="300">
          <v-card class="mx-auto" max-width="300" tile>
            <v-list>
              <v-subheader>Specs</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in measure1Specs"
                  :key="i"
                  @click="selectDefaultSpec1(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- dialog for selecting defaultspec1 ends -->

        <!-- dialog for selecting defaultspec2 -->
        <v-dialog v-model="defaultspec2dialog" width="300">
          <v-card class="mx-auto" max-width="300" tile>
            <v-list>
              <v-subheader>Specs</v-subheader>
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in measure2Specs"
                  :key="i"
                  @click="selectDefaultSpec2(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-dialog>
        <!-- dialog for selecting defaultspec2 ends -->

        <v-row class="justify-space-between">
          <v-flex xs12 class="ma-5 ml-1">
            <div
              class="mr-5"
              elevation="0"
              light
              @dragend="OnDragEnter"
              @dragleave="OnDragLeave"
              @dragover.prevent
              @drop="OnDrop"
            >
              <input
                type="file"
                multiple
                accept="image/*"
                ref="selectImage"
                style="display: none"
                @change="OnInputFile"
              />
              <input
                type="file"
                accept="image/*"
                ref="editImage1"
                style="display: none"
                @change="OnEditImage1"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage2"
                style="display: none"
                @change="OnEditImage2"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage3"
                style="display: none"
                @change="OnEditImage3"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage4"
                style="display: none"
                @change="OnEditImage4"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage5"
                style="display: none"
                @change="OnEditImage5"
              />

              <!-- <v-row class="justify-center">
                <v-flex xs2>
                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2"
                    :src="image1"
                    height="142"
                    contain
                    v-if="image1 != ''"
                    @click="changeImage(0, 'n')"
                  ></v-img>

                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2 text-center"
                    v-if="image1 == ''"
                    @click="simulateInputfileClicked(0)"
                  >
                   
                    <v-icon centered class="mt-10 grey--text"
                      >camera_alt</v-icon
                    >
                    <br />
                    <br />
                    <br />
                    <br />
                    <p class="text-center grey--text">Image 1</p>
                   
                  </div>
                </v-flex>

                <v-flex xs2>
                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2"
                    :src="image2"
                    height="142"
                    contain
                    v-if="image2 != ''"
                    @click="changeImage(1, 'n')"
                  ></v-img>
                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2 text-center"
                    v-if="image2 == ''"
                    @click="simulateInputfileClicked(0)"
                  >
                    
                    <v-icon centered class="mt-10 grey--text"
                      >camera_alt</v-icon
                    >
                    <br />
                    <br />
                    <br />
                    <br />
                    <p class="text-center grey--text">Image 2</p>
                   
                  </div>
                </v-flex>

                <v-flex xs2>
                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2"
                    :src="image3"
                    height="142"
                    contain
                    v-if="image3 != ''"
                    @click="changeImage(2, 'n')"
                  ></v-img>
                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2 text-center"
                    v-if="image3 == ''"
                    @click="simulateInputfileClicked(0)"
                  >
                  
                    <v-icon centered class="mt-10 grey--text"
                      >camera_alt</v-icon
                    >
                    <br />
                    <br />
                    <br />
                    <br />
                    <p class="text-center grey--text">Image 3</p>
                    
                  </div>
                </v-flex>

                <v-flex xs2>
                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2"
                    :src="image4"
                    height="142"
                    contain
                    v-if="image4 != ''"
                    @click="changeImage(3, 'n')"
                  ></v-img>
                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2 text-center"
                    v-if="image4 == ''"
                    @click="simulateInputfileClicked(0)"
                  >
                   
                    <v-icon centered class="mt-10 grey--text"
                      >camera_alt</v-icon
                    >
                    <br />
                    <br />
                    <br />
                    <br />
                    <p class="text-center grey--text">Image 4</p>
                  
                  </div>
                </v-flex>

                <v-flex xs2>
                  <v-img
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2"
                    :src="image5"
                    height="142"
                    contain
                    v-if="image5 != ''"
                    @click="changeImage(4, 'n')"
                  ></v-img>
                  <div
                    @mouseenter="changeCursor"
                    id="imageCamera"
                    class="ml-2 mr-2 text-center"
                    v-if="image5 == ''"
                    @click="simulateInputfileClicked(0)"
                  >
                    
                    <v-icon centered class="mt-10 grey--text"
                      >camera_alt</v-icon
                    >
                    <br />
                    <br />
                    <br />
                    <br />
                    <p class="text-center grey--text">Image 5</p>
                   
                  </div>
                </v-flex>

              
              </v-row>
              <v-card-title
                v-if="files.length < 5"
                class="justify-center"
                style="font-size: 12px"
              >
                Drag images Here
              </v-card-title>
              <v-card-title
                v-else
                class="justify-center"
                style="font-size: 12px"
              >
                Click images to change it
              </v-card-title> -->
            </div>
            <!-- 
            <div v-if="colorCounter != 0">
              <input
                v-if="1 == 1"
                type="file"
                multiple
                accept="image/*"
                ref="selectImageA"
                style="display: none"
                @change="OnInputFile"
              />
              <input
                type="file"
                accept="image/*"
                ref="editImage11"
                style="display: none"
                @change="OnEditImage1"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage21"
                style="display: none"
                @change="OnEditImage2"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage31"
                style="display: none"
                @change="OnEditImage3"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage41"
                style="display: none"
                @change="OnEditImage4"
              />

              <input
                type="file"
                accept="image/*"
                ref="editImage51"
                style="display: none"
                @change="OnEditImage5"
              />
              <div
                class="mr-5"
                elevation="0"
                light
                @dragend="OnDragEnter"
                @dragleave="OnDragLeave"
                @dragover.prevent
                @drop="OnDrop"
                v-for="item in colorCounter"
                :key="item"
              >
                <v-row class="justify-center">
                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="image1"
                      height="142"
                      contain
                      v-if="image1 != ''"
                      @click="changeImage(0, 'ad')"
                    ></v-img>

                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="image1 == ''"
                      @click="simulateInputfileClicked(1)"
                    >
                     
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 1</p>
                   
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="image2"
                      height="142"
                      contain
                      v-if="image2 != ''"
                      @click="changeImage(1, 'ad')"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="image2 == ''"
                      @click="simulateInputfileClicked(1)"
                    >
                    
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 2</p>
                   
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="image3"
                      height="142"
                      contain
                      v-if="image3 != ''"
                      @click="changeImage(2, 'ad')"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="image3 == ''"
                      @click="simulateInputfileClicked(1)"
                    >
                     
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 3</p>
                     
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="image4"
                      height="142"
                      contain
                      v-if="image4 != ''"
                      @click="changeImage(3, 'ad')"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="image4 == ''"
                      @click="simulateInputfileClicked(1)"
                    >
                     
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 4</p>
                    
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="image5"
                      height="142"
                      contain
                      v-if="image5 != ''"
                      @click="changeImage(4, 'ad')"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="image5 == ''"
                      @click="simulateInputfileClicked(1)"
                    >
                     
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 5</p>
                      
                    </div>
                  </v-flex>
                  <v-flex xs1>
                    <div class="text-right mt-15 pt-12">
                      <a right @click="colorCounter--"
                        ><v-icon large class="red--text">remove</v-icon></a
                      >
                    </div>
                  </v-flex>
                
                </v-row>
                <v-card-title
                  v-if="files.length < 5"
                  class="justify-center"
                  style="font-size: 12px"
                >
                  Drag images Here
                </v-card-title>
                <v-card-title
                  v-else
                  class="justify-center"
                  style="font-size: 12px"
                >
                  Click images to change it
                </v-card-title>
              </div>
            </div> -->
          </v-flex>
          <v-flex xs12 md6 class="pt-3">
            <v-row class="pl-10 pr-10">
              <v-select
                style="font-size: 11px"
                class="mr-4"
                v-model="selectedCategory"
                :items="categories"
                :rules="[(v) => !!v || 'Category is required']"
                hide-selected
                label="Category"
                outlined
                dense
                :multiple="false"
                @change="selectCategory"
              >
              </v-select>

              <v-select
                style="font-size: 11px"
                v-model="selectedSubCategory"
                :items="subCategories"
                :rules="[(v) => !!v || 'Sub Category is required']"
                hide-selected
                label="Sub Category"
                outlined
                dense
                :multiple="false"
              >
              </v-select>
            </v-row>
            <v-row class="pl-10 pr-10">
              <v-text-field
                style="font-size: 11px"
                class="mr-4"
                v-model="product_title_in_english"
                :rules="[(v) => !!v || 'Title is required']"
                label="Enter product title?"
                required
                outlined
                dense
              ></v-text-field>

              <v-text-field
                style="font-size: 11px"
                v-model="product_title_in_amharic"
                :rules="[(v) => !!v || 'Title in amharic is required']"
                label="Product title in Amharic?"
                required
                outlined
                dense
              ></v-text-field>
            </v-row>

            <v-row class="pl-10 pr-10">
              <v-text-field
                style="font-size: 11px"
                class="mr-4"
                v-model="pprice"
                :rules="[(v) => !!v || 'Price is required']"
                label="Enter product price?"
                required
                outlined
                type="number"
                dense
              ></v-text-field>

              <v-select
                style="font-size: 11px"
                v-model="selectedSizeSpec"
                :items="sizeSpecList"
                label="Choose Size Spec"
                outlined
                dense
                :multiple="false"
              >
              </v-select>
            </v-row>

            <v-row class="pl-10 pr-10">
              <v-flex xs6 class="mb-4">
                <v-text-field
                  style="font-size: 11px"
                  v-model="psock"
                  :rules="[(v) => !!v || 'Stock is required']"
                  label="Total Stock"
                  required
                  outlined
                  disabled
                  dense
                ></v-text-field>
              </v-flex>
            </v-row>
          </v-flex>

          <v-flex 12 md6>
            <v-textarea
              style="font-size: 11px"
              rows="3"
              v-model="description_in_english"
              clearable
              clear-icon="close"
              label="Product description in English"
              counter="255"
              outlined
              dense
              multi-line
              class="ml-8 mt-0"
              :rules="[(v) => !!v || 'Description is required']"
            />

            <v-textarea
              style="font-size: 11px"
              rows="2"
              v-model="description_in_amharic"
              clearable
              clear-icon="close"
              label="Product description in Amharic"
              counter="255"
              outlined
              dense
              class="ml-8"
              :rules="[(v) => !!v || 'Description in amharic is required']"
            ></v-textarea>
          </v-flex>

          <v-flex xs12 class="pl-15 pr-15">
            <v-layout
              row
              wrap
              class="pl-7 pr-7"
              v-for="color in colorCounter"
              :key="color"
            >
              <v-flex xs12 class="pa-3">
                <v-row>
                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="imageURLS[color - 1].image1"
                      height="142"
                      contain
                      v-if="imageURLS[color - 1].image1 != ''"
                      @click="changeImage(0, color - 1)"
                    ></v-img>

                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="imageURLS[color - 1].image1 == ''"
                      @click="simulateInputfileClicked(color - 1)"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 1</p>
                      <!-- </v-btn> -->
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="imageURLS[color - 1].image2"
                      height="142"
                      contain
                      v-if="imageURLS[color - 1].image2 != ''"
                      @click="changeImage(1, color - 1)"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="imageURLS[color - 1].image2 == ''"
                      @click="simulateInputfileClicked(color - 1)"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 2</p>
                      <!-- </v-btn> -->
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="imageURLS[color - 1].image3"
                      height="142"
                      contain
                      v-if="imageURLS[color - 1].image3 != ''"
                      @click="changeImage(2, color - 1)"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="imageURLS[color - 1].image3 == ''"
                      @click="simulateInputfileClicked(color - 1)"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 3</p>
                      <!-- </v-btn> -->
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="imageURLS[color - 1].image4"
                      height="142"
                      contain
                      v-if="imageURLS[color - 1].image4 != ''"
                      @click="changeImage(3, color - 1)"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="imageURLS[color - 1].image4 == ''"
                      @click="simulateInputfileClicked(color - 1)"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 4</p>
                      <!-- </v-btn> -->
                    </div>
                  </v-flex>

                  <v-flex xs2>
                    <v-img
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2"
                      :src="imageURLS[color - 1].image5"
                      height="142"
                      contain
                      v-if="imageURLS[color - 1].image5 != ''"
                      @click="changeImage(4, color - 1)"
                    ></v-img>
                    <div
                      @mouseenter="changeCursor"
                      id="imageCamera"
                      class="ml-2 mr-2 text-center"
                      v-if="imageURLS[color - 1].image5 == ''"
                      @click="simulateInputfileClicked(color - 1)"
                    >
                      <!-- <v-btn centered outlined large fab class="ma-4"> -->
                      <v-icon centered class="mt-10 grey--text"
                        >camera_alt</v-icon
                      >
                      <br />
                      <br />
                      <br />
                      <br />
                      <p class="text-center grey--text">Image 5</p>
                      <!-- </v-btn> -->
                    </div>
                  </v-flex>
                  <v-flex xs1>
                    <div class="text-right mt-15 pt-12">
                      <a
                        right
                        @click="
                          selectedColors.splice(color - 1, 1);
                          sizeCounter.splice(color - 1, 1);
                          productSize.splice(color - 1, 1);
                          productStock.splice(color - 1, 1);
                          imageURLS.splice(color - 1, 1);
                          allFilesImg.splice(color - 1, 1);
                          colorCounter--;
                        "
                        ><v-icon large class="red--text"
                          >remove_circle_rounded</v-icon
                        ></a
                      >
                    </div>
                  </v-flex>

                  <!-- for displaying the add image buttons ends -->
                  <!-- for displaying the add image buttons ends -->
                  <!-- for displaying the add image buttons ends -->
                </v-row>
              </v-flex>
              <v-flex xs12 class="mt-10">
                <v-layout row wrap class="pl-5 pr-7">
                  <v-flex xs1>
                    <h3
                      style="font-size: 16px"
                      class="text-capitalize mr-4 pt-1 ml-1"
                    >
                      Color
                    </h3>
                  </v-flex>
                  <v-flex xs2 class="pl-10">
                    <h3
                      style="font-size: 16px"
                      class="text-capitalize mr-4 pt-1 ml-8"
                    >
                      Size
                    </h3>
                  </v-flex>
                  <v-flex xs3 class="text-center">
                    <h3
                      style="font-size: 16px"
                      class="text-capitalize mr-4 pt-1 ml-3"
                    >
                      stock
                    </h3>
                  </v-flex>
                  <v-flex xs12 class="pa-1 mb-5">
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs2>
                <v-menu
                  rounded
                  transition="scale-transition"
                  location="bottom"
                  internal-activator
                  max-height="400px"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn
                      :color="selectedColors[color - 1]"
                      class="text-capitalize"
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        selectedColors[color - 1] != '' &&
                        selectedColors[color - 1] != undefined
                      "
                    >
                    </v-btn>
                    <v-btn
                      class="text-capitalize pa-1"
                      v-bind="attrs"
                      v-on="on"
                      v-else
                    >
                      color
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item-group
                      v-model="group4"
                      active-class="white--text  green"
                    >
                      <div v-for="item in colorOptions" :key="item.name">
                        <v-list-item
                          class="mb-1"
                          id="color"
                          @click="selectedColors[color - 1] = item.code"
                        >
                          <span class="text-capitalize mr-16">{{
                            item.name
                          }}</span>

                          <v-spacer></v-spacer>
                          <v-btn right :color="item.code"></v-btn>
                        </v-list-item>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </v-flex>

              <v-flex xs7 class="mt-3">
                <v-layout
                  row
                  wrap
                  v-for="size in sizeCounter[color - 1].count"
                  :key="size + 100"
                >
                  <v-flex xs3 class="text-center pa-1">
                    <v-text-field
                      style="font-size: 11px"
                      v-model="productSize[color - 1].size[size - 1]"
                      placeholder="Enter product size?"
                      outlined
                      dense
                      disabled
                      :hide-details="true"
                    ></v-text-field>
                  </v-flex>
                  <!-- <v-flex xs1 class="pt-1 text-center">
                    <a
                      v-if="size == sizeCounter[color - 1].count"
                      right
                      @click="
                        sizeCounter[color - 1].count =
                          sizeCounter[color - 1].count + 1;

                        productSize[color - 1].size.push('');
                        productStock[color - 1].stock.push('');
                      "
                      dark
                      ><v-icon class="blue--text">add_circle</v-icon> <br
                    /></a>

                    <a
                      v-if="size != 1"
                      right
                      @click="
                        productSize[color - 1].size.splice(size - 1, 1);
                        productStock[color - 1].stock.splice(size - 1, 1);
                        sizeCounter[color - 1].count =
                          sizeCounter[color - 1].count - 1;
                      "
                      ><v-icon class="red--text">remove</v-icon></a
                    >
                  </v-flex> -->
                  <v-flex xs5 class="text-center pa-1 pl-5">
                    <v-text-field
                      @change="totalStockC"
                      style="font-size: 11px"
                      v-model="productStock[color - 1].stock[size - 1]"
                      label="Product stock?"
                      outlined
                      dense
                      type="number"
                      :hide-details="true"
                      :rules="[(v) => !!v || 'Stock is required']"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 class="pa-1 pb-5">
                <br />
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-flex xs8>
              <div class="text-left">
                <a right @click="addColorCount" dark
                  ><v-icon large class="blue--text">add_circle</v-icon></a
                >
              </div>
            </v-flex>

            <v-flex xs12>
              <div class="pb-10 pt-3 pl-10 text-center">
                <v-btn
                  text
                  class="px-10 grey lighten-2 text-capitalize"
                  @click="addProduct"
                >
                  Save
                </v-btn>
              </div>
            </v-flex>
          </v-flex>
        </v-row>
      </v-form>
    </v-flex>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
// import serviceApi from '@/services/apiService.js'
// import emailjs from "emailjs-com";
// import graphqlQueries from '@/config/graphqlQueries'
import axios from "axios";
import firebase from "firebase";
import * as blurhash from "blurhash";
// import { createCanvas } from "canvas";

export default {
  data() {
    return {
      colorCounter: 0,
      sizeCounter: [],
      group4: null,
      pprice: "",
      psock: "",
      allCategories: [],
      selectedSubCategory: "",
      subCategories: [],
      colorOptions: [
        {
          name: "red",
          code: "#FF0000",
        },
        {
          name: "black",
          code: "#000000",
        },
        {
          name: "white",
          code: "#FFFFFF",
        },
        {
          name: "purple",
          code: "#800080",
        },
        {
          name: "Pink",
          code: "#FF00FF",
        },
        {
          name: "green",
          code: "#008000",
        },
        {
          name: "yellow",
          code: "#FFFF00",
        },
        {
          name: "blue",
          code: "#0000FF",
        },
        {
          name: "gray",
          code: "#808080",
        },
      ],
      selectedColors: [],
      productSize: [],
      productStock: [],
      imageURLS: [],
      allFilesImg: [],
      colorImageIndex: "",

      tableName: "",
      subdept_id: 0,
      valid: true,
      status: "",
      loading: false,
      color: "",
      privilages: [],
      hasColors: false,
      hasOneSpec: false,
      hasDefaultSpecs: false,
      selectedColors: [],
      selectedColorNames: [],
      measureName: "",
      hasOnlyOneSpec: 0,
      selectedCategory: "",
      searchCategory: "",
      searchMeasures: "",
      measures: [],
      saleTypes: [],
      selectedMeasure1: null,
      selectedMeasure2: null,
      selectedMeasure1Name: "Select measure 1?",
      selectedMeasure2Name: "Select measure 2?",
      product_title_in_english: "",
      product_title_in_amharic: "",
      description_in_english: "",
      description_in_amharic: "",
      files: [],
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
      imageURL1: "",
      imageURL2: "",
      imageURL3: "",
      imageURL4: "",
      imageURL5: "",
      blurhash1: "",
      blurhash2: "",
      blurhash3: "",
      blurhash4: "",
      blurhash5: "",
      errors: false,
      dialog: false,
      saleTypesDialog: false,
      saleTypesDialog2: false,
      addingStatus: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      message: "",
      categories: [],
      colors: [],
      hexColors: [],
      colorNames: [],
      spec1: [],
      spec2: [],
      defaultSpec1: [],
      defaultSpec2: [],
      touchedSpecIndex: 1,
      touchedDefaultSpecIndex: 1,
      spec1dialog: false,
      spec2dialog: false,
      defaultspec2dialog: false,
      defaultspec1dialog: false,
      measure1Specs: [],
      measure2Specs: [],
      spec1Stocks: ["1"],
      spec1Prices: [],
      spec2Stocks: ["1"],
      spec2Prices: [],
      spec3Stocks: ["1"],
      spec3Prices: [],
      spec4Stocks: ["1"],
      spec4Prices: [],
      spec5Stocks: ["1"],
      spec5Prices: [],
      spec6Stocks: ["1"],
      spec6Prices: [],
      spec7Stocks: ["1"],
      spec7Prices: [],
      spec8Stocks: ["1"],
      spec8Prices: [],
      defaultStocks: ["1"],
      defaultPrices: [],
      visibleStocks: {
        spec1VisibleStocks: 1,
        spec2VisibleStocks: 1,
        spec3VisibleStocks: 1,
        spec4VisibleStocks: 1,
        spec5VisibleStocks: 1,
        spec6VisibleStocks: 1,
        spec7VisibleStocks: 1,
        spec8VisibleStocks: 1,
      },
      visibleSpecs: 1,
      defaultVisisbleSpecs: 1,
      defaultVisisbleStocks: 1,
      stack: null,
      priceList: "",
      stockList: "",
      specList: "",
      defaultPriceList: "",
      defaultStockList: "",
      defaultSpecList: "",
      selectedSizeSpec: "",
      sizeSpecList: ["RUGLAN SLEVE", "CREW NEKC T-SHIRT", "POLO T-SHIRT"],
    };
  },
  // apollo: {
  //   getSingleSupplierbyId: {
  //     query: gql` query getSingleSupplierbyId($supplier_id: Int) {
  //                   getSingleSupplierbyId(supplier_id: $supplier_id) {
  //                     supplier_id
  //                     privilege_adder
  //                   }
  //                 }
  //               `,
  //              variables: {
  //                "supplier_id": 49
  //               },
  //   }
  // },
  methods: {
    totalStockC() {
      var st = 0;

      for (let i = 0; i < this.productStock.length; i++) {
        for (let k = 0; k < this.productStock[i].stock.length; k++) {
          if (
            !isNaN(this.productStock[i].stock[k]) &&
            this.productStock[i].stock[k] != "" &&
            this.productStock[i].stock[k] >= 0
          )
            st = parseInt(st) + parseInt(this.productStock[i].stock[k]);
        }
      }

      this.psock = st;
    },
    addColorCount() {
      this.colorCounter = this.colorCounter + 1;
      this.imageURLS.push({
        image1: "",
        image2: "",
        image3: "",
        image4: "",
        image5: "",
      });
      this.sizeCounter.push({ count: 6 });
      this.productSize.push({ size: ["S", "M", "L", "XL", "XXL", "XXXL"] });
      this.productStock.push({ stock: ["0", "0", "0", "0", "0", "0"] });
      this.allFilesImg.push({ files: ["", "", "", "", ""] });
      this.files = [];
    },
    fetchSpec1(index) {
      if (this.selectedMeasure1 != null) {
        this.measure1Specs = this.selectedMeasure1.measure_value.split(",");
        this.touchedSpecIndex = index;
        this.spec1dialog = true;
      } else {
        alert("Please select measure 1");
      }
    },
    fetchSpec2(index) {
      if (this.selectedMeasure2) {
        this.measure2Specs = this.selectedMeasure2.measure_value.split(",");
        this.touchedSpecIndex = index;
        this.spec2dialog = true;
      } else {
        alert("Please select measure 2");
      }
    },

    fetchDefaultSpec1(index) {
      if (this.selectedMeasure1 != null) {
        this.measure1Specs = this.selectedMeasure1.measure_value.split(",");
        this.touchedDefaultSpecIndex = index;
        this.defaultspec1dialog = true;
      } else {
        alert("Please select measure 1");
      }
    },
    fetchDefaultSpec2(index) {
      if (this.selectedMeasure2) {
        this.measure2Specs = this.selectedMeasure2.measure_value.split(",");
        this.touchedDefaultSpecIndex = index;
        this.defaultspec2dialog = true;
      } else {
        alert("Please select measure 2");
      }
    },

    async getPrivilages() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                    {
                    getAllCategories {
                      category_id
                      blurhash
                       title_english
                       title_amharic
                        image
                        blurhash
                        getSubCategory{
                          sub_category_id
                          category_id
                          title_amharic
                          title_english
                          image
                          blurhash
                         
                          }
                    }
                    }
                `,
          },
        });

        this.allCategories = result.data.data.getAllCategories;
        this.categories = [];
        for (let i = 0; i < this.allCategories.length; i++) {
          this.categories.push(
            this.allCategories[i].title_english +
              "," +
              this.allCategories[i].category_id
          );
        }
      } catch (err) {
        alert(err);
      }
    },
    async getColors() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `{  
                    getAllColors{
                    color_id
                    color_value
                    color_name  
                  }
                }
                `,
          },
        });

        for (let i = 0; i < result.data.data.getAllColors.length; i++) {
          this.colors.push(
            "#" + result.data.data.getAllColors[i].color_value.substring(4, 10)
          );
          this.hexColors.push(result.data.data.getAllColors[i].color_value);
          this.colorNames.push(result.data.data.getAllColors[i].color_name);
        }
      } catch (err) {
        alert(err);
      }
    },
    async selectCategory() {
      this.subCategories = [];
      for (let i = 0; i < this.allCategories.length; i++) {
        if (
          parseInt(this.selectedCategory.split(",")[1]) ==
          this.allCategories[i].category_id
        ) {
          for (
            let j = 0;
            j < this.allCategories[i].getSubCategory.length;
            j++
          ) {
            this.subCategories.push(
              this.allCategories[i].getSubCategory[j].title_english +
                "," +
                this.allCategories[i].getSubCategory[j].sub_category_id
            );
          }
        }
      }
    },
    async fetchAllSaleTypes() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                 query getMeasures {
                    getMeasures{
                      measure_id
                      measure_name
                      measure_value
                    }
                  }
                `,
          },
        });
        // this.fetchMeasures();

        this.saleTypes = result.data.data.getMeasures;
      } catch (err) {
        alert(err);
      }
    },

    selectMeasures(item) {
      this.selectedMeasure1 = item;
      this.selectedMeasure1Name = this.selectedMeasure1.measure_name;
      this.spec1 = [];
      this.saleTypesDialog = false;
    },
    selectMeasures2(item) {
      this.selectedMeasure2 = item;
      this.selectedMeasure2Name = this.selectedMeasure2.measure_name;
      this.spec2 = [];
      this.saleTypesDialog2 = false;
    },
    selectSpec1(item) {
      this.spec1[this.touchedSpecIndex] = item;
      this.spec1dialog = false;
    },
    selectSpec2(item) {
      this.spec2[this.touchedSpecIndex] = item;
      this.spec2dialog = false;
    },

    selectDefaultSpec1(item) {
      this.defaultSpec1[this.touchedDefaultSpecIndex] = item;
      this.defaultspec1dialog = false;
    },
    selectDefaultSpec2(item) {
      this.defaultSpec2[this.touchedDefaultSpecIndex] = item;
      this.defaultspec2dialog = false;
    },

    // async fetchMeasures() {
    //   if (this.selectedCategory != "") {
    //     if (this.hasMeasures == true) {
    //       try {
    //         var result = await axios({
    //           method: "POST",
    //           url: this.$store.state.url,
    //           data: {
    //             query: `{
    //                     getMeasureInfoAndSubDeptInfo(subDeptTitle: "${this.selectedCategory}") {
    //                       subdept_id
    //                       subdept_title_in_amharic
    //                       subdept_title_in_english
    //                       maindept_id
    //                       measure_id
    //                       measure {
    //                         measure_id
    //                         measure_name
    //                         measure_value
    //                       }
    //                       mainDept {
    //                         id
    //                         maindept_title_in_amharic
    //                         maindept_title_in_english
    //                         image
    //                       }
    //                     }
    //                   }
    //             `,
    //           },
    //         });

    //         this.measures =
    //           result.data.data.getMeasureInfoAndSubDeptInfo[0].measure[0].measure_value.split(
    //             ","
    //           );
    //       } catch (err) {
    //         alert(err);
    //       }
    //     }
    //   } else {
    //     alert("Select Category first !");
    //   }
    // },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    increaseStocks(index) {
      if (index == 1) {
        if (this.visibleStocks.spec1VisibleStocks < 5) {
          ++this.visibleStocks.spec1VisibleStocks;
        }
      } else if (index == 2) {
        if (this.visibleStocks.spec2VisibleStocks < 5) {
          ++this.visibleStocks.spec2VisibleStocks;
        }
      } else if (index == 3) {
        if (this.visibleStocks.spec3VisibleStocks < 5) {
          ++this.visibleStocks.spec3VisibleStocks;
        }
      } else if (index == 4) {
        if (this.visibleStocks.spec4VisibleStocks < 5) {
          ++this.visibleStocks.spec4VisibleStocks;
        }
      } else if (index == 5) {
        if (this.visibleStocks.spec5VisibleStocks < 5) {
          ++this.visibleStocks.spec5VisibleStocks;
        }
      } else if (index == 6) {
        if (this.visibleStocks.spec6VisibleStocks < 5) {
          ++this.visibleStocks.spec6VisibleStocks;
        }
      } else if (index == 7) {
        if (this.visibleStocks.spec7VisibleStocks < 5) {
          ++this.visibleStocks.spec7VisibleStocks;
        }
      } else if (index == 8) {
        if (this.visibleStocks.spec8VisibleStocks < 5) {
          ++this.visibleStocks.spec8VisibleStocks;
        }
      }
    },
    decreaseStocks(index) {
      if (index == 1) {
        if (this.visibleStocks.spec1VisibleStocks == 2) {
          this.spec1Stocks[1] = undefined;
          this.spec1Prices[1] = undefined;
          --this.visibleStocks.spec1VisibleStocks;
          console.log(this.spec1Stocks[1] + " 2 " + this.spec1Prices[1]);
        } else if (this.visibleStocks.spec1VisibleStocks == 3) {
          this.spec1Stocks[2] = undefined;
          this.spec1Prices[2] = undefined;
          --this.visibleStocks.spec1VisibleStocks;
          console.log(this.spec1Stocks[2] + " 3 " + this.spec1Prices[2]);
        } else if (this.visibleStocks.spec1VisibleStocks == 4) {
          this.spec1Stocks[3] = undefined;
          this.spec1Prices[3] = undefined;
          --this.visibleStocks.spec1VisibleStocks;
          console.log(this.spec1Stocks[3] + " 4 " + this.spec1Prices[3]);
        } else if (this.visibleStocks.spec1VisibleStocks == 5) {
          this.spec1Stocks[4] = undefined;
          this.spec1Prices[4] = undefined;
          --this.visibleStocks.spec1VisibleStocks;
          console.log(this.spec1Stocks[4] + " 5 " + this.spec1Prices[4]);
        }
      } else if (index == 2) {
        console.log(this.visibleStocks.spec2VisibleStocks);
        if (this.visibleStocks.spec2VisibleStocks == 2) {
          this.spec2Stocks[1] = undefined;
          this.spec2Prices[1] = undefined;
          --this.visibleStocks.spec2VisibleStocks;
          console.log(this.spec2Stocks[1] + " 2 " + this.spec2Prices[1]);
        } else if (this.visibleStocks.spec2VisibleStocks == 3) {
          this.spec2Stocks[2] = undefined;
          this.spec2Prices[2] = undefined;
          --this.visibleStocks.spec2VisibleStocks;
          console.log(this.spec2Stocks[2] + " 3 " + this.spec2Prices[2]);
        } else if (this.visibleStocks.spec2VisibleStocks == 4) {
          this.spec2Stocks[3] = undefined;
          this.spec2Prices[3] = undefined;
          --this.visibleStocks.spec2VisibleStocks;
          console.log(this.spec2Stocks[3] + " 4 " + this.spec2Prices[3]);
        } else if (this.visibleStocks.spec2VisibleStocks == 5) {
          this.spec2Stocks[4] = undefined;
          this.spec2Prices[4] = undefined;
          --this.visibleStocks.spec2VisibleStocks;
          console.log(this.spec2Stocks[4] + " 5 " + this.spec2Prices[4]);
        }
      } else if (index == 3) {
        if (this.visibleStocks.spec3VisibleStocks == 2) {
          this.spec3Stocks[1] = undefined;
          this.spec3Prices[1] = undefined;
          --this.visibleStocks.spec3VisibleStocks;
          console.log(this.spec3Stocks[1] + " 2 " + this.spec3Prices[1]);
        } else if (this.visibleStocks.spec3VisibleStocks == 3) {
          this.spec3Stocks[2] = undefined;
          this.spec3Prices[2] = undefined;
          --this.visibleStocks.spec3VisibleStocks;
          console.log(this.spec3Stocks[2] + " 3 " + this.spec3Prices[2]);
        } else if (this.visibleStocks.spec3VisibleStocks == 4) {
          this.spec3Stocks[3] = undefined;
          this.spec3Prices[3] = undefined;
          --this.visibleStocks.spec3VisibleStocks;
          console.log(this.spec3Stocks[3] + " 4 " + this.spec3Prices[3]);
        } else if (this.visibleStocks.spec3VisibleStocks == 5) {
          this.spec3Stocks[4] = undefined;
          this.spec3Prices[4] = undefined;
          --this.visibleStocks.spec3VisibleStocks;
          console.log(this.spec3Stocks[4] + " 5 " + this.spec3Prices[4]);
        }
      } else if (index == 4) {
        if (this.visibleStocks.spec4VisibleStocks == 2) {
          this.spec4Stocks[1] = undefined;
          this.spec4Prices[1] = undefined;
          --this.visibleStocks.spec4VisibleStocks;
          console.log(this.spec4Stocks[1] + " 2 " + this.spec4Prices[1]);
        } else if (this.visibleStocks.spec4VisibleStocks == 3) {
          this.spec4Stocks[2] = undefined;
          this.spec4Prices[2] = undefined;
          --this.visibleStocks.spec4VisibleStocks;
          console.log(this.spec4Stocks[2] + " 3 " + this.spec4Prices[2]);
        } else if (this.visibleStocks.spec4VisibleStocks == 4) {
          this.spec4Stocks[3] = undefined;
          this.spec3Prices[3] = undefined;
          --this.visibleStocks.spec4VisibleStocks;
          console.log(this.spec4Stocks[3] + " 4 " + this.spec4Prices[3]);
        } else if (this.visibleStocks.spec4VisibleStocks == 5) {
          this.spec4Stocks[4] = undefined;
          this.spec4Prices[4] = undefined;
          --this.visibleStocks.spec4VisibleStocks;
          console.log(this.spec4Stocks[4] + " 5 " + this.spec4Prices[4]);
        }
      } else if (index == 5) {
        if (this.visibleStocks.spec5VisibleStocks == 2) {
          this.spec5Stocks[1] = undefined;
          this.spec5Prices[1] = undefined;
          --this.visibleStocks.spec5VisibleStocks;
          console.log(this.spec5Stocks[1] + " 2 " + this.spec5Prices[1]);
        } else if (this.visibleStocks.spec5VisibleStocks == 3) {
          this.spec5Stocks[2] = undefined;
          this.spec5Prices[2] = undefined;
          --this.visibleStocks.spec5VisibleStocks;
          console.log(this.spec5Stocks[2] + " 3 " + this.spec5Prices[2]);
        } else if (this.visibleStocks.spec5VisibleStocks == 4) {
          this.spec5Stocks[3] = undefined;
          this.spec5Prices[3] = undefined;
          --this.visibleStocks.spec5VisibleStocks;
          console.log(this.spec5Stocks[3] + " 4 " + this.spec5Prices[3]);
        } else if (this.visibleStocks.spec5VisibleStocks == 5) {
          this.spec5Stocks[4] = undefined;
          this.spec5Prices[4] = undefined;
          --this.visibleStocks.spec5VisibleStocks;
          console.log(this.spec5Stocks[4] + " 5 " + this.spec5Prices[4]);
        }
      } else if (index == 6) {
        if (this.visibleStocks.spec6VisibleStocks == 2) {
          this.spec6Stocks[1] = undefined;
          this.spec6Prices[1] = undefined;
          --this.visibleStocks.spec6VisibleStocks;
          console.log(this.spec6Stocks[1] + " 2 " + this.spec6Prices[1]);
        } else if (this.visibleStocks.spec6VisibleStocks == 3) {
          this.spec6Stocks[2] = undefined;
          this.spec6Prices[2] = undefined;
          --this.visibleStocks.spec6VisibleStocks;
          console.log(this.spec6Stocks[2] + " 3 " + this.spec6Prices[2]);
        } else if (this.visibleStocks.spec6VisibleStocks == 4) {
          this.spec6Stocks[3] = undefined;
          this.spec6Prices[3] = undefined;
          --this.visibleStocks.spec6VisibleStocks;
          console.log(this.spec6Stocks[3] + " 4 " + this.spec6Prices[3]);
        } else if (this.visibleStocks.spec6VisibleStocks == 5) {
          this.spec6Stocks[4] = undefined;
          this.spec6Prices[4] = undefined;
          --this.visibleStocks.spec6VisibleStocks;
          console.log(this.spec6Stocks[4] + " 5 " + this.spec6Prices[4]);
        }
      } else if (index == 7) {
        if (this.visibleStocks.spec7VisibleStocks == 2) {
          this.spec7Stocks[1] = undefined;
          this.spec7Prices[1] = undefined;
          --this.visibleStocks.spec7VisibleStocks;
          console.log(this.spec7Stocks[1] + " 2 " + this.spec7Prices[1]);
        } else if (this.visibleStocks.spec7VisibleStocks == 3) {
          this.spec7Stocks[2] = undefined;
          this.spec7Prices[2] = undefined;
          --this.visibleStocks.spec7VisibleStocks;
          console.log(this.spec7Stocks[2] + " 3 " + this.spec7Prices[2]);
        } else if (this.visibleStocks.spec7VisibleStocks == 4) {
          this.spec7Stocks[3] = undefined;
          this.spec7Prices[3] = undefined;
          --this.visibleStocks.spec7VisibleStocks;
          console.log(this.spec7Stocks[3] + " 4 " + this.spec7Prices[3]);
        } else if (this.visibleStocks.spec7VisibleStocks == 5) {
          this.spec7Stocks[4] = undefined;
          this.spec7Prices[4] = undefined;
          --this.visibleStocks.spec7VisibleStocks;
          console.log(this.spec7Stocks[4] + " 5 " + this.spec7Prices[4]);
        }
      } else if (index == 8) {
        if (this.visibleStocks.spec8VisibleStocks == 2) {
          this.spec8Stocks[1] = undefined;
          this.spec8Prices[1] = undefined;
          --this.visibleStocks.spec8VisibleStocks;
          console.log(this.spec8Stocks[1] + " 2 " + this.spec8Prices[1]);
        } else if (this.visibleStocks.spec8VisibleStocks == 3) {
          this.spec8Stocks[2] = undefined;
          this.spec8Prices[2] = undefined;
          --this.visibleStocks.spec8VisibleStocks;
          console.log(this.spec8Stocks[2] + " 3 " + this.spec8Prices[2]);
        } else if (this.visibleStocks.spec8VisibleStocks == 4) {
          this.spec8Stocks[3] = undefined;
          this.spec8Prices[3] = undefined;
          --this.visibleStocks.spec8VisibleStocks;
          console.log(this.spec8Stocks[3] + " 4 " + this.spec8Prices[3]);
        } else if (this.visibleStocks.spec8VisibleStocks == 5) {
          this.spec8Stocks[4] = undefined;
          this.spec8Prices[4] = undefined;
          --this.visibleStocks.spec8VisibleStocks;
          console.log(this.spec8Stocks[4] + " 5 " + this.spec8Prices[4]);
        }
      }
    },
    decreaseSpecs() {
      if (this.visibleSpecs > 1) {
        if (this.spec1.length == this.visibleSpecs) {
          this.spec1.pop();
        }
        if (this.spec2.length == this.visibleSpecs) {
          this.spec2.pop();
        }
        --this.visibleSpecs;
        if (this.visibleSpecs == 7) {
          this.spec8Stocks = ["1"];
          this.spec8Prices = [];
          this.visibleStocks.spec8VisibleStocks = 1;
        } else if (this.visibleSpecs == 6) {
          this.spec7Stocks = ["1"];
          this.spec7Prices = [];
          this.visibleStocks.spec7VisibleStocks = 1;
        } else if (this.visibleSpecs == 5) {
          this.spec6Stocks = ["1"];
          this.spec6Prices = [];
          this.visibleStocks.spec6VisibleStocks = 1;
        } else if (this.visibleSpecs == 4) {
          this.spec5Stocks = ["1"];
          this.spec5Prices = [];
          this.visibleStocks.spec5VisibleStocks = 1;
        } else if (this.visibleSpecs == 3) {
          this.spec4Stocks = ["1"];
          this.spec4Prices = [];
          this.visibleStocks.spec4VisibleStocks = 1;
        } else if (this.visibleSpecs == 2) {
          this.spec3Stocks = ["1"];
          this.spec3Prices = [];
          this.visibleStocks.spec3VisibleStocks = 1;
        } else if (this.visibleSpecs == 1) {
          this.spec2Stocks = ["1"];
          this.spec2Prices = [];
          this.visibleStocks.spec2VisibleStocks = 1;
        }
      }
    },
    increaseSpecs() {
      if (this.visibleSpecs < 8) {
        this.visibleSpecs++;
      }
    },
    increaseDefaultStocks() {
      if (this.defaultVisisbleStocks < 5) {
        ++this.defaultVisisbleStocks;
      }
    },
    increaseDefaultSpecs() {
      if (this.defaultVisisbleSpecs < 8) {
        this.defaultVisisbleSpecs++;
      }
    },
    decreaseDefaultStocks() {
      console.log(this.defaultVisisbleStocks);
      if (this.defaultVisisbleStocks == 2) {
        this.defaultStocks[1] = undefined;
        this.defaultStocks[1] = undefined;
        --this.defaultVisisbleStocks;
        console.log(
          this.defaultStocks[1] + " default2 " + this.defaultPrices[1]
        );
      } else if (this.defaultVisisbleStocks == 3) {
        this.defaultStocks[2] = undefined;
        this.defaultStocks[2] = undefined;
        --this.defaultVisisbleStocks;
        console.log(
          this.defaultStocks[2] + " default3 " + this.defaultPrices[2]
        );
      } else if (this.defaultVisisbleStocks == 4) {
        this.defaultStocks[3] = undefined;
        this.defaultPrices[3] = undefined;
        --this.defaultVisisbleStocks;
        console.log(
          this.defaultStocks[3] + " default4 " + this.defaultPrices[3]
        );
      } else if (this.defaultVisisbleStocks == 5) {
        this.defaultStocks[4] = undefined;
        this.defaultPrices[4] = undefined;
        --this.defaultVisisbleStocks;
        console.log(
          this.defaultStocks[4] + " default5 " + this.defaultPrices[4]
        );
      }
    },
    decreaseDefaultSpecs() {
      if (this.defaultVisisbleSpecs > 1) {
        if (this.defaultSpec1.length == this.defaultVisisbleSpecs) {
          this.defaultSpec1.pop();
        }
        if (this.defaultSpec2.length == this.defaultVisisbleSpecs) {
          this.defaultSpec2.pop();
        }

        --this.defaultVisisbleSpecs;
      }
    },
    // decreaseStocks(visi) {
    //   if (this.visible > 1) {
    //     --this.visible;
    //     console.log(index);
    //     if (index == 2) {
    //       this.stock2 = undefined;
    //       this.price2 = undefined;

    //       console.log(this.stock2 + " 2 " + this.price3);
    //     } else if (index == 3) {
    //       this.stock3 = undefined;
    //       this.price3 = undefined;

    //       console.log(this.stock3 + " 3 " + this.price3);
    //     } else if (index == 4) {
    //       this.stock4 = undefined;
    //       this.price4 = undefined;

    //       console.log(this.stock4 + " 4 " + this.price4);
    //     } else if (index == 5) {
    //       this.stock5 = undefined;
    //       this.price5 = undefined;

    //       console.log(this.stock5 + " 5 " + this.price5);
    //     }
    //   }
    //   console.log(this.visible);
    // },
    OnDragEnter(e) {
      e.preventDefault();
      console.log("entering");
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      console.log("leaving");
    },
    OnDrop(e) {
      console.log("dropping");
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      console.log(files);
      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      console.log(files);
      Array.from(files).forEach((file) => this.addImage(file));
      // this.files = [];
    },

    OnEditImage1(e) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = (f) => {
        this.imageURLS[this.colorImageIndex].image1 = f.target.result;
        this.allFilesImg[this.colorImageIndex].files[0] = file;
        // const imageData = this.getImageData(this.image1);
        // this.blurhash1 = blurhash.encode(
        //   imageData.data,
        //   imageData.width,
        //   imageData.height,
        //   4,
        //   4
        // );
        // console.log(this.blurhash1 + "   blur1");
      };
      reader.readAsDataURL(file);
    },
    OnEditImage2(e) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (f) => {
        this.imageURLS[this.colorImageIndex].image2 = f.target.result;
        this.allFilesImg[this.colorImageIndex].files[1] = file;
        // const imageData = this.getImageData(this.image2);
        // this.blurhash2 = blurhash.encode(
        //   imageData.data,
        //   imageData.width,
        //   imageData.height,
        //   4,
        //   4
        // );
        // console.log(this.blurhash2 + "   blur2");
      };
      reader.readAsDataURL(file);
    },
    OnEditImage3(e) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (f) => {
        this.imageURLS[this.colorImageIndex].image3 = f.target.result;
        this.allFilesImg[this.colorImageIndex].files[2] = file;
        // const imageData = this.getImageData(this.image3);
        // this.blurhash3 = blurhash.encode(
        //   imageData.data,
        //   imageData.width,
        //   imageData.height,
        //   4,
        //   4
        // );
        // console.log(this.blurhash3 + "   blur3");
      };
      reader.readAsDataURL(file);
    },
    OnEditImage4(e) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (f) => {
        this.imageURLS[this.colorImageIndex].image4 = f.target.result;
        this.allFilesImg[this.colorImageIndex].files[3] = file;
        // const imageData = this.getImageData(this.image4);
        // this.blurhash4 = blurhash.encode(
        //   imageData.data,
        //   imageData.width,
        //   imageData.height,
        //   4,
        //   4
        // );
        // console.log(this.blurhash4 + "   blur4");
      };
      reader.readAsDataURL(file);
    },
    OnEditImage5(e) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = (f) => {
        this.imageURLS[this.colorImageIndex].image5 = f.target.result;
        this.allFilesImg[this.colorImageIndex].files[4] = file;
        // const imageData = this.getImageData(this.image5);
        // this.blurhash5 = blurhash.encode(
        //   imageData.data,
        //   imageData.width,
        //   imageData.height,
        //   4,
        //   4
        // );
        // console.log(this.blurhash5 + "   blur5");
      };
      reader.readAsDataURL(file);
    },
    simulateInputfileClicked(i) {
      this.colorImageIndex = i;
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        return;
      }
      if (this.files.length == 5) {
        // this.status = "can't add more than 5 images";
        // this.errors = true;
        return;
      }
      this.files.push(file);

      const reader = new FileReader();
      if (this.files.length == 1) {
        reader.onload = (f) => {
          this.imageURLS[this.colorImageIndex].image1 = f.target.result;
          this.allFilesImg[this.colorImageIndex].files[0] = file;
          // const imageData = this.getImageData(this.image1);
          // this.blurhash1 = blurhash.encode(
          //   imageData.data,
          //   imageData.width,
          //   imageData.height,
          //   4,
          //   4
          // );
          // console.log(this.blurhash1 + "   blur1");
        };
        reader.readAsDataURL(file);
      } else if (this.files.length == 2) {
        reader.onload = (f) => {
          this.imageURLS[this.colorImageIndex].image2 = f.target.result;
          this.allFilesImg[this.colorImageIndex].files[1] = file;
          // const imageData = this.getImageData(this.image2);
          // this.blurhash2 = blurhash.encode(
          //   imageData.data,
          //   imageData.width,
          //   imageData.height,
          //   4,
          //   4
          // );
          // console.log(this.blurhash2 + "   blur2");
        };
        reader.readAsDataURL(file);
      } else if (this.files.length == 3) {
        reader.onload = (f) => {
          this.imageURLS[this.colorImageIndex].image3 = f.target.result;
          this.allFilesImg[this.colorImageIndex].files[2] = file;
          // const imageData = this.getImageData(this.image2);
          // this.blurhash3 = blurhash.encode(
          //   imageData.data,
          //   imageData.width,
          //   imageData.height,
          //   4,
          //   4
          // );
          // console.log(this.blurhash3 + "   blur3");
        };
        reader.readAsDataURL(file);
      } else if (this.files.length == 4) {
        reader.onload = (f) => {
          this.imageURLS[this.colorImageIndex].image4 = f.target.result;
          this.allFilesImg[this.colorImageIndex].files[3] = file;
          // const imageData = this.getImageData(this.image4);
          // this.blurhash4 = blurhash.encode(
          //   imageData.data,
          //   imageData.width,
          //   imageData.height,
          //   4,
          //   4
          // );
          // console.log(this.blurhash4 + "   blur4");
        };
        reader.readAsDataURL(file);
      } else if (this.files.length == 5) {
        reader.onload = (f) => {
          this.imageURLS[this.colorImageIndex].image5 = f.target.result;
          this.allFilesImg[this.colorImageIndex].files[4] = file;
          // const imageData = this.getImageData(this.image5);
          // this.blurhash5 = blurhash.encode(
          //   imageData.data,
          //   imageData.width,
          //   imageData.height,
          //   4,
          //   4
          // );
          // console.log(this.blurhash5 + "   blur5");
        };
        reader.readAsDataURL(file);
      }
    },

    changeImage(index, add) {
      this.colorImageIndex = add;
      if (index == 0) {
        console.log("changing image 1");
        this.$refs.editImage1.click();
      } else if (index == 1) {
        console.log("changing image 2");
        this.$refs.editImage2.click();
      } else if (index == 2) {
        console.log("changing image 3");
        this.$refs.editImage3.click();
      } else if (index == 3) {
        console.log("changing image 4");
        this.$refs.editImage4.click();
      } else if (index == 4) {
        console.log("changing image 5");
        this.$refs.editImage5.click();
      }
    },
    async addProduct() {
      if (
        this.selectedColors.length != this.colorCounter ||
        this.selectedColors.length == 0
      ) {
        alert("Please add all colors");
        return;
      }
      let isImageComplete = 0;
      for (let i = 0; i < this.imageURLS.length; i++) {
        if (
          this.imageURLS[i].image1 != "" &&
          this.imageURLS[i].image1 != undefined
        ) {
          isImageComplete++;
        }
        if (
          this.imageURLS[i].image2 != "" &&
          this.imageURLS[i].image2 != undefined
        ) {
          isImageComplete++;
        }
        if (
          this.imageURLS[i].image3 != "" &&
          this.imageURLS[i].image3 != undefined
        ) {
          isImageComplete++;
        }
        if (
          this.imageURLS[i].image4 != "" &&
          this.imageURLS[i].image4 != undefined
        ) {
          isImageComplete++;
        }
        if (
          this.imageURLS[i].image5 != "" &&
          this.imageURLS[i].image5 != undefined
        ) {
          isImageComplete++;
        }
      }
      if (isImageComplete == 0) {
        alert("Please add at least one image");
        return;
      }

      if (this.pprice <= 0) {
        alert("Price must be numeric and greater than 0");
        return;
      }

      var allsize = "";
      for (let k = 0; k < this.productSize.length; k++) {
        if (allsize == "") allsize = this.productSize[k].size.toString();
        else
          allsize = allsize + "*product#" + this.productSize[k].size.toString();
      }

      var allStock = "";
      var check = 0;
      for (let n = 0; n < this.productStock.length; n++) {
        for (let k = 0; k < this.productStock[n].stock.length; k++) {
          if (
            isNaN(this.productStock[n].stock[k]) ||
            this.productStock[n].stock[k] == "" ||
            this.productStock[n].stock[k] < 0
          )
            check = check + 1;
        }
        if (allStock == "") allStock = this.productStock[n].stock.toString();
        else
          allStock =
            allStock + "*product#" + this.productStock[n].stock.toString();
      }

      if (check > 0) {
        alert("Stock must be numeric and greater than 0 or equal to 0");
        return;
      }

      if (this.$refs.form.validate()) {
        this.addingStatus = "initializing";
        this.dialog = true;
        var allImages = "";
        var allBlurhashs = "";

        for (let i = 0; i < this.imageURLS.length; i++) {
          this.imageURL1 = "";
          this.imageURL2 = "";
          this.imageURL3 = "";
          this.imageURL4 = "";
          this.imageURL5 = "";

          this.blurhash1 = "";
          this.blurhash2 = "";
          this.blurhash3 = "";
          this.blurhash4 = "";
          this.blurhash5 = "";
          try {
            if (this.allFilesImg[i].files[0] != "") {
              this.addingStatus = "uploading image 1  color " + (i + 1);

              await this.uploadImage1(this.allFilesImg[i].files[0]);

              this.blurhash1 = await this.decodeImagetoBlurhash(
                this.imageURLS[i].image1
              );
            }
            if (this.allFilesImg[i].files[1] != "") {
              this.addingStatus = "uploading image 2   color " + (i + 1);
              await this.uploadImage2(this.allFilesImg[i].files[1]);

              this.blurhash2 = await this.decodeImagetoBlurhash(
                this.imageURLS[i].image2
              );
            }

            if (this.allFilesImg[i].files[2] != "") {
              this.addingStatus = "uploading image 3   color " + (i + 1);
              await this.uploadImage3(this.allFilesImg[i].files[2]);

              this.blurhash3 = await this.decodeImagetoBlurhash(
                this.imageURLS[i].image3
              );
            }

            if (this.allFilesImg[i].files[3] != "") {
              this.addingStatus = "uploading image 4   color " + (i + 1);
              await this.uploadImage4(this.allFilesImg[i].files[3]);

              this.blurhash4 = await this.decodeImagetoBlurhash(
                this.imageURLS[i].image4
              );
            }
            if (this.allFilesImg[i].files[4] != "") {
              this.addingStatus = "uploading image 5   color " + (i + 1);
              await this.uploadImage5(this.allFilesImg[i].files[4]);

              this.blurhash5 = await this.decodeImagetoBlurhash(
                this.imageURLS[i].image5
              );
            }
          } catch (error) {
            console.log(error);
          }

          if (allImages == "") {
            allImages =
              this.imageURL1 +
              ",pimg" +
              this.imageURL2 +
              ",pimg" +
              this.imageURL3 +
              ",pimg" +
              this.imageURL4 +
              ",pimg" +
              this.imageURL5;
          } else {
            allImages =
              allImages +
              "*product#" +
              this.imageURL1 +
              ",pimg" +
              this.imageURL2 +
              ",pimg" +
              this.imageURL3 +
              ",pimg" +
              this.imageURL4 +
              ",pimg" +
              this.imageURL5;
          }

          if (allBlurhashs == "")
            allBlurhashs =
              this.blurhash1 +
              ",pimg" +
              this.blurhash2 +
              ",pimg" +
              this.blurhash3 +
              ",pimg" +
              this.blurhash4 +
              ",pimg" +
              this.blurhash5;
          else
            allBlurhashs =
              allBlurhashs +
              "*product#" +
              this.blurhash1 +
              ",pimg" +
              this.blurhash2 +
              ",pimg" +
              this.blurhash3 +
              ",pimg" +
              this.blurhash4 +
              ",pimg" +
              this.blurhash5;
        }

        // console.log(allImages);
        // console.log(allBlurhashs);

        try {
          this.addingStatus = "adding data";
          await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                  mutation {
                    addProduct(

                              price: ${this.pprice},

                              sub_category_id: ${parseInt(
                                this.selectedSubCategory.split(",")[1]
                              )},

                              image: "${allImages}",
                              size_spec: "${this.selectedSizeSpec}",

                              blurhash: "${allBlurhashs}",

                             title_amharic: "${this.product_title_in_amharic.trim()}",
                              title_english:"${this.product_title_in_english.trim()}",
                              description_english: "${this.description_in_english
                                .toString()
                                .trim()
                                .replace(/\n/g, "\\n")}",
                              description_amharic: "${this.description_in_amharic
                                .toString()
                                .trim()
                                .replace(/\n/g, "\\n")}",

                              stock:${this.psock},
                              colors:"${this.selectedColors.toString()}",
                              sizes:"${allsize}",
                              stocks:"${allStock}",

                              ){
                                statusMessage
                              }
                      }

              `,
            },
          });
          this.dialog = false;
          this.addingStatus = "";
          alert("Product Added Successfully");
          this.$router
            .push({
              name: "products",
            })
            .catch((err) => {});
        } catch (err) {
          this.dialog = false;
          alert(err);
        }
      } else alert("Please fill all required fields with correct data");
    },
    async uploadImage1(img) {
      var storageReference = firebase
        .storage()
        .ref("supplierimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(img)).ref
        .getDownloadURL()
        .then((url) => {
          console.log(this.imageURL1);
          this.imageURL1 = url;
        });

      // var task = await storageReference.put(this.files[0]);
      // task.on(
      //   "state_changed",
      //   function progress(snapshot) {
      //     var percentage =
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //     console.log(percentage + "%");
      //   },
      //   function error(err) {
      //     console.log(err);
      //   },
      //   function completed() {
      //     task.snapshot.ref.getDownloadURL().then((url) => {
      //       this.imageURL1 = url;
      //       console.log(this.imageURL1 + "  url1");
      // } else if (index == 1) {
      //   this.imageURL2 = url;
      //   console.log(this.imageURL2 + "  url2");
      // } else if (index == 2) {
      //   this.imageURL3 = url;
      //   console.log(this.imageURL3 + "  url3");
      // } else if (index == 3) {
      //   this.imageURL4 = url;
      //   console.log(this.imageURL4 + "  url4");
      // } else if (index == 4) {
      //   this.imageURL5 = url;
      //   console.log(this.imageURL5 + "  url5");
      // }
      // });
      // }
      // );
    },
    async uploadImage2(img) {
      var storageReference = firebase
        .storage()
        .ref("supplierimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(img)).ref
        .getDownloadURL()
        .then((url) => {
          this.imageURL2 = url;
        });

      //   var task = await storageReference.put(this.files[1]);
      //   task.on(
      //     "state_changed",
      //     function progress(snapshot) {
      //       var percentage =
      //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //       console.log(percentage + "%");
      //     },
      //     function error(err) {
      //       console.log(err);
      //     },
      //     function completed() {
      //       task.snapshot.ref.getDownloadURL().then((url) => {
      //         this.imageURL2 = url;
      //         console.log(this.imageURL2 + "  url2");
      //         // } else if (index == 1) {
      //         //   this.imageURL2 = url;
      //         //   console.log(this.imageURL2 + "  url2");
      //         // } else if (index == 2) {
      //         //   this.imageURL3 = url;
      //         //   console.log(this.imageURL3 + "  url3");
      //         // } else if (index == 3) {
      //         //   this.imageURL4 = url;
      //         //   console.log(this.imageURL4 + "  url4");
      //         // } else if (index == 4) {
      //         //   this.imageURL5 = url;
      //         //   console.log(this.imageURL5 + "  url5");
      //         // }
      //       });
      //     }
      //   );
    },
    async uploadImage3(img) {
      var storageReference = firebase
        .storage()
        .ref("supplierimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(img)).ref
        .getDownloadURL()
        .then((url) => {
          this.imageURL3 = url;
        });
      // var task = await storageReference.put(this.files[2]);
      // task.on(
      //   "state_changed",
      //   function progress(snapshot) {
      //     var percentage =
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //     console.log(percentage + "%");
      //   },
      //   function error(err) {
      //     console.log(err);
      //   },
      //   function completed() {
      //     task.snapshot.ref.getDownloadURL().then((url) => {
      //       this.imageURL3 = url;
      //       console.log(this.imageURL3 + "  url3");
      //       // } else if (index == 1) {
      //       //   this.imageURL2 = url;
      //       //   console.log(this.imageURL2 + "  url2");
      //       // } else if (index == 2) {
      //       //   this.imageURL3 = url;
      //       //   console.log(this.imageURL3 + "  url3");
      //       // } else if (index == 3) {
      //       //   this.imageURL4 = url;
      //       //   console.log(this.imageURL4 + "  url4");
      //       // } else if (index == 4) {
      //       //   this.imageURL5 = url;
      //       //   console.log(this.imageURL5 + "  url5");
      //       // }
      //     });
      //   }
      // );
    },
    async uploadImage4(img) {
      var storageReference = firebase
        .storage()
        .ref("supplierimages/image" + Math.random() + ".jpg");
      await (await storageReference.put(img)).ref
        .getDownloadURL()
        .then((url) => {
          this.imageURL4 = url;
        });
      // var task = await storageReference.put(this.files[3]);
      // task.on(
      //   "state_changed",
      //   function progress(snapshot) {
      //     var percentage =
      //       (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //     console.log(percentage + "%");
      //   },
      //   function error(err) {
      //     console.log(err);
      //   },
      //   function completed() {
      //     task.snapshot.ref.getDownloadURL().then((url) => {
      //       this.imageURL4 = url;
      //       console.log(this.imageURL4 + "  url4");
      //       // } else if (index == 1) {
      //       //   this.imageURL2 = url;
      //       //   console.log(this.imageURL2 + "  url2");
      //       // } else if (index == 2) {
      //       //   this.imageURL3 = url;
      //       //   console.log(this.imageURL3 + "  url3");
      //       // } else if (index == 3) {
      //       //   this.imageURL4 = url;
      //       //   console.log(this.imageURL4 + "  url4");
      //       // } else if (index == 4) {
      //       //   this.imageURL5 = url;
      //       //   console.log(this.imageURL5 + "  url5");
      //       // }
      //     });
      //   }
      // );
    },
    async uploadImage5(img) {
      var storageReference = firebase
        .storage()
        .ref("supplierimages/image" + Math.random() + ".jpg");
      await (await storageReference.put(img)).ref
        .getDownloadURL()
        .then((url) => {
          this.imageURL5 = url;
        });
      // } else if (index == 1) {
      //   this.imageURL2 = url;
      //   console.log(this.imageURL2 + "  url2");
      // } else if (index == 2) {
      //   this.imageURL3 = url;
      //   console.log(this.imageURL3 + "  url3");
      // } else if (index == 3) {
      //   this.imageURL4 = url;
      //   console.log(this.imageURL4 + "  url4");
      // } else if (index == 4) {
      //   this.imageURL5 = url;
      //   console.log(this.imageURL5 + "  url5");
      // }

      //   on(
      // "state_changed",
      // function progress(snapshot) {
      //   var percentage =
      //     (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //   console.log(percentage + "%");
      // },
      // function error(err) {
      //   console.log(err);
      // },
      // function completed() {
      //   storageReference.getDownloadURL().then((url) => {
      //     this.imageURL5 = url;
      //     console.log(this.imageURL5 + "  url5");
      // } else if (index == 1) {
      //   this.imageURL2 = url;
      //   console.log(this.imageURL2 + "  url2");
      // } else if (index == 2) {
      //   this.imageURL3 = url;
      //   console.log(this.imageURL3 + "  url3");
      // } else if (index == 3) {
      //   this.imageURL4 = url;
      //   console.log(this.imageURL4 + "  url4");
      // } else if (index == 4) {
      //   this.imageURL5 = url;
      //   console.log(this.imageURL5 + "  url5");
      // }
      // });
    },
    async decodeImagetoBlurhash(url) {
      const loadImage = async (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (...args) => reject(args);
          img.src = src;
          img.crossOrigin = "";
        });

      const getImageData = (image) => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;

        canvas.height = image.height;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0);

        return context.getImageData(0, 0, image.width, image.height);
      };

      const image = await loadImage(url);
      const imageData = getImageData(image);

      return blurhash.encode(
        imageData.data,
        imageData.width,
        imageData.height,
        4,
        3
      );
    },
    selectColor(index, hexColor) {
      var removedIndex = this.selectedColors.indexOf(hexColor);
      if (this.selectedColors.includes(this.hexColors[index])) {
        this.selectedColorNames.splice(removedIndex, 1);
        this.selectedColors.splice(removedIndex, 1);
      } else {
        this.selectedColors.push(this.hexColors[index]);
        this.selectedColorNames.push(this.colorNames[index]);
      }
    },
    // reset() {
    //   this.$refs.form.reset();
    // },
  },
  created() {
    this.colorCounter = this.colorCounter + 1;
    this.imageURLS.push({
      image1: "",
      image2: "",
      image3: "",
      image4: "",
      image5: "",
    });
    this.sizeCounter.push({ count: 6 });
    this.productSize.push({ size: ["S", "M", "L", "XL", "XXL", "XXXL"] });
    this.productStock.push({ stock: ["0", "0", "0", "0", "0", "0"] });
    this.allFilesImg.push({ files: ["", "", "", "", ""] });

    this.getPrivilages();

    // this.getColors();
    // this.fetchAllSaleTypes();
  },
};
</script>

<style scoped>
.horizontalDivider {
  margin-top: 48px;
}
#shadow {
  box-shadow: 0px 0px 10px rgb(216, 215, 221);
}

#report {
  max-width: 1110px;
  margin: auto;
}

#imageCamera {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgb(189, 159, 159);
  font-size: 10px;
}

#color:hover {
  background-color: rgb(226, 220, 220);
}
</style>
