<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <div
        v-if="
          $store.state.admin.privilage == 'Super Admin' ||
          $store.state.admin.privilage == 'Sales Manager' ||
          $store.state.admin.privilage == 'Sales'
        "
        class="mt-1"
        style="font-size: 15px"
      >
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div>
          <v-layout
            row
            wrap
            class="text-center"
            id="dashboard"
            v-if="!pageLoading"
          >
            <v-flex xs12 md4>
              <a
                @click="
                  $router
                    .push({
                      name: 'orders',
                    })
                    .catch((err) => {})
                "
              >
                <v-card
                  outlined
                  class="ma-2 pt-10 pb-10 pa-7 pr-1 grey--text text--darken-2"
                  height="200px"
                >
                  <v-layout row wrap>
                    <v-flex xs10>
                      <h4>Today's Order History</h4>
                      <br />
                      <span
                        >Total Orders:
                        <u
                          ><strong>{{ todayTotalOrders }}</strong></u
                        ></span
                      ><br />
                      <span class="red--text"
                        >Unpaid Orders
                        <u
                          ><strong>{{ unpaidOrder }}</strong></u
                        ></span
                      >
                      <br />

                      <span
                        >Pending Orders:
                        <u
                          ><strong>{{ pendingOrder }}</strong></u
                        ></span
                      >

                      <br />

                      <span
                        >Completed Orders
                        <u
                          ><strong>{{ completed }}</strong></u
                        ></span
                      >
                    </v-flex>
                    <v-flex xs2>
                      <!-- <v-icon large class="green--text text--lighten-1"
                      >monetization_on</v-icon
                    > -->
                      <v-avatar size="35" class="green lighten-1">
                        <v-icon class="white--text text--lighten-1"
                          >trending_up</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-card>
              </a>
            </v-flex>
            <v-flex xs12 md4>
              <a @click="deailDialog = true">
                <v-card
                  outlined
                  class="ma-2 pt-10 pb-10 pa-7 pr-1 grey--text text--darken-2"
                  height="200px"
                >
                  <v-layout row wrap>
                    <v-flex xs10>
                      <h4>Today's Transaction &nbsp;</h4>
                      <br />
                      <span
                        >Sold Products:
                        <strong>{{ totalSold }}</strong>
                      </span>

                      <br />
                      <span
                        >Total Sale:
                        <strong>{{ totalMoney }}</strong>
                        Birr</span
                      >
                    </v-flex>
                    <v-flex xs2>
                      <v-avatar size="35" class="blue lighten-1">
                        <v-icon class="white--text text--lighten-1"
                          >monetization_on</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-card>
              </a>
            </v-flex>
            <v-flex xs12 md4>
              <a
                @click="
                  $router
                    .push({
                      name: 'users',
                    })
                    .catch((err) => {})
                "
              >
                <v-card
                  outlined
                  class="ma-2 pt-10 pb-10 pa-7 pr-1 grey--text text--darken-2"
                  height="200px"
                >
                  <v-layout row wrap>
                    <v-flex xs10>
                      <h4>User Status</h4>
                      <br />
                      <span
                        >Total Users:
                        <u
                          ><strong>{{ registeredUsers }}</strong></u
                        ></span
                      ><br />

                      <span
                        >Active Users:
                        <u
                          ><strong>{{ registeredUsers }}</strong></u
                        ></span
                      ><br />
                      <span class="red--text"
                        >Suspended Users:
                        <u
                          ><strong>{{ 0 }}</strong></u
                        ></span
                      >
                    </v-flex>
                    <v-flex xs2>
                      <!-- <v-icon large class="green--text text--lighten-1"
                      >monetization_on</v-icon
                    > -->

                      <v-avatar size="35" class="orange darken-2">
                        <v-icon class="white--text text--darken-1"
                          >electric_bolt</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-card>
              </a>
            </v-flex>
          </v-layout>

          <div
            v-if="!pageLoading"
            style="border-radius: 3px"
            class="white pt-3 mt-8"
          >
            <v-layout row wrap justify-space-around="">
              <v-flex xs12 md5>
                <div id="chooseDate" class="ml-10 mr-10 pl-10">
                  <h4 class="mt-6 grey--text text--darken-1 ml-10">
                    Order Summary
                  </h4>

                  <v-menu rounded="lg" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        text
                        class="pl-6 pr-6 mt-2"
                        v-bind="attrs"
                        v-on="on"
                        outlined=""
                      >
                        {{ choosedDateRange }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="group"
                        active-class="deep-purple--text text--accent-4"
                      >
                        <v-list-item
                          v-for="item in items"
                          :key="item"
                          @click="
                            if (item == 'Custom Range') {
                              dateDialog = true;
                            } else {
                              getDailyWorks(item);
                            }
                          "
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mb-15 pa-3 pt-0">
              <horizontal-scroll
                id="report"
                class="horizontal-scroll white pa-3 pt-0"
                style="border-radius: 5px"
              >
                <table style="width: 100%" class="ma-3 mt-0 text-center">
                  <tr>
                    <th>Day</th>
                    <th>Total Orders</th>
                    <th>Unpaid Orders</th>
                    <th>Pending Orders</th>
                    <th>Completed Orders</th>
                    <th>Cancelled Orders</th>

                    <th>Sold Products</th>
                    <th>Total Money</th>
                    <th>Action</th>
                  </tr>
                  <tr v-for="(item, i) in dailyReport" :key="i">
                    <td style="font-size: 11px">
                      <v-icon small>date_range</v-icon>
                      {{ weekday[new Date(item.day).getDay()] }}
                      <br v-if="item.date != ''" />
                      <span class="ml-4">{{ item.date }}</span>
                    </td>
                    <td>
                      {{ item.tOrders }}
                    </td>
                    <td>
                      {{ item.unpOrders }}
                    </td>
                    <td>
                      {{ item.pOrders }}
                    </td>

                    <td>
                      {{ item.cOrders }}
                    </td>
                    <td>
                      {{ item.cancelledOrders }}
                    </td>

                    <td>
                      {{ item.sProducts }}
                    </td>

                    <td>
                      {{ item.totalMoney }}
                      <span style="font-size: 11px">ETB</span>
                    </td>

                    <td>
                      <a @click="summaryDetailDialog = true" class="blue--text"
                        >Detail</a
                      >
                    </td>

                    <hr />
                  </tr>
                </table>
              </horizontal-scroll>
            </v-layout>
          </div>
        </div>

        <v-dialog v-model="dateDialog" persistent max-width="420">
          <v-card class="">
            <v-card-title>
              Choose date range <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="blue"
                text
                @click="
                  dateDialog = false;
                  if (date1 != '' && date2 != '') {
                    getDailyWorks('Custom Range');
                  }
                "
              >
                GO
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date1"
                        label="Date 1"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date1"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="Date 2"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deailDialog" persistent max-width="1000">
          <v-card class="">
            <v-card-title>
              <v-spacer></v-spacer> Transaction Detail <v-spacer></v-spacer>
              <v-btn class="red--text" text @click="deailDialog = false">
                X
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p class="text-center">
                <span
                  >Sold Products:
                  <strong>{{ totalSold }}</strong>
                </span>

                <br />
                <span
                  >Total Sale:
                  <strong>{{ totalMoney }}</strong>
                  Birr</span
                >
              </p>
            </v-card-text>

            <v-card-text class="mt-2 pl-14">
              <v-layout
                wrap
                row
                v-for="(order, i) in transitionDeaile"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex class="" xs4>
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >SubTotal Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br /><br />
                  </span>
                </v-flex>

                <v-flex xs6 class="pa-2 pr-0">
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="50"
                          width="50"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex xs2 class="pa-1" v-if="order.total_price > 0">
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex xs12 v-if="order.total_price > 0">
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="summaryDetailDialog" persistent max-width="1000">
          <v-card class="">
            <v-card-title>
              <v-spacer></v-spacer> Summary Detail <v-spacer></v-spacer>
              <v-btn
                class="red--text"
                text
                @click="summaryDetailDialog = false"
              >
                X
              </v-btn>
            </v-card-title>
            <!-- <v-card-text>
              <p class="text-center">
                <span
                  >Sold Products:
                  <strong>{{ totalSold }}</strong>
                </span>

                <br />
                <span
                  >Total Sale:
                  <strong>{{ totalMoney }}</strong>
                  Birr</span
                >
              </p>
            </v-card-text> -->

            <v-card-text class="mt-2 pl-14">
              <v-layout
                wrap
                row
                v-for="(order, i) in dailyWork"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex class="" xs4>
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="(orderc, i) in order.order_description.split(':')"
                      :key="orderc + i"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >SubTotal Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br /><br />
                  </span>
                </v-flex>

                <v-flex xs6 class="pa-2 pr-0">
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="(orderc, i) in order.order_description.split(
                          ':'
                        )"
                        :key="orderc + 1000 + i"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="50"
                          width="50"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex xs2 class="pa-1" v-if="order.total_price > 0">
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex xs12 v-if="order.total_price > 0">
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Access Denied</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      todayTotalOrders: 0,
      pendingOrder: 0,
      paidOrder: 0,
      unpaidOrder: 0,
      completed: 0,
      totalSold: 0,
      totalMoney: 0,
      deailDialog: false,
      summaryDetailDialog: false,

      pageLoading: false,
      loadingCount: 0,
      dateDialog: false,
      jackpotDialog: false,
      kenoJackHistory: [],
      spinJackHistory: [],

      date1: "",
      date2: "",
      menu1: false,
      menu2: false,

      dailyWork: [],
      todayTotal: 0,
      todayOnhand: 0,
      todayTotalPaid: 0,
      yesterdayTotal: 0,
      yesterdayEarning: 0,
      yesterdayTotalPaid: 0,
      choosedDateRange: "",
      partnerBalance: "",
      partnerCreditBalance: 0,
      depositDialog: false,

      todaysWork: [],
      noBets: 0,
      noPiadTickets: 0,
      cancelledTickets: 0,

      winnersMoney: 0,
      winnersNoTickets: 0,
      winnersNoBets: 0,
      odds: [],
      attemptReport: [],
      attemptReportDialog: false,
      logoutReport: [],
      logoutReportDialog: false,

      winnerMoneyFrequency: [],
      spinWinnerMoneyFrequency: [],
      inputMoneyFrequency: [],
      spinInputMoneyFrequency: [],
      inputAndWinnersFrequencyPopUp: false,

      group: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      dailyReport: [],
      choosenDate: "Last 15 days",

      items: [
        "Today",
        "Yesterday",
        "Last 15 days",
        "Last 7 days",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],

      spinDailyWork: [],
      spinTodayTotal: 0,
      spinTodayOnhand: 0,
      spinTodayTotalPaid: 0,
      spinChoosedDateRange: "2023-10-23 - 2023-10-26",

      spinTodaysWork: [],
      spinNoBets: 0,
      spinNoPiadTickets: 0,
      spinCancelledTickets: 0,
      spinWinnersMoney: 0,
      spinWinnersNoTickets: 0,
      spinWinnersNoBets: 0,
      spinOdds: [],
      spinGroup: null,
      spinDailyReport: [],
      spinChoosenDate: "Last 15 days",

      spinItems: [
        "Today",
        "Yesterday",
        "Last 15 days",
        "Last 7 days",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],
      paidSpinMoneyFromYesterday: 0,
      paidKenoMoneyFromYesterday: 0,
      allDailyWork: [],
      kenoUnpaidList: [],
      spinUnpaidList: [],
      unpaidDialog: false,
      gameItems: ["All", "keno", "spin"],
      choosedGame: "All",

      clearLoading: false,
      registeredUsers: 0,
      transitionDeaile: [],
    };
  },

  methods: {
    async getCurrentStastics() {
      if (this.loadingCount == 0) this.pageLoading = true;

      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.url,

          data: {
            query: `{
              getOrderByDate(date1:"${date1}", date2:"${date2}"){
                order_id
                    product_id
                    is_cancelled
                    user_id
                    quantity
                    payment_type
                    total_price
                    delivery_fee
                    order_date
                    delivery_status
                    order_number
                    shipping_address
                    order_description
                    isPaid
                    needs_delivery
                    

                    getProducts{
                            product_id
                            sub_category_id
                            title_amharic
                            title_english
                            image
                            blurhash
                            price
                            description_english
                            description_amharic
                            posted_date
                            colors
                    sizes
                    stocks
                          }

                    getUser{
                      user_id
                      full_name
                      phone_number
                      user_address
                    }                 

                   }
              }
              `,
          },
        });

        this.todaysWork = todayResult.data.data.getOrderByDate;

        this.pendingOrder = 0;
        this.paidOrder = 0;
        this.unpaidOrder = 0;
        this.completed = 0;
        this.totalSold = 0;
        this.totalMoney = 0;
        var pendingList = [],
          unpaidList = [],
          completeList = [];
        this.transitionDeaile = [];

        for (let t = 0; t < this.todaysWork.length; t++) {
          if (this.todaysWork[t].delivery_status != "cl") {
            if (this.todaysWork[t].isPaid == 1) {
              this.totalMoney =
                this.totalMoney +
                this.todaysWork[t].total_price +
                this.todaysWork[t].delivery_fee;
              this.transitionDeaile.push(this.todaysWork[t]);
              for (
                let i = 0;
                i < this.todaysWork[t].order_description.split(":").length;
                i++
              ) {
                var od = this.todaysWork[t].order_description.split(":")[i];
                for (let j = 0; j < od.split("-")[1].split("/").length; j++) {
                  this.totalSold =
                    this.totalSold +
                    parseFloat(od.split("-")[1].split("/")[j].split("*")[1]);
                }
              }
              // this.pendingOrder = this.pendingOrder + 1;
              pendingList.push(this.todaysWork[t].order_number);
            } else {
              // this.unpaidOrder = this.unpaidOrder + 1;
              unpaidList.push(this.todaysWork[t].order_number);
            }
          } else {
            this.totalMoney =
              this.totalMoney +
              this.todaysWork[t].total_price +
              this.todaysWork[t].delivery_fee;
            this.transitionDeaile.push(this.todaysWork[t]);
            for (
              let i = 0;
              i < this.todaysWork[t].order_description.split(":").length;
              i++
            ) {
              var od = this.todaysWork[t].order_description.split(":")[i];
              for (let j = 0; j < od.split("-")[1].split("/").length; j++) {
                this.totalSold =
                  this.totalSold +
                  parseFloat(od.split("-")[1].split("/")[j].split("*")[1]);
              }
            }

            // this.totalSold =
            //   this.totalSold +
            //   this.todaysWork[t].quantity

            // this.completed = this.completed + 1;
            completeList.push(this.todaysWork[t].order_number);
          }
        }
        let uniqpend = [...new Set(pendingList)];
        let uniqunp = [...new Set(unpaidList)];
        let uniqcomp = [...new Set(completeList)];
        this.todayTotalOrders =
          uniqpend.length + uniqunp.length + uniqcomp.length;

        this.pendingOrder = uniqpend.length;
        this.unpaidOrder = uniqunp.length;
        this.completed = uniqcomp.length;
      } catch (err) {
        alert(err);
      }
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                    query{
                      countUsers{
                        product_count
                      }
                    }
                `,
          },
        });

        this.registeredUsers = result.data.data.countUsers[0].product_count;
      } catch (err) {}

      this.loadingCount++;
      this.pageLoading = false;
    },
    async getDailyWorks(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 15 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.choosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.choosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.url,

          data: {
            query: `{
              getOrderByDate(date1:"${date1}", date2:"${date2}"){
                      
                    order_id
                    product_id
                    is_cancelled
                    user_id
                    quantity
                    payment_type
                    total_price
                    delivery_fee
                    order_date
                    delivery_status
                    order_number
                    shipping_address
                    order_description
                    isPaid                   

                    getProducts{
                            product_id
                            sub_category_id
                            title_amharic
                            title_english
                            image
                            blurhash
                            price
                            description_english
                            description_amharic
                            posted_date
                            colors
                            sizes
                            stocks
                          }

                    getUser{
                      user_id
                      full_name
                      phone_number
                      user_address
                    }

                     

                   }
              }
              `,
          },
        });
        this.dailyWork = dailyResult.data.data.getOrderByDate;

        var allDays = [];
        this.dailyReport = [];

        if (
          this.choosenDate == "Today" ||
          this.choosenDate == "Yesterday" ||
          this.choosenDate == "Last 15 days"
        ) {
          for (let t = 0; t < this.dailyWork.length; t++) {
            // var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();
            var day = this.formatDate(
              new Date(Number(this.dailyWork[t].order_date))
            );

            if (allDays.indexOf(day) === -1) allDays.push(day);
          }
          for (let d = 0; d < allDays.length; d++) {
            var totalOrders = 0;

            var unpaidOrders = 0;
            var paidOrders = 0;
            var completedOrders = 0;
            var soldProducts = 0;
            var totalMoney = 0;
            var cancelledOrders = 0;
            var date = "";
            var pendingList = [],
              unpaidList = [],
              completeList = [];

            for (let t = 0; t < this.dailyWork.length; t++) {
              var day = this.formatDate(
                new Date(Number(this.dailyWork[t].order_date))
              );

              if (day == allDays[d]) {
                var month =
                  this.monthNames[
                    new Date(Number(this.dailyWork[t].order_date)).getMonth()
                  ];

                var eachSplitedDate = this.formatDate(
                  new Date(Number(this.dailyWork[t].order_date))
                ).split("-");
                date =
                  month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                if (this.dailyWork[t].is_cancelled == 0) {
                  if (this.dailyWork[t].delivery_status == "pl") {
                    if (this.dailyWork[t].isPaid == 1) {
                      totalMoney =
                        totalMoney +
                        this.dailyWork[t].total_price +
                        this.dailyWork[t].delivery_fee;
                      for (
                        let i = 0;
                        i <
                        this.dailyWork[t].order_description.split(":").length;
                        i++
                      ) {
                        var od =
                          this.dailyWork[t].order_description.split(":")[i];
                        for (
                          let j = 0;
                          j < od.split("-")[1].split("/").length;
                          j++
                        ) {
                          soldProducts =
                            soldProducts +
                            parseFloat(
                              od.split("-")[1].split("/")[j].split("*")[1]
                            );
                        }
                      }
                      pendingList.push(this.dailyWork[t].order_number);
                    } else unpaidList.push(this.dailyWork[t].order_number);
                  } else if (this.dailyWork[t].delivery_status == "cl") {
                    completeList.push(this.dailyWork[t].order_number);
                    for (
                      let i = 0;
                      i < this.dailyWork[t].order_description.split(":").length;
                      i++
                    ) {
                      var od =
                        this.dailyWork[t].order_description.split(":")[i];
                      for (
                        let j = 0;
                        j < od.split("-")[1].split("/").length;
                        j++
                      ) {
                        soldProducts =
                          soldProducts +
                          parseFloat(
                            od.split("-")[1].split("/")[j].split("*")[1]
                          );
                      }
                    }
                    totalMoney =
                      totalMoney +
                      this.dailyWork[t].total_price +
                      this.dailyWork[t].delivery_fee;
                  }
                } else cancelledOrders = cancelledOrders + 1;
              }
            }

            let uniqpend = [...new Set(pendingList)];
            let uniqunp = [...new Set(unpaidList)];
            let uniqcomp = [...new Set(completeList)];
            totalOrders = uniqpend.length + uniqunp.length + uniqcomp.length;

            paidOrders = uniqpend.length;
            unpaidOrders = uniqunp.length;
            completedOrders = uniqcomp.length;

            this.dailyReport.push({
              day: allDays[d],
              date: date,
              tOrders: totalOrders,
              unpOrders: unpaidOrders,
              pOrders: paidOrders,

              cOrders: completedOrders,
              cancelledOrders: cancelledOrders,
              sProducts: soldProducts,

              totalMoney: totalMoney,
            });
          }
        } else {
          var totalOrders = 0;

          var unpaidOrders = 0;
          var paidOrders = 0;
          var completedOrders = 0;
          var soldProducts = 0;
          var totalMoney = 0;
          var cancelledOrders = 0;
          this.dailyReport = [];
          var pendingList = [],
            unpaidList = [],
            completeList = [];

          for (let t = 0; t < this.dailyWork.length; t++) {
            if (this.dailyWork[t].is_cancelled == 0) {
              if (this.dailyWork[t].delivery_status == "pl") {
                if (this.dailyWork[t].isPaid == 1) {
                  totalMoney =
                    totalMoney +
                    this.dailyWork[t].total_price +
                    this.dailyWork[t].delivery_fee;
                  for (
                    let i = 0;
                    i < this.dailyWork[t].order_description.split(":").length;
                    i++
                  ) {
                    var od = this.dailyWork[t].order_description.split(":")[i];
                    for (
                      let j = 0;
                      j < od.split("-")[1].split("/").length;
                      j++
                    ) {
                      soldProducts =
                        soldProducts +
                        parseFloat(
                          od.split("-")[1].split("/")[j].split("*")[1]
                        );
                    }
                  }
                  pendingList.push(this.dailyWork[t].order_number);
                } else unpaidList.push(this.dailyWork[t].order_number);
              } else if (this.dailyWork[t].delivery_status == "cl") {
                completeList.push(this.dailyWork[t].order_number);
                for (
                  let i = 0;
                  i < this.dailyWork[t].order_description.split(":").length;
                  i++
                ) {
                  var od = this.dailyWork[t].order_description.split(":")[i];
                  for (let j = 0; j < od.split("-")[1].split("/").length; j++) {
                    soldProducts =
                      soldProducts +
                      parseFloat(od.split("-")[1].split("/")[j].split("*")[1]);
                  }
                }
                totalMoney =
                  totalMoney +
                  this.dailyWork[t].total_price +
                  this.dailyWork[t].delivery_fee;
              }
            } else cancelledOrders = cancelledOrders + 1;
          }
          let uniqpend = [...new Set(pendingList)];
          let uniqunp = [...new Set(unpaidList)];
          let uniqcomp = [...new Set(completeList)];
          totalOrders = uniqpend.length + uniqunp.length + uniqcomp.length;

          paidOrders = uniqpend.length;
          unpaidOrders = uniqunp.length;
          completedOrders = uniqcomp.length;

          this.dailyReport.push({
            day: "",
            date: "",
            tOrders: totalOrders,
            unpOrders: unpaidOrders,
            pOrders: paidOrders,

            cOrders: completedOrders,
            cancelledOrders: cancelledOrders,
            sProducts: soldProducts,

            totalMoney: totalMoney,
          });
        }

        // this.yesterdayEarning = this.yesterdayTotal - this.yesterdayTotalPaid;
        // this.todayEarning = this.todayTotal - this.todayTotalPaid;
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    await this.getCurrentStastics();
    await this.getDailyWorks("Last 15 days");
    if (this.$store.state.admin != "") {
      setInterval(async () => {
        await this.getCurrentStastics();
        await this.getDailyWorks("Last 15 days");
      }, 300000);
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 30px;
}

#chooseDate {
  max-width: 400px;

  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}

.outer {
  display: flex;
  flex: 1;

  height: 100%;

  flex-flow: row nowrap;
  align-items: center;
  width: fit-content;
  max-width: 500px;
  display: flex;
  overflow-x: auto;
}
.inner-content {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
}
.inner-content:not(:first-of-type) {
  margin-left: 30px;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
