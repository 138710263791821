<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager'
    "
  >
    <p class="headline white--text ml-5">UI</p>
    <v-row v-if="loadingUi" class="text-center">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </v-row>
    <v-row
      v-else
      class="container white pa-16 pl-0 pr-2 pt-1"
      id="report"
      style="border-radius: 3px; font-size: 13px"
    >
      <v-flex xs12 sm6 md3 v-for="(ui, i) in allUi" :key="i">
        <v-card
          class="uiProducts mr-3 ma-2"
          v-long-press="500"
          @long-press-start="onLongPressStart(ui)"
          @long-press-stop="onLongPressStop"
        >
          <span class="mx-2"> {{ ui.title_english }} </span>
          <!-- <span class="mx-2">
            {{ ui.box_type }}
          </span> -->
          <blur-hash-image :hash="ui.blurhash" :src="ui.image" contain />
        </v-card>
      </v-flex>
    </v-row>

    <v-btn
      class="fab grey lighten-2 text-capitalize"
      fab
      @click="
        navigator({
          name: 'uiAdder',
        })
      "
    >
      <v-icon> add </v-icon>
    </v-btn>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import LongPress from "vue-directive-long-press";

export default {
  directives: {
    "long-press": LongPress,
  },
  data() {
    return {
      allUi: [],
      allProducts: [],
      loadingUi: false,
    };
  },
  components: {
    HorizontalScroll,
  },
  methods: {
    async onLongPressStart(ui) {
      const deleteConfirmation = confirm(
        "Are you sure you want to delete this UI?"
      );
      if (deleteConfirmation) {
        try {
          var deleteResult = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                deleteUI(ui_id:${ui.ui_id})
                   {
                 statusMessage
                   }
              }
              `,
            },
          });

          var response = deleteResult.data.data.deleteUI;
          if (response[0].statusMessage) {
            this.getUiAdder();
          }
        } catch (err) {
          alert(err);
        }
      }
    },

    onLongPressStop() {
      // do something
    },
    navigator(route) {
      this.$router.push(route).catch((err) => {
        if (err.name != "NavigationDuplicated") throw err;
      });
      // this.routeName = this.$route.name;
      window.scrollTo(0, 0);
    },
    async getUiAdder() {
      this.loadingUi = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                  {
                    getUI{
                      ui_id
                      url_id
                      title_amharic
                      title_english
                      image
                      blurhash
                      description_english
                      description_amharic
                      box_type
                       
                    }
                    }
                `,
          },
        });

        this.allUi = result.data.data.getUI;

        this.loadingUi = false;
      } catch (err) {
        alert(err);
      }
      this.loadingUi = false;
    },
  },
  created() {
    if (this.$store.state.admin != "") {
      this.getUiAdder();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
.fab {
  position: fixed;
  padding: 2em;
  right: 5%;
  bottom: 10%;
}

.uiProducts {
  /* display: block; */
  /* width: 100%; */
  height: 350px;
  overflow: auto;
}

.product {
  border-style: solid;
  border-width: 1px;
  margin-bottom: 2px;
}

.horizontal-scroll {
  display: flex;

  height: 150px;
}

#report {
  max-width: 1110px;
  margin: auto;
}
</style>
