<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager'
    "
  >
    <p class="headline white--text ml-5">Blog</p>
    <div v-if="loadingUi" class="text-center">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-layout
      row
      wrap
      v-else
      class="container white pl-6 pr-15 pt-5 pb-10"
      id="report"
      style="font-size: 16px; border-radius: 3px"
    >
      <v-flex xs12 v-for="(blog, i) in allBlog" :key="i" class="">
        <div class="pb-4" v-if="i != 0">
          <v-divider></v-divider>
        </div>
        <v-layout row wrap justify-space-around>
          <v-flex xs9 class="pl-2 pt-4 pr-3 text-center">
            <v-img :src="blog.image" contain width="100%" height="300"></v-img>

            <!-- <v-btn @click="onLongPressStart(blog.blog_id)" x-small text>
              <v-icon class="red--text">delete</v-icon>
            </v-btn> -->
          </v-flex>
          <v-flex xs12>
            <div class="mr-3 ma-2">
              <h3 class="pt-4 pb-3 text-center text-capitalize">
                {{ blog.title }}
              </h3>

              <div
                v-for="(post, i) in blog.news.split(':gfr:')"
                :key="i"
                class="pl-10 pr-10"
              >
                <p v-if="post != ''">
                  {{ post }}
                  <br />
                </p>
                <v-img
                  :src="blog.feature.split(':gfr:')[i]"
                  contain
                  height="200px"
                  width="700px"
                  v-if="
                    blog.feature.split(':gfr:')[i] != '' &&
                    blog.feature.split(':gfr:')[i] != undefined
                  "
                ></v-img>

                <br />
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="addBlog"
      max-width="600px"
      @click:outside="
        title = '';
        news = [];
        allImages = [];

        imageUrl = '';
        image1 = '';
        $refs.form.reset();
      "
    >
      <v-container class="white">
        <v-form
          class="container white pa-4 pt-1"
          id="report"
          style="border-radius: 3px; font-size: 13px"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row justify="center">
            <v-flex xs12 md8>
              <v-card
                elevation="0"
                light
                @dragend="OnDragEnter"
                @dragleave="OnDragLeave"
                @dragover.prevent
                @drop="OnDrop"
                class="text-center ma-5"
              >
                <input
                  type="file"
                  accept="image/*"
                  ref="selectImage"
                  style="display: none"
                  @change="OnInputFile"
                />
                <input
                  type="file"
                  accept="image/*"
                  ref="changeImage"
                  style="display: none"
                  @change="OnInputFile"
                />

                <input
                  type="file"
                  accept="image/*"
                  ref="selectImagem"
                  style="display: none"
                  @change="OnInputFilem"
                />
                <input
                  type="file"
                  accept="image/*"
                  ref="changeImagem"
                  style="display: none"
                  @change="OnEditImage"
                />

                <v-img
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  class="ml-5 mr-5"
                  :src="image1"
                  height="142"
                  contain
                  v-if="image1 != ''"
                  @click="changeImage()"
                ></v-img>

                <div
                  @mouseenter="changeCursor"
                  id="imageCamera"
                  class="ml-5 mr-5 text-center"
                  v-if="image1 == ''"
                  @click="simulateInputfileClicked"
                >
                  <!-- <v-btn centered outlined large fab class="ma-4"> -->
                  <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
                  <br />
                  <br />
                  <br />
                  <p class="text-center grey--text">Select Image</p>
                  <!-- </v-btn> -->
                </div>
              </v-card>
            </v-flex>
          </v-row>

          <v-row justify="center">
            <v-flex xs12>
              <v-text-field
                v-model="title"
                style="font-size: 11px"
                :rules="[(v) => !!v || 'title is required']"
                label="Enter title"
                required
                dense
                outlined
                class="mx-5"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 v-for="(newt, i) in news" :key="i">
              <p v-if="i != news.length - 1" class="pa-2">
                {{ news[i].toString().trim().replace(/\n/g, "\\n") }}
              </p>
              <v-textarea
                v-if="i == news.length - 1"
                style="font-size: 16px"
                rows="3"
                v-model="news[i]"
                label="Type Here..."
                dense
                multi-line
                class="mx-5"
                flat
                solo
              />
              <v-img
                @mouseenter="changeCursor"
                :src="allImages[i]"
                height="142"
                contain
                v-if="allImages[i] != '' && allImages[i] != undefined"
                @click="changeImagem(i)"
              ></v-img>

              <div class="text-right" v-if="i == news.length - 1">
                <v-btn
                  small
                  text
                  class="text-center mr-4"
                  @click="
                    simulateInputfileClickedm(i);
                    news.push('');
                  "
                >
                  <!-- <v-btn centered outlined large fab class="ma-4"> -->
                  <v-icon small centered class="grey--text">camera_alt</v-icon>

                  <!-- </v-btn> -->
                </v-btn>
              </div>
            </v-flex>
          </v-row>

          <v-row justify="center">
            <v-btn
              text
              class="px-10 grey lighten-2 text-capitalize"
              small
              @click="addBlogs"
            >
              Add Blog
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-dialog>

    <v-dialog v-model="addingDialog" persistent width="300">
      <v-card color="primary text-center" dark>
        <v-card-text>
          {{ addingStatus }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn
      class="fab grey lighten-2 text-capitalize"
      fab
      @click="
        addBlog = true;
        news = [];
        allImages = [''];
        news = [''];
        allImages = [''];
        newimg = '';
      "
    >
      <v-icon> add </v-icon>
    </v-btn>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import * as blurhash from "blurhash";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import LongPress from "vue-directive-long-press";

export default {
  directives: {
    "long-press": LongPress,
  },
  data() {
    return {
      allBlog: [],
      allProducts: [],
      loadingUi: false,
      addBlog: false,
      image1: "",
      imageUrl1: "",
      blurhash1: "",
      files: [],
      title: "",
      news: [""],
      allImages: [""],
      newimg: "",
      allImagesFiles: [""],

      addingStatus: "",
      addingDialog: false,

      valid: true,
      colorImageIndex: "",
    };
  },
  components: {
    HorizontalScroll,
  },
  methods: {
    async onLongPressStart(id) {
      const deleteConfirmation = confirm(
        "Are you sure you want to delete this blog?"
      );
      if (deleteConfirmation) {
        try {
          var deleteResult = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                  deleteBlog(blog_id:${id})
                     {
                   statusMessage
                     }
                }
                `,
            },
          });

          var response = deleteResult.data.data.deleteBlog;
          if (response[0].statusMessage) {
            this.getBlog();
          }
        } catch (err) {
          alert(err);
        }
      }
    },

    onLongPressStop() {
      // do something
    },
    navigator(route) {
      this.$router.push(route).catch((err) => {
        if (err.name != "NavigationDuplicated") throw err;
      });
      // this.routeName = this.$route.name;
      window.scrollTo(0, 0);
    },
    async getBlog() {
      this.loadingUi = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                    {
                    getBlog{
                        blog_id
                        title
                        news
                        image
                        blurhash
                        video
                        feature
                       feaureblurhash
                      }
                      }
                  `,
          },
        });

        this.allBlog = result.data.data.getBlog;

        this.loadingUi = false;
      } catch (err) {
        alert(err);
      }
      this.loadingUi = false;
    },

    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();
      console.log("entering");
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      console.log("leaving");
    },
    OnDrop(e) {
      console.log("dropping");
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      console.log(files);
      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = [];
      Array.from(files).forEach((file) => this.addImage(file));
      console.log(this.files);
    },
    OnInputFilem(e) {
      const files = e.target.files;

      Array.from(files).forEach((file) => this.addImagem(file));
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },

    simulateInputfileClickedm(i) {
      this.colorImageIndex = i;
      this.$refs.selectImagem.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        console.log(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    addImagem(file) {
      if (!file.type.match("image.*")) {
        console.log(`${file.name} is not an image`);
        return;
      }
      // this.files.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        if (this.newimg == "") this.newimg = f.target.result;
        else this.newimg = this.newimg + "-pmi-" + f.target.result;

        this.allImagesFiles[this.colorImageIndex] = file;
        var allImages = [];
        for (let i = 0; i <= this.allImages.length; i++) {
          if (i == this.colorImageIndex) {
            allImages[i] = f.target.result;
          } else allImages[i] = this.allImages[i];
        }
        this.allImages = allImages;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    changeImagem(i) {
      this.colorImageIndex = i;
      this.$refs.changeImagem.click();
    },
    async uploadImage() {
      var storageReference = firebase
        .storage()
        .ref("adminimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(this.files[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl1 = url;
          console.log(this.imageUrl + "  url1");
        });
    },

    async uploadImagem(img) {
      var storageReference = firebase
        .storage()
        .ref("adminimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(img)).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl1 = url;
          console.log(this.imageUrl + "  url1");
        });
    },
    async decodeImagetoBlurhash(url) {
      const loadImage = async (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (...args) => reject(args);
          img.src = src;
          img.crossOrigin = "";
        });

      const getImageData = (image) => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;

        canvas.height = image.height;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0);

        return context.getImageData(0, 0, image.width, image.height);
      };

      const image = await loadImage(url);
      const imageData = getImageData(image);

      return blurhash.encode(
        imageData.data,
        imageData.width,
        imageData.height,
        4,
        3
      );
    },

    OnEditImage(e) {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = (f) => {
        this.allImagesFiles[this.colorImageIndex] = file;
        var allImages = [];
        for (let i = 0; i < this.allImages.length; i++) {
          if (i == this.colorImageIndex) {
            allImages[i] = f.target.result;
          } else allImages[i] = this.allImages[i];
        }
        this.allImages = allImages;
      };

      reader.readAsDataURL(file);
    },

    async addBlogs() {
      if (this.files.length < 1) {
        alert("Please add feature image");
        return;
      }

      if (this.$refs.form.validate()) {
        var finalNews = "",
          finimg = "",
          finblur,
          coverurl = "";
        try {
          this.addingStatus = "uploading image";
          this.addingDialog = true;
          await this.uploadImage();
          this.blurhash1 = await this.decodeImagetoBlurhash(this.image1);
          coverurl = this.imageUrl1;

          for (let k = 0; k < this.news.length; k++) {
            var locblur = "";
            this.imageUrl1 = "";
            if (finalNews == "") finalNews = this.news[k];
            else finalNews = finalNews + ":gfr:" + this.news[k];

            if (this.allImages[k] != "" && this.allImages[k] != undefined) {
              await this.uploadImagem(this.allImagesFiles[k]);
              locblur = await this.decodeImagetoBlurhash(this.allImages[k]);
            }

            if (finimg == "") finimg = this.imageUrl1;
            else finimg = finimg + ":gfr:" + this.imageUrl1;

            if (finblur == "") finblur = locblur;
            else finblur = finblur + ":gfr:" + this.locblur;
          }
        } catch (error) {
          console.log(error);
        }

        try {
          this.addingStatus = "adding data";
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                 mutation {
                addBlog(
                    title:"${this.title}"
                    news:"${finalNews.toString().trim().replace(/\n/g, "\\n")}"
                    image:"${coverurl}"
                    feature:"${finimg}"
                    blurhash:"${this.blurhash1}"
                    feaureblurhash:"${finblur}"
                    video:"${""}"                   

                    ){
                        statusMessage
                    }
                    }
              `,
            },
          });
          if (result.data.data.addBlog[0].statusMessage) {
            this.addingDialog = false;
            this.addingStatus = "";

            alert("Blog Added Successfully");
            this.addBlog = false;
            this.getBlog();
          } else {
            this.addingDialog = false;
            this.addingStatus = "";

            alert("Something went wrong, please try again");
          }
        } catch (err) {
          this.addingDialog = false;
          this.addingStatus = "";

          alert(err);
        }
      }
    },
  },

  mounted() {
    this.allImages = this.allImages;
  },
  created() {
    if (this.$store.state.admin != "") {
      this.getBlog();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
.fab {
  position: fixed;
  padding: 2em;
  right: 5%;
  bottom: 10%;
}

.product {
  border-style: solid;
  border-width: 1px;
  margin-bottom: 2px;
}

.horizontal-scroll {
  display: flex;

  height: 150px;
}

#report {
  max-width: 1110px;
  margin: auto;
}

#imageCamera {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(189, 159, 159);
  font-size: 10px;
}
</style>
