<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager' ||
      $store.state.admin.privilage == 'Sales'
    "
  >
    <p class="headline white--text ml-5">Trendy Products</p>

    <div v-if="trendyProductsLoading" class="text-center">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-layout
      wrap
      row
      v-if="!trendyProductsLoading"
      class="container white pa-16 pl-10 pr-10 pt-1"
      id="report"
      style="border-radius: 3px; font-size: 13px"
    >
      <v-flex
        xs6
        md2
        class="pr-1"
        v-for="(product, i) in trendyProducts"
        :key="i"
      >
        <br />
        <blur-hash-image
          :hash="product.getProducts[0].blurhash.split(',pimg')[0].trim()"
          :src="product.getProducts[0].image.split(',pimg')[0].trim()"
          height="150"
        />
        <br />
        <span class="d-inline-block text-truncate" style="max-width: 170px">
          {{ product.getProducts[0].title_english }}</span
        ><br />
        <span> {{ product.getProducts[0].price }} Birr</span><br />
        <br />
        <div class="mt-2">
          <v-btn text small @click="removeTrendyProduct(product)">
            <span><v-icon small class="red--text">delete</v-icon></span></v-btn
          >

          <v-progress-linear
            v-if="removeLoading && productId == product.productId"
            color="deep-purple accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="searchProduct"
      max-width="1200px"
      @click:outside="
        allProducts = [];
        searchQuery = '';
      "
    >
      <v-container class="white pb-15">
        <v-flex xs3 class="mt-2 mb-10">
          <v-text-field
            color="deep-purple accent-4"
            label="Search products"
            append-icon="search"
            :hide-details="true"
            v-model="searchQuery"
            clearable
            outlined
            required
            dense
          ></v-text-field>
        </v-flex>
        <div v-if="loadingProducts" class="text-center">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-row v-if="!loadingProducts && allProducts.length > 0">
          <v-flex
            xs2
            v-for="(product, i) in allProducts"
            :key="i"
            class="pl-2 mt-2"
          >
            <v-card>
              <blur-hash-image
                :hash="product.blurhash.split(',pimg')[0].trim()"
                :src="product.image.split(',pimg')[0].trim()"
                height="150px"
                contain
                width="120"
              />

              <v-card-text
                class="d-inline-block text-truncate"
                style="max-width: 140px; font-size: 16px"
                >{{ product.title_english }}
                <br />
                {{ product.price }} ETB
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="blue--text text-capitalize"
                  text
                  small
                  @click="
                    trendyProduct = product;
                    addToTrendy();
                  "
                >
                  Add to trendy
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-row>
      </v-container>
    </v-dialog>

    <v-btn
      class="fab grey lighten-2 text-capitalize"
      fab
      @click="searchProduct = true"
    >
      <v-icon> add </v-icon>
    </v-btn>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      trendyProducts: [],
      trendyProductsLoading: false,

      removeLoading: false,
      productId: "",
      searchQuery: "",

      loadingCount: 0,
      searchProduct: false,
      loadingProducts: false,
      allProducts: [],
    };
  },
  methods: {
    async getAllProducts(searchValue, row_length) {
      if (searchValue == null) {
        searchValue = "";
      }

      this.loadingProducts = true;

      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `query {
                     searchingProducts(searchValue: "${searchValue}",row_length: ${row_length}) {
                                 product_id
                                 sub_category_id
                                 stock
                                 title_amharic
                                 title_english
                                 image
                                 blurhash 
                                 price 
                                 description_english
                                 description_amharic
                                 posted_date
                                 colors
                                 sizes
                                 stocks

                                 getSubCategory{
                                     sub_category_id
                                     category_id
                                     title_amharic
                                     title_english
                                     image
                                     blurhash
                                     getCategory{
                                      category_id
                                      title_english
                                     }
                         
                                  }
                          }
                          }

                  `,
          },
        });

        this.allProducts = result.data.data.searchingProducts;

        this.loadingProducts = false;
      } catch (err) {
        alert(err);
      }
    },
    async getTrendyProducts() {
      if (this.loadingCount == 0) this.trendyProductsLoading = true;
      try {
        var trendyResult = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `{
                getTrendyProducts{

                  trendy_id
                  product_id
                  getProducts{
                            product_id
                            sub_category_id
                            title_amharic
                            title_english
                            image
                            blurhash
                            price
                            description_english
                            description_amharic
                            posted_date
                          }
    
   
  }             
            }
              `,
          },
        });

        this.trendyProducts = trendyResult.data.data.getTrendyProducts;
      } catch (err) {
        if (this.loadingCount == 0) alert(err);
        this.loadingCount++;
      }

      if (this.loadingCount == 0) this.trendyProductsLoading = false;
      this.loadingCount++;
    },

    async removeTrendyProduct(product) {
      this.productId = product.productId;
      const deleteConfirmation = confirm(
        "Are you sure you want to remove this trendy product?"
      );
      if (deleteConfirmation) {
        this.removeLoading = true;
        try {
          var removeResult = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                 deleteTrendyProduct(trendy_id:${product.trendy_id}){
                    statusMessage
                      }               
              }
              `,
            },
          });

          var response = removeResult.data.data.deleteTrendyProduct;
          if (response[0].statusMessage) {
            this.getTrendyProducts();
          }
        } catch (err) {
          alert(err);
        }

        this.removeLoading = false;
      }
    },
    async addToTrendy() {
      const deleteConfirmation = confirm(
        "Are you sure you want to add trendy product?"
      );
      if (deleteConfirmation) {
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                  mutation {
                    addTrendy(product_id: ${this.trendyProduct.product_id}) {
                        statusMessage
                      }
                    }
                `,
            },
          });
          if (result.data.data.addTrendy[0].statusMessage) {
            this.searchProduct = false;
            alert("Added Successfully");
            this.getTrendyProducts();
          } else {
            alert("Something went wrong, please try again");
          }
        } catch (err) {
          alert(err);
        }
      }
    },
  },
  watch: {
    searchQuery: _.debounce(function (value) {
      if (value != "") {
        this.getAllProducts(
          value,

          0
        );
      }

      //  else {
      //   console.log("some category selected");
      //   this.getAllProducts(
      //     value,
      //     this.$store.state.supplier_id,
      //     0,
      //     this.selectedCategory
      //   );
      // }
    }, 500),
  },

  async created() {
    if (this.$store.state.admin != "") {
      this.getTrendyProducts();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#report {
  max-width: 1110px;
  margin: auto;
}
.fab {
  position: fixed;
  padding: 2em;
  right: 5%;
  bottom: 10%;
}
</style>
