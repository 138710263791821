<template>
  <v-container
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager' ||
      $store.state.admin.privilage == 'Sales'
    "
  >
    <p class="headline white--text ml-5">Products</p>
    <!-- dialog for deletion -->
    <!-- dialog for deletion -->
    <!-- dialog for deletion -->
    <!-- dialog for deletion -->
    <!-- dialog for deletion -->
    <v-dialog v-model="deletePopUp" max-width="290">
      <v-card>
        <v-card-title class="headline">
          <strong> Delete Product </strong>
        </v-card-title>
        <v-card-text>Are You Sure to delete this product ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteProduct()">
            <v-icon>delete</v-icon>Yes
          </v-btn>
          <v-btn color="green darken-1" text @click="deletePopUp = false">
            <v-icon>arrow_back_ios</v-icon> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog for deletion end here-->
    <!-- dialog for deletion end here-->
    <!-- dialog for deletion end here-->
    <!-- dialog for deletion end here-->
    <!-- dialog for deletion end here-->

    <!-- snackbar -->
    <v-snackbar v-model="snackbar">
      {{ status }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="(snackbar = false), (status = '')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- snackbar -->
    <div
      class="container white pa-16 pl-0 pr-2 pt-1"
      id="report"
      style="border-radius: 3px; font-size: 13px"
    >
      <v-row class="pt-4">
        <v-flex xs12 sm6 md3 class="text-center">
          <span class="headline text-center"> {{ postedProducts }} </span><br />
          <span>Posted Products</span>
        </v-flex>

        <v-flex xs12 sm6 md3 class="text-center pt-2">
          <v-btn
            @click="
              $router
                .push({
                  name: 'addProduct',
                })
                .catch((err) => {
                  throw err;
                })
            "
            class="grey lighten-2 text-capitalize"
            text
            >Add Product</v-btn
          >
        </v-flex>
      </v-row>

      <!-- <v-row>
        <v-flex xs12 sm6 md3>
         
         
              <p class="display-1 text--primary text-center">
                {{ postedProducts }}
              </p>
              <p class="text-center">Posted Products</p>
          
        </v-flex>

        <v-flex xs12 sm6 md3>
         
              <v-btn
                @click="
                  $router
                    .push({
                      name: 'addProduct',
                    })
                    .catch((err) => {
                      throw err;
                    })
                "
                class="primary mb-5"
                elevation="0.0"
                text
                >Add Product</v-btn
              >

              <p>Add new product</p>
          
        </v-flex>
      </v-row> -->

      <!-- searching and filtering products -->
      <v-layout justify-space-around>
        <v-flex xs12 sm6 md3 class="mt-2 align-content-center pt-5">
          <v-text-field
            color="deep-purple accent-4"
            label="Search products"
            append-icon="search"
            :hide-details="true"
            v-model="searchQuery"
            clearable
            outlined
            required
            dense
          ></v-text-field>
        </v-flex>
        <!-- <v-flex xs12 sm6 md3 class="mt-2 align-content-center">
          <v-select
            v-model="selectedCategory"
            :items="categories"
            label="Category"
            @change="filterProduct"
            clearable
          ></v-select>
        </v-flex> -->
        <v-flex xs12 sm6 md3 class="mt-2 align-content-center pt-5">
          <v-select
            v-model="selectedSort"
            :items="sortingMethods"
            label="sort"
            append-icon="sort"
            :hide-details="true"
            @change="sortProducts"
            clearable
            outlined
            required
            dense
          ></v-select>
        </v-flex>
      </v-layout>

      <v-row v-show="loadingProducts" class="text-center">
        <v-flex xs12>
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </v-flex>
      </v-row>

      <v-row v-show="!loadingProducts" class="pa-2 pl-4">
        <!-- posted products -->
        <v-flex
          xs12
          sm4
          md2
          v-for="(product, i) in allProducts"
          :key="i"
          class="pl-1 mt-2 pr-1"
        >
          <v-card :loading="false">
            <div
              @click="productDetails(product)"
              @mouseenter="changeCursor"
              class="pl-1 text-left"
            >
              <blur-hash-image
                :hash="
                  product.blurhash
                    .split('*product#')[0]
                    .split(',pimg')[0]
                    .trim()
                "
                :src="
                  product.image.split('*product#')[0].split(',pimg')[0].trim()
                "
                width="110px"
              />
              <!-- <v-img contain ></v-img> -->

              <v-card-text
                class="text-truncate"
                style="max-width: 170px; font-size: 16px"
                >{{ product.title_english }}
                <br />
                {{ product.title_amharic }}
                <br />
                {{ product.getSubCategory[0].getCategory[0].title_english }}
                <br /><br />
                Price:&nbsp;&nbsp;
                <strong>{{ product.price }} ETB</strong><br />
                Total Stock:&nbsp;&nbsp; <strong> {{ product.stock }}</strong>
              </v-card-text>
            </div>

            <v-divider class=""></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                class="red--text"
                text
                fab
                small
                @click="(deletePopUp = true), (deletingProduct = product)"
              >
                <v-icon>delete</v-icon>
              </v-btn>

              <v-btn text fab small @click="editProduct(product)">
                <v-icon class="blue--text">edit</v-icon>
              </v-btn>
            </v-card-actions>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>
          </v-card>
        </v-flex>
      </v-row>

      <!-- fetching more products -->
      <!-- <v-layout justify-space-around>
        <v-flex v-if="!thereisMore" xs12 sm6 md3 class="mt-5">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </v-flex>
      </v-layout> -->

      <!-- end of products -->
      <v-layout justify-space-around>
        <v-flex v-if="!thereisMore" xs12 sm6 md3 class="mt-5">
          <v-card>
            <v-card-text> No More Products </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import noPrevilages from "@/components/noPrevilages";
export default {
  components: {
    noPrevilages,
  },
  data() {
    return {
      detailDialog: false,
      allProducts: [],
      loadingProducts: false,
      selectedProduct: "",
      deletePopUp: false,
      deletingProduct: "",
      status: "",
      snackbar: false,
      postedProducts: 0,
      searchQuery: "",
      categories: [],
      selectedCategory: [],
      thereisMore: true,
      selectedSort: "",
      sortingMethods: ["sort by name", "sort by date"],
      noPrevilages: false,
    };
  },
  methods: {
    async getPrivilages() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `{
             getSingleSupplierbyId(supplier_id: ${this.$store.state.supplier_id}) {
                      supplier_id
                      privilege_adder
                    }  
           }     
                `,
          },
        });

        this.categories =
          result.data.data.getSingleSupplierbyId[0].privilege_adder.split(",");
        this.countProducts();
        if (this.categories == []) {
          this.noPrevilages = true;
          return;
        }
        this.getAllProducts(
          "",
          this.$store.state.supplier_id,
          0,
          this.categories.toString()
        );
      } catch (err) {
        alert(err);
      }
    },
    async getAllProducts(searchValue, row_length) {
      if (searchValue == null) {
        searchValue = "";
      }

      this.loadingProducts = true;

      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `query {
                     searchingProducts(searchValue: "${searchValue}",row_length: ${row_length}) {
                                 product_id
                                 sub_category_id
                                 stock
                                 title_amharic
                                 title_english
                                 image
                                 blurhash 
                                 price 
                                 description_english
                                 description_amharic
                                 posted_date
                                 colors
                                 sizes
                                 stocks
                                 size_spec

                                 getSubCategory{
                                     sub_category_id
                                     category_id
                                     title_amharic
                                     title_english
                                     image
                                     blurhash
                                     getCategory{
                                      category_id
                                      title_english
                                     }
                         
                                  }
                          }
                          }

                  `,
          },
        });

        if (row_length == 0) {
          this.allProducts = result.data.data.searchingProducts;
          this.thereisMore = true;
        } else {
          this.allProducts = this.allProducts.concat(
            result.data.data.searchingProducts
          );
          this.thereisMore = true;
        }
        if (result.data.data.searchingProducts.length < 20)
          this.thereisMore = false;

        this.loadingProducts = false;
      } catch (err) {
        alert(err);
      }
    },
    async countProducts() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                    query{
                      countProducts{
                        product_count
                      }
                    }
                `,
          },
        });

        this.postedProducts = result.data.data.countProducts[0].product_count;
      } catch (err) {
        alert(err);
      }
    },
    productDetails(product) {
      this.selectedProduct = product;
      this.$router
        .push({
          name: "productDetails",
          params: {
            detailProduct: this.selectedProduct,
          },
        })
        .catch((err) => {
          throw err;
        });
    },
    async deleteProduct() {
      this.deletePopUp = false;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                 mutation {
                      deleteProduct(product_id: ${this.deletingProduct.product_id}) {
                        statusMessage
                      }
                    }
                `,
          },
        });
        if (result.data.data.deleteProduct[0].statusMessage) {
          alert("Product Deleted Successfully");
          if (this.selectedCategory.length == 0) {
            console.log("no selected category");
            this.getAllProducts(
              this.searchQuery,

              0
            );
          } else {
            console.log("some category selected");
            this.getAllProducts(
              this.searchQuery,

              0
            );
          }
        } else {
          alert("Something went wrong, please try again!");
        }
      } catch (err) {
        alert(err);
      }
    },
    editProduct(product) {
      this.selectedProduct = product;
      this.$router
        .push({
          name: "editProduct",
          params: {
            editProduct: this.selectedProduct,
          },
        })
        .catch((err) => {
          throw err;
        });
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    filterProduct() {
      console.log(this.selectedCategory);
      if (this.selectedCategory == null) {
        this.selectedCategory = [];
      }
      if (this.selectedCategory.length == 0) {
        console.log("no selected category");
        this.getAllProducts(
          this.searchQuery,
          this.$store.state.supplier_id,
          0,
          this.categories.toString()
        );
      } else {
        console.log("some category selected");
        this.getAllProducts(
          this.searchQuery,
          this.$store.state.supplier_id,
          0,
          this.selectedCategory
        );
      }
    },
    sortProducts() {
      console.log(this.selectedSort);

      if (this.selectedSort == "sort by name") {
        this.allProducts.sort((a, b) =>
          a.title_english.toLowerCase() > b.title_english.toLowerCase()
            ? 1
            : b.title_english.toLowerCase() > a.title_english.toLowerCase()
            ? -1
            : 0
        );
      } else {
        this.allProducts.sort((a, b) =>
          a.posted_date < b.posted_date
            ? 1
            : b.posted_date < a.posted_date
            ? -1
            : 0
        );
      }
    },
    fetchMore() {
      if (
        document.documentElement.scrollTop + window.innerHeight ==
        document.documentElement.scrollHeight
      ) {
        if (this.postedProducts > this.allProducts.length)
          if (this.selectedCategory.length == 0) {
            console.log("no selected category");
            this.getAllProducts(this.searchQuery, this.allProducts.length);
          }
        // else {
        //   console.log("some category selected");
        //   this.getAllProducts(
        //     this.searchQuery,
        //     this.$store.state.supplier_id,
        //     this.allProducts.length,
        //     this.selectedCategory
        //   );
        // }
      }
    },
  },
  watch: {
    searchQuery: _.debounce(function (value) {
      if (this.selectedCategory.length == 0) {
        if (this.$store.state.admin != "") {
          this.getAllProducts(
            value,

            0
          );
        } else this.$router.push({ name: "login" });
      }
      //  else {
      //   console.log("some category selected");
      //   this.getAllProducts(
      //     value,
      //     this.$store.state.supplier_id,
      //     0,
      //     this.selectedCategory
      //   );
      // }
    }, 500),
  },
  async created() {
    if (this.$store.state.admin != "") {
      this.countProducts();
      await this.getAllProducts(
        "",

        0
      );
      window.addEventListener("scroll", this.fetchMore);
    } else this.$router.push({ name: "login" });
  },

  destroyed() {
    if (this.$store.state.admin != "") {
      window.removeEventListener("scroll", this.fetchMore);
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#report {
  max-width: 1110px;
  margin: auto;
}
</style>
