<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="$store.state.admin.privilage == 'Super Admin'"
  >
    <p class="headline white--text ml-5">Users</p>
    <div v-if="loadingUsers" class="text-center">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-layout
      row
      wrap
      v-else
      class="container white pa-16 pl-0 pr-1 pt-1"
      id="report"
    >
      <v-flex xs8 class="pr-4 mt-6">
        <h3 class="text-center mt-4">Staff</h3>
        <horizontal-scroll
          class="horizontal-scroll white pa-3 pt-0 mt-3"
          style="border-radius: 5px"
        >
          <table style="width: 100%" class="ma-3 mt-0 text-center">
            <tr>
              <th>No.</th>
              <th>Full Name</th>
              <th>Phone Number</th>
              <th>User Name</th>
              <th>Privilege</th>
              <th>
                Action
                <v-btn
                  small
                  text
                  @click="
                    cashierRegOrUpdateDialog = true;
                    isRegistration = 'Admin Registration';
                  "
                  class="mt-1 ml-5"
                >
                  <v-icon class="blue--text">add</v-icon>
                </v-btn>
              </th>
            </tr>
            <tr v-for="(item, i) in admins" :key="i">
              <td style="font-size: 11px">
                <span class="ml-4">{{ i + 1 }}</span>
              </td>
              <td>
                {{ item.full_name }}
              </td>
              <td>
                {{ item.phone_number }}
              </td>
              <td>
                {{ item.user_name }}
              </td>
              <td>
                {{ item.privilage }}
              </td>

              <td>
                <v-btn small text @click="editClicked(item)" class="mt-1">
                  <v-icon class="blue--text">edit</v-icon>
                </v-btn>

                <v-btn small text @click="deleteCashier(item)" class="mt-1">
                  <v-icon class="red--text">delete</v-icon>
                </v-btn>
              </td>
              <hr />
            </tr>
          </table>
        </horizontal-scroll>
      </v-flex>
      <v-flex xs4 class="pr-2">
        <!-- <v-layout row wrap class="mb-15 pa-3 pt-0">
          <v-flex xs12>
           
          </v-flex>
      
        
          
        </v-layout> -->
        <v-flex xs12 class="text-center mt-3">
          <h2>
            {{ users.length }}
          </h2>

          <h4>Total Clients</h4>
        </v-flex>

        <horizontal-scroll
          class="horizontal-scroll white pa-1 pt-0 mt-0"
          style="border-radius: 5px"
        >
          <table style="width: 100%" class="ma-1 mt-0 text-center">
            <tr>
              <th>No.</th>
              <th>Full Name</th>
              <th>Phone Number</th>
            </tr>
            <tr v-for="(item, i) in users" :key="i">
              <td style="font-size: 11px">
                <span class="ml-4">{{ i + 1 }}</span>
              </td>
              <td>
                {{ item.full_name }}
              </td>
              <td>
                {{ item.phone_number }}
              </td>
              <hr />
            </tr>
          </table>
        </horizontal-scroll>
      </v-flex>
    </v-layout>

    <v-dialog v-model="cashierRegOrUpdateDialog" persistent max-width="420">
      <v-card class="text-center pl-5 pr-5 pb-10">
        <v-card-title style="font-size: 13px">
          {{ isRegistration }} <v-spacer></v-spacer>
          <v-btn text small @click="cashierRegOrUpdateDialog = false">
            <span class="text-capitalize red--text">close</span>
          </v-btn></v-card-title
        >

        <v-form @submit.prevent="" ref="form">
          <v-card-text>
            <P>Fill the following required fields</P>
            <v-text-field
              style="font-size: 11px"
              v-model="fullName"
              :rules="[(v) => !!v || 'Required']"
              label="Full Name"
              required
              dense
              outlined
              prepend-icon="person"
            ></v-text-field>

            <v-text-field
              style="font-size: 11px"
              v-model="phoneNumber"
              :rules="[(v) => !!v || 'Required']"
              label="Phone Number"
              required
              dense
              outlined
              prepend-icon="phone"
            ></v-text-field>

            <v-menu rounded="lg" max-height="300px">
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  text
                  class="pl-10 pr-10 text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                  outlined=""
                >
                  {{
                    choosedPrivilage != ""
                      ? choosedPrivilage
                      : "Choose Privilege"
                  }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item
                    v-for="item in privilages"
                    :key="item"
                    @click="choosedPrivilage = item"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-text-field
              style="font-size: 11px"
              v-model="userName"
              :rules="[(v) => !!v || 'Required']"
              label="User Name"
              required
              dense
              outlined
              prepend-icon="group"
              class="mt-6"
            ></v-text-field>
            <v-text-field
              style="font-size: 11px"
              v-model="password"
              :rules="[(v) => !!v || 'Required']"
              label="Password"
              required
              dense
              outlined
              type="password"
              prepend-icon="lock"
            ></v-text-field>

            <v-btn
              v-if="isRegistration == 'Admin Registration'"
              small
              text
              class="primary"
              @click="registerCashier"
              :loading="regOrUpdateLoading"
              type="submit"
            >
              <span class="text-capitalize">Register</span>
            </v-btn>

            <v-btn
              v-else
              small
              text
              class="primary"
              :loading="regOrUpdateLoading"
              type="submit"
              @click="updateCashier"
            >
              <span class="text-capitalize">Update</span>
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    loadingUsers: false,
    users: [],
    admins: [],
    cashierRegOrUpdateDialog: false,
    isRegistration: "",
    regOrUpdateLoading: false,
    privilages: ["Sales Manager", "Sales"],
    choosedPrivilage: "",
    userName: "",
    password: "",
    fullName: "",
    phoneNumber: "",
    cashierId: "",
  }),

  methods: {
    editClicked(cashier) {
      this.isRegistration = "Update Admin";
      this.cashierRegOrUpdateDialog = true;
      this.fullName = cashier.full_name;
      this.phoneNumber = cashier.phone_number;
      this.choosedPrivilage = cashier.privilage;
      this.userName = cashier.user_name;
      this.password = cashier.password;
      this.cashierId = cashier.cashier_id;
    },
    async registerCashier() {
      if (this.$refs.form.validate() && this.choosedPrivilage != "") {
        this.regOrUpdateLoading = true;
        try {
          var addCashierResult = await axios({
            method: "POST",
            url: this.$store.state.url,

            data: {
              query: `mutation{
                addAdmin(
                 full_name:"${this.fullName}",
                 phone_number:"${this.phoneNumber}",privilage
                :"${this.choosedPrivilage}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addCashierResult.data.data.addAdmin[0].statusMessage.toString() ==
            "true"
          ) {
            this.cashierRegOrUpdateDialog = false;
            alert("Registered successfully!");
            this.getAdmins();
          } else alert("Admin already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },
    async updateCashier() {
      if (this.$refs.form.validate() && this.choosedPrivilage != "") {
        this.regOrUpdateLoading = true;
        try {
          var addCashierResult = await axios({
            method: "POST",
            url: this.$store.state.url,

            data: {
              query: `mutation{
                editAdmin(
                  admin_id:${this.cashierId},
                 full_name:"${this.fullName}",
                 phone_number:"${this.phoneNumber}",privilage
                :"${this.choosedPrivilage}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
                 
              }
              
              }
              `,
            },
          });

          if (
            addCashierResult.data.data.editAdmin[0].statusMessage.toString() ==
            "true"
          ) {
            this.cashierRegOrUpdateDialog = false;
            alert("Updated successfully!");
            this.getAdmins();
          } else alert("Admin already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },

    async deleteCashier(cashier) {
      if (cashier.privilage != "Super Admin") {
        const deleteConfirm = confirm(
          "Are you sure you want to delete this admin?"
        );
        if (deleteConfirm) {
          try {
            var deleteCashierResult = await axios({
              method: "POST",
              url: this.$store.state.url,

              data: {
                query: `mutation{
                  deleteAdmin(
                  admin_id:${cashier.admin_id}){
                 statusMessage
              }
              
              }
              `,
              },
            });
            if (
              deleteCashierResult.data.data.deleteAdmin[0].statusMessage.toString() ==
              "true"
            ) {
              alert("Deleted successfully!");
              this.getAdmins();
            } else alert("not deleted, Something went wrong");
          } catch (err) {
            alert(err);
          }
        }
      } else alert("You can not delete super admin!");
    },
    async getUsers() {
      this.loadingUsers = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                   {
                      getUsers {
                        user_id
                        full_name
                        
                        phone_number
                      }
                    }

                `,
          },
        });

        this.users = result.data.data.getUsers;
        this.loadingUsers = false;
      } catch (err) {
        alert(err);
      }
    },

    async getAdmins() {
      this.loadingUsers = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                   {
                    getAdmin {
                      admin_id
                      user_name
                      password
                      phone_number
                      full_name
                      privilage
                      }
                    }

                `,
          },
        });

        this.admins = result.data.data.getAdmin;
        this.loadingUsers = false;
      } catch (err) {
        alert(err);
      }
    },
  },
  created() {
    if (this.$store.state.admin != "") {
      this.getAdmins();
      this.getUsers();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#report {
  max-width: 1110px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px;
  padding-right: 5px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}

.outer {
  display: flex;
  flex: 1;

  height: 100%;

  flex-flow: row nowrap;
  align-items: center;
  width: fit-content;
  max-width: 500px;
  display: flex;
  overflow-x: auto;
}
.inner-content {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
}
.inner-content:not(:first-of-type) {
  margin-left: 30px;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
