import { render, staticRenderFns } from "./addProduct.vue?vue&type=template&id=03cee362&scoped=true"
import script from "./addProduct.vue?vue&type=script&lang=js"
export * from "./addProduct.vue?vue&type=script&lang=js"
import style0 from "./addProduct.vue?vue&type=style&index=0&id=03cee362&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03cee362",
  null
  
)

export default component.exports