<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager' ||
      $store.state.admin.privilage == 'Sales'
    "
  >
    <p class="headline white--text ml-5">Orders</p>
    <div
      class="container white pa-16 pl-15 pr-2 pt-0"
      id="report"
      style="border-radius: 3px; font-size: 17px"
    >
      <div class="pl-15">
        <v-btn
          @click="isNeedDelivery = 'needs'"
          text
          class="text-capitalize red--text grey lighten-2 mr-3 mt-2"
          v-if="isNeedDelivery == 'needs'"
        >
          <span>Needs delivery</span>
        </v-btn>
        <v-btn
          @click="isNeedDelivery = 'needs'"
          text
          class="text-capitalize red--text mr-3 mt-2"
          v-else
        >
          <span>Needs delivery</span>
        </v-btn>
        <v-btn
          text
          class="text-capitalize blue--text grey lighten-2 mr-3 mt-2"
          @click="isNeedDelivery = 'noneeds'"
          v-if="isNeedDelivery == 'noneeds'"
        >
          <span> No Needs delivery</span>
        </v-btn>

        <v-btn
          text
          class="text-capitalize blue--text mr-3 mt-2"
          @click="isNeedDelivery = 'noneeds'"
          v-else
        >
          <span> No Needs delivery</span>
        </v-btn>
        <v-menu rounded="lg" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              text
              class="pl-6 pr-6 mt-2"
              v-bind="attrs"
              v-on="on"
              outlined=""
            >
              {{ choosedDateRange }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <v-list-item
                v-for="item in itemsd"
                :key="item"
                @click="
                  if (item == 'Custom Range') {
                    dateDialog = true;
                  } else {
                    getOrders(item);
                    loadingCount == 0;
                  }
                "
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <v-toolbar flat id="dashboard" class="pt-0" v-if="!newOrderLoading">
        <template v-slot:extension>
          <v-tabs v-model="tab" class="" style="margin-top: -100px">
            <v-tab
              v-for="item in items"
              :key="item"
              style="font-size: 17px"
              class="pa-0 mr-6"
              :id="`${item}`"
              @click="clickTab($event)"
            >
              <span class="black--text text-capitalize"> {{ item }}</span>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <div v-if="newOrderLoading" class="text-center">
        <v-progress-circular
          :size="70"
          :width="2"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="isNeedDelivery == 'needs'">
        <v-layout row wrap>
          <v-flex
            xs12
            class="pl-3 pr-2"
            v-if="
              chooser == 'Pending Orders' &&
              !newOrderLoading &&
              paidOrders.length > 0
            "
          >
            <strong>
              <span class="black--text pl-5">
                <!-- Pending Orders: {{ paidOrders.length }} -->
              </span>

              <br
            /></strong>
            <div v-for="onum in paidOrdersON" :key="onum">
              <v-layout
                wrap
                row
                v-for="(order, i) in paidOrders"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex
                  class=""
                  xs6
                  md3
                  v-if="order.needs_delivery == 1 && onum == order.order_number"
                >
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >Total Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br /><br />
                    <v-btn
                      @click="completeOrder(order)"
                      text
                      small
                      class="px-4 grey lighten-2 text-capitalize"
                    >
                      Complete order
                    </v-btn>
                  </span>
                </v-flex>

                <v-flex
                  xs6
                  md7
                  class="pa-2 pr-0"
                  v-if="order.needs_delivery == 1 && onum == order.order_number"
                >
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex
                  xs6
                  md2
                  class="pa-1"
                  v-if="
                    order.needs_delivery == 1 &&
                    onum == order.order_number &&
                    order.total_price > 0
                  "
                >
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    order.total_price > 0 &&
                    order.needs_delivery == 1 &&
                    onum == order.order_number
                  "
                >
                  <br />
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                  <br />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <!-- <v-flex
            xs12
            v-else-if="
              paidOrders.length == 0 &&
              chooser == 'Pending Orders' &&
              !newOrderLoading
            "
            class="text-center mt-10 pt-10"
            style="font-size: 13px"
          >
            <span>There is no pending order</span>
          </v-flex> -->

          <v-flex
            xs12
            class="pl-3 pr-2"
            v-if="
              chooser == 'Unpaid Orders' &&
              !newOrderLoading &&
              notPaidOrders.length > 0
            "
          >
            <strong>
              <span class="black--text pl-5">
                <!-- Unpaid Orders: {{ notPaidOrders.length }} -->
              </span>
              <br />
            </strong>
            <div v-for="onum in notPaidOrdersON" :key="onum">
              <v-layout
                wrap
                row
                v-for="(order, i) in notPaidOrders"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex
                  class=""
                  xs6
                  md3
                  v-if="order.needs_delivery == 1 && onum == order.order_number"
                >
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >Total Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br /><br />
                    <v-btn
                      @click="completeOrder(order)"
                      text
                      small
                      class="px-4 grey lighten-2 text-capitalize"
                    >
                      Complete order
                    </v-btn>
                  </span>
                </v-flex>

                <v-flex
                  xs6
                  md7
                  class="pa-2 pr-0"
                  v-if="order.needs_delivery == 1 && onum == order.order_number"
                >
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex
                  xs6
                  md2
                  class="pa-1"
                  v-if="
                    order.needs_delivery == 1 &&
                    onum == order.order_number &&
                    order.total_price > 0
                  "
                >
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    order.total_price > 0 &&
                    order.needs_delivery == 1 &&
                    onum == order.order_number
                  "
                >
                  <br />
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                  <br />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <!-- <v-flex
            xs12
            v-else-if="
              notPaidOrders.length == 0 &&
              chooser == 'Unpaid Orders' &&
              !newOrderLoading
            "
            class="text-center mt-10 pt-10"
            style="font-size: 13px"
          >
            <span>There is no unpaid order</span>
          </v-flex> -->

          <v-flex
            class="pl-3 pr-2"
            xs12
            v-if="
              chooser == 'Completed' &&
              !newOrderLoading &&
              completedOrders.length > 0
            "
          >
            <strong>
              <span class="black--text pl-5">
                <!-- Completed Orders: {{ completedOrders.length }} -->
              </span></strong
            >

            <br />

            <div v-for="onum in completedOrdersON" :key="onum">
              <v-layout
                wrap
                row
                v-for="(order, i) in completedOrders"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex
                  class=""
                  xs6
                  md3
                  v-if="order.needs_delivery == 1 && onum == order.order_number"
                >
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >Total Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br />
                  </span>
                </v-flex>

                <v-flex
                  xs6
                  md7
                  class="pa-2 pr-0"
                  v-if="order.needs_delivery == 1 && onum == order.order_number"
                >
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex
                  xs6
                  md2
                  class="pa-1"
                  v-if="
                    order.needs_delivery == 1 &&
                    onum == order.order_number &&
                    order.total_price > 0
                  "
                >
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    order.total_price > 0 &&
                    order.needs_delivery == 1 &&
                    onum == order.order_number
                  "
                >
                  <br />
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                  <br />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <!-- <v-flex
            xs12
            v-else-if="
              completedOrders.length == 0 &&
              chooser == 'Completed' &&
              !newOrderLoading
            "
            class="text-center mt-10 pt-10"
            style="font-size: 13px"
          >
            <span>There is no completed order</span>
          </v-flex> -->

          <v-dialog
            v-if="paymentReview.length > 0"
            v-model="reviewPaymentOnOff"
            transition="dialog-bottom-transition"
            max-width="600"
            :retain-focus="false"
          >
            <v-card flat>
              <v-toolbar flat id="paymentReview" class="text-center" dark
                >Payment review</v-toolbar
              >
              <v-card-text>
                <div class="text-center black--text pa-2 mt-2">
                  <v-layout wrap row justify-space-around>
                    <v-flex xs12>
                      <span class="headline black--text">
                        {{ totalMoneyPaid.toFixed(2) }} Birr</span
                      ><br />
                      <span class="grey--text">Total money</span>
                    </v-flex>
                    <v-flex xs12 class="">
                      <v-zoomer
                        style="width: 96%; height: 200px"
                        v-if="
                          paymentReview[0].getOrderPayment[0].reference_image !=
                          ''
                        "
                        class="text-center"
                      >
                        <img
                          contain
                          :src="
                            paymentReview[0].getOrderPayment[0].reference_image
                          "
                          alt="Image"
                          height="100%"
                          width="100%"
                        />
                      </v-zoomer>
                      <p
                        v-else
                        class="text-center red--text"
                        style="font-size: 18px"
                      >
                        There is no referrence image
                      </p>
                    </v-flex>
                    <v-flex xs12>
                      <br />
                      <span>
                        Referrence Number:
                        {{
                          paymentReview[0].getOrderPayment[0].referrence_number
                        }}
                      </span>
                      <br />
                      <span>
                        Selected payment:
                        {{ paymentReview[0].payment_type.split("*")[0] }}
                      </span>
                    </v-flex>
                  </v-layout>
                </div>
                <br />
                <v-layout
                  class="ma-2"
                  wrap
                  row
                  justify-space-around
                  v-for="order in paymentReview"
                  :key="order.order_id"
                >
                  <v-flex xs2>
                    <img
                      contain
                      height="50px"
                      width="50px"
                      :src="order.getProduct[0].image_one"
                      alt="Image"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <span>{{
                      order.getProduct[0].product_title_in_english
                    }}</span>
                    <br /><span>Quantity: {{ order.quantity }}</span>
                  </v-flex>
                  <v-flex xs6>
                    <span
                      >Supplier:
                      {{ order.getProduct[0].getSupplier[0].storename }}</span
                    >
                    <br /><span
                      >Phone:
                      {{
                        order.getProduct[0].getSupplier[0].store_telephone
                      }}</span
                    >
                  </v-flex>

                  <v-flex xs12>
                    <v-divider class="grey--text"></v-divider>
                  </v-flex>
                </v-layout>

                <v-layout class="pa-2 ma-2" wrap row justify-space-around>
                  <v-flex xs2 class="ml-2">
                    <v-btn
                      :loading="acceptLoading"
                      text
                      dark
                      class="green"
                      @click="acceptPayment(paymentReview[0].reciept_number)"
                    >
                      <span class="text-capitalize">Accept</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs2 class="mr-2">
                    <v-btn
                      :loading="cancelLoading"
                      text
                      dark
                      class="red"
                      @click="declinePayment(paymentReview[0].reciept_number)"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="reviewPaymentOnOff = false"
                  ><span class="text-capitalize">Close</span></v-btn
                >
              </v-card-actions>
            </v-card>
            <!-- </template> -->
          </v-dialog>

          <v-dialog
            v-if="orderReview.length > 0"
            v-model="reviewOrderOnOff"
            transition="dialog-bottom-transition"
            max-width="900"
            :retain-focus="false"
          >
            <v-card flat>
              <v-toolbar flat id="paymentReview" class="text-center" dark
                >Order review</v-toolbar
              >
              <v-card-text>
                <div class="text-center black--text pa-2 mt-2">
                  <v-layout wrap row justify-space-around>
                    <v-flex xs12>
                      <span class="black--text">
                        Total Item Price:
                        {{ totalItemMoneyPaid.toFixed(2) }} </span
                      ><br />
                      <span class="black--text">
                        Total delivery fee:
                        {{ totalDeliveryFeeePaid.toFixed(2) }} </span
                      ><br />

                      <span class="black--text">
                        Total service fee:
                        {{ totalServiceFeeePaid.toFixed(2) }} </span
                      ><br />

                      <p class="text-h5">
                        Total:
                        {{
                          (
                            totalServiceFeeePaid +
                            totalDeliveryFeeePaid +
                            totalItemMoneyPaid
                          ).toFixed(2)
                        }}
                      </p>

                      <span
                        class="red--text"
                        v-if="
                          orderReview[0].payment_type.split('*')[0].length == 4
                        "
                        >Selected Payment: ****{{
                          orderReview[0].payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ orderReview[0].payment_type.split("*")[0] }}</span
                      >
                      <br />
                    </v-flex>
                  </v-layout>
                </div>
                <br />
                <v-layout
                  justify-space-around
                  class="ma-2"
                  wrap
                  row
                  v-for="order in orderReview"
                  :key="order.order_id"
                >
                  <v-flex xs4 md2>
                    <img
                      contain
                      height="50px"
                      width="50px"
                      :src="order.getProduct[0].image_one"
                      alt="Image"
                    />
                  </v-flex>
                  <v-flex xs4 md2>
                    <span>{{
                      order.getProduct[0].product_title_in_english
                    }}</span>
                    <br /><span>Quantity: {{ order.quantity }}</span>
                  </v-flex>
                  <v-flex xs4 md2>
                    <span>
                      {{ order.getProduct[0].getSupplier[0].storename }}</span
                    >
                    <br /><span>
                      {{
                        order.getProduct[0].getSupplier[0].store_telephone
                      }}</span
                    >
                  </v-flex>
                  <!-- 
              <v-checkbox
                v-if="order.order_id != clickedOrderId"
                v-model="checkbox"
                :label="`Assign order`"
                :id="`${order.order_id}`"
               
              ></v-checkbox> -->

                  <v-btn
                    :loading="deliveryLoading"
                    :small="true"
                    dark
                    text
                    class="green mt-2 mb-2"
                    @click="assignRight(order.order_id)"
                    v-if="order.order_id != clickedOrderId"
                  >
                    <span class="text-capitalize">Assign order</span>
                  </v-btn>

                  <v-flex
                    xs5
                    md2
                    v-if="
                      order.order_id == clickedOrderId &&
                      deliveryName.length > 0
                    "
                  >
                    <v-select
                      v-model="selectedDelivery"
                      :items="deliveryName"
                      label="Delivery type"
                      dense
                      outlined
                      @change="selectDrivers"
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs5
                    md2
                    v-if="
                      order.order_id == clickedOrderId &&
                      deliveryName.length > 0
                    "
                  >
                    <v-select
                      :loading="assignLoading"
                      v-model="selectedDriver"
                      :items="driverList"
                      label="Driver"
                      dense
                      outlined
                      @change="assignDriver(order)"
                      :clearable="true"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-divider class="grey--text"></v-divider>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="reviewOrderOnOff = false"
                  ><span class="text-capitalize">Close</span></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </div>
      <div v-else>
        <v-layout row wrap>
          <v-flex
            xs12
            class="pl-3 pr-2"
            v-if="
              chooser == 'Pending Orders' &&
              !newOrderLoading &&
              paidOrders.length > 0
            "
          >
            <strong>
              <span class="black--text pl-5">
                <!-- Pending Orders: {{ paidOrders.length }} -->
              </span>

              <br
            /></strong>
            <div v-for="onum in paidOrdersON" :key="onum">
              <v-layout
                wrap
                row
                v-for="(order, i) in paidOrders"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex
                  class=""
                  xs6
                  md3
                  v-if="order.needs_delivery == 0 && onum == order.order_number"
                >
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >Total Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br /><br />
                    <v-btn
                      @click="completeOrder(order)"
                      text
                      small
                      class="px-4 grey lighten-2 text-capitalize"
                    >
                      Complete order
                    </v-btn>
                  </span>
                </v-flex>

                <v-flex
                  xs6
                  md7
                  class="pa-2 pr-0"
                  v-if="order.needs_delivery == 0 && onum == order.order_number"
                >
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex
                  xs6
                  md2
                  class="pa-1"
                  v-if="
                    order.needs_delivery == 0 &&
                    onum == order.order_number &&
                    order.total_price > 0
                  "
                >
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    order.total_price > 0 &&
                    order.needs_delivery == 0 &&
                    onum == order.order_number
                  "
                >
                  <br />
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                  <br />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <!-- <v-flex
            xs12
            v-else-if="
              paidOrders.length == 0 &&
              chooser == 'Pending Orders' &&
              !newOrderLoading
            "
            class="text-center mt-10 pt-10"
            style="font-size: 13px"
          >
            <span>There is no pending order</span>
          </v-flex> -->

          <v-flex
            xs12
            class="pl-3 pr-2"
            v-if="
              chooser == 'Unpaid Orders' &&
              !newOrderLoading &&
              notPaidOrders.length > 0
            "
          >
            <strong>
              <span class="black--text pl-5">
                <!-- Unpaid Orders: {{ notPaidOrders.length }} -->
              </span>
              <br />
            </strong>
            <div v-for="onum in notPaidOrdersON" :key="onum">
              <v-layout
                wrap
                row
                v-for="(order, i) in notPaidOrders"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex
                  class=""
                  xs6
                  md3
                  v-if="order.needs_delivery == 0 && onum == order.order_number"
                >
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >Total Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br /><br />
                    <v-btn
                      @click="completeOrder(order)"
                      text
                      small
                      class="px-4 grey lighten-2 text-capitalize"
                    >
                      Complete order
                    </v-btn>
                  </span>
                </v-flex>

                <v-flex
                  xs6
                  md7
                  class="pa-2 pr-0"
                  v-if="order.needs_delivery == 0 && onum == order.order_number"
                >
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex
                  xs6
                  md2
                  class="pa-1"
                  v-if="
                    order.needs_delivery == 0 &&
                    onum == order.order_number &&
                    order.total_price > 0
                  "
                >
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    order.total_price > 0 &&
                    order.needs_delivery == 0 &&
                    onum == order.order_number
                  "
                >
                  <br />
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                  <br />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <!-- <v-flex
            xs12
            v-else-if="
              notPaidOrders.length == 0 &&
              chooser == 'Unpaid Orders' &&
              !newOrderLoading
            "
            class="text-center mt-10 pt-10"
            style="font-size: 13px"
          >
            <span>There is no unpaid order</span>
          </v-flex> -->

          <v-flex
            class="pl-3 pr-2"
            xs12
            v-if="
              chooser == 'Completed' &&
              !newOrderLoading &&
              completedOrders.length > 0
            "
          >
            <strong>
              <span class="black--text pl-5">
                <!-- Completed Orders: {{ completedOrders.length }} -->
              </span></strong
            >

            <br />

            <div v-for="onum in completedOrdersON" :key="onum">
              <v-layout
                wrap
                row
                v-for="(order, i) in completedOrders"
                :key="order.order_id"
                style="font-size: 13px"
              >
                <v-flex
                  class=""
                  xs6
                  md3
                  v-if="order.needs_delivery == 0 && onum == order.order_number"
                >
                  <span class="headline" v-if="i == 0">Order Info</span><br />
                  <span> </span>
                  <span
                    >Name:
                    <strong>
                      {{ order.getProducts[0].title_english }}</strong
                    > </span
                  ><br />
                  <span
                    v-if="
                      order.order_description != '' &&
                      order.order_description != undefined
                    "
                  >
                    <span
                      v-for="orderc in order.order_description.split(':')"
                      :key="orderc + 100"
                    >
                      <span
                        >Color:&nbsp;&nbsp;
                        <v-btn
                          x-small
                          elevation="0"
                          :color="orderc.split('-')[0]"
                        ></v-btn>
                      </span>
                      <br />
                      <span
                        v-for="(size, i) in orderc.split('-')[1].split('/')"
                        :key="i"
                      >
                        <span>Size: {{ size.split("*")[0] }}</span
                        >&nbsp;&nbsp;&nbsp;
                        <span>Quantity: {{ size.split("*")[1] }}</span
                        ><br />
                      </span>
                      <br />
                    </span>
                  </span>
                  <span v-if="order.total_price > 0">
                    <span>Item Price: {{ order.total_price.toFixed(2) }}</span
                    ><br />
                    <span
                      >Delivery Fee: {{ order.delivery_fee.toFixed(2) }}</span
                    ><br />

                    <span class="red--text"
                      >Total Price:
                      {{
                        (order.total_price + order.delivery_fee).toFixed(2)
                      }}</span
                    ><br />
                    <span
                      v-if="
                        order.payment_type != '' &&
                        order.payment_type != undefined
                      "
                    >
                      <span
                        class="red--text"
                        v-if="order.payment_type.split('*')[0].length == 4"
                        >Selected Payment: ****{{
                          order.payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ order.payment_type.split("*")[0] }}</span
                      >
                    </span>

                    <br />

                    <span>Order No: {{ order.order_number }}</span
                    ><br />

                    <span>
                      Date:{{
                        new Date(Number(order.order_date)) | formatDate
                      }} </span
                    ><br />
                  </span>
                </v-flex>

                <v-flex
                  xs6
                  md7
                  class="pa-2 pr-0"
                  v-if="order.needs_delivery == 0 && onum == order.order_number"
                >
                  <horizontal-scroll class="horizontal-scroll">
                    <div class="outer">
                      <div
                        class="inner-content"
                        v-for="orderc in order.order_description.split(':')"
                        :key="orderc"
                      >
                        <v-img
                          contain
                          :src="
                            order.getProducts[0].image
                              .split('*product#')
                              [
                                order.getProducts[0].colors
                                  .split(',')
                                  .indexOf(orderc.split('-')[0])
                              ].split(',pimg')[0]
                              .trim()
                          "
                          height="100"
                          width="100"
                        >
                        </v-img>
                      </div>
                    </div>
                  </horizontal-scroll>
                </v-flex>
                <v-flex
                  xs6
                  md2
                  class="pa-1"
                  v-if="
                    order.needs_delivery == 0 &&
                    onum == order.order_number &&
                    order.total_price > 0
                  "
                >
                  <span class="headline">User info</span><br />
                  <span>Name: {{ order.getUser[0].full_name }}</span
                  ><br />
                  <span
                    >Address: {{ order.shipping_address.split("*")[0] }}</span
                  ><br />
                  <span>Phone No: {{ order.getUser[0].phone_number }}</span
                  ><br />
                </v-flex>
                <v-flex
                  xs12
                  v-if="
                    order.total_price > 0 &&
                    order.needs_delivery == 0 &&
                    onum == order.order_number
                  "
                >
                  <br />
                  <!-- <hr class="grey--text" /> -->
                  <v-divider class="grey--text"></v-divider>
                  <br />
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <!-- <v-flex
            xs12
            v-else-if="
              completedOrders.length == 0 &&
              chooser == 'Completed' &&
              !newOrderLoading
            "
            class="text-center mt-10 pt-10"
            style="font-size: 13px"
          >
            <span>There is no completed order</span>
          </v-flex> -->

          <v-dialog
            v-if="paymentReview.length > 0"
            v-model="reviewPaymentOnOff"
            transition="dialog-bottom-transition"
            max-width="600"
            :retain-focus="false"
          >
            <v-card flat>
              <v-toolbar flat id="paymentReview" class="text-center" dark
                >Payment review</v-toolbar
              >
              <v-card-text>
                <div class="text-center black--text pa-2 mt-2">
                  <v-layout wrap row justify-space-around>
                    <v-flex xs12>
                      <span class="headline black--text">
                        {{ totalMoneyPaid.toFixed(2) }} Birr</span
                      ><br />
                      <span class="grey--text">Total money</span>
                    </v-flex>
                    <v-flex xs12 class="">
                      <v-zoomer
                        style="width: 96%; height: 200px"
                        v-if="
                          paymentReview[0].getOrderPayment[0].reference_image !=
                          ''
                        "
                        class="text-center"
                      >
                        <img
                          contain
                          :src="
                            paymentReview[0].getOrderPayment[0].reference_image
                          "
                          alt="Image"
                          height="100%"
                          width="100%"
                        />
                      </v-zoomer>
                      <p
                        v-else
                        class="text-center red--text"
                        style="font-size: 18px"
                      >
                        There is no referrence image
                      </p>
                    </v-flex>
                    <v-flex xs12>
                      <br />
                      <span>
                        Referrence Number:
                        {{
                          paymentReview[0].getOrderPayment[0].referrence_number
                        }}
                      </span>
                      <br />
                      <span>
                        Selected payment:
                        {{ paymentReview[0].payment_type.split("*")[0] }}
                      </span>
                    </v-flex>
                  </v-layout>
                </div>
                <br />
                <v-layout
                  class="ma-2"
                  wrap
                  row
                  justify-space-around
                  v-for="order in paymentReview"
                  :key="order.order_id"
                >
                  <v-flex xs2>
                    <img
                      contain
                      height="50px"
                      width="50px"
                      :src="order.getProduct[0].image_one"
                      alt="Image"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <span>{{
                      order.getProduct[0].product_title_in_english
                    }}</span>
                    <br /><span>Quantity: {{ order.quantity }}</span>
                  </v-flex>
                  <v-flex xs6>
                    <span
                      >Supplier:
                      {{ order.getProduct[0].getSupplier[0].storename }}</span
                    >
                    <br /><span
                      >Phone:
                      {{
                        order.getProduct[0].getSupplier[0].store_telephone
                      }}</span
                    >
                  </v-flex>

                  <v-flex xs12>
                    <v-divider class="grey--text"></v-divider>
                  </v-flex>
                </v-layout>

                <v-layout class="pa-2 ma-2" wrap row justify-space-around>
                  <v-flex xs2 class="ml-2">
                    <v-btn
                      :loading="acceptLoading"
                      text
                      dark
                      class="green"
                      @click="acceptPayment(paymentReview[0].reciept_number)"
                    >
                      <span class="text-capitalize">Accept</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs2 class="mr-2">
                    <v-btn
                      :loading="cancelLoading"
                      text
                      dark
                      class="red"
                      @click="declinePayment(paymentReview[0].reciept_number)"
                    >
                      <span class="text-capitalize">Cancel</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="reviewPaymentOnOff = false"
                  ><span class="text-capitalize">Close</span></v-btn
                >
              </v-card-actions>
            </v-card>
            <!-- </template> -->
          </v-dialog>

          <v-dialog
            v-if="orderReview.length > 0"
            v-model="reviewOrderOnOff"
            transition="dialog-bottom-transition"
            max-width="900"
            :retain-focus="false"
          >
            <v-card flat>
              <v-toolbar flat id="paymentReview" class="text-center" dark
                >Order review</v-toolbar
              >
              <v-card-text>
                <div class="text-center black--text pa-2 mt-2">
                  <v-layout wrap row justify-space-around>
                    <v-flex xs12>
                      <span class="black--text">
                        Total Item Price:
                        {{ totalItemMoneyPaid.toFixed(2) }} </span
                      ><br />
                      <span class="black--text">
                        Total delivery fee:
                        {{ totalDeliveryFeeePaid.toFixed(2) }} </span
                      ><br />

                      <span class="black--text">
                        Total service fee:
                        {{ totalServiceFeeePaid.toFixed(2) }} </span
                      ><br />

                      <p class="text-h5">
                        Total:
                        {{
                          (
                            totalServiceFeeePaid +
                            totalDeliveryFeeePaid +
                            totalItemMoneyPaid
                          ).toFixed(2)
                        }}
                      </p>

                      <span
                        class="red--text"
                        v-if="
                          orderReview[0].payment_type.split('*')[0].length == 4
                        "
                        >Selected Payment: ****{{
                          orderReview[0].payment_type.split("*")[0]
                        }}</span
                      >
                      <span class="red--text" v-else
                        >Selected Payment:
                        {{ orderReview[0].payment_type.split("*")[0] }}</span
                      >
                      <br />
                    </v-flex>
                  </v-layout>
                </div>
                <br />
                <v-layout
                  justify-space-around
                  class="ma-2"
                  wrap
                  row
                  v-for="order in orderReview"
                  :key="order.order_id"
                >
                  <v-flex xs4 md2>
                    <img
                      contain
                      height="50px"
                      width="50px"
                      :src="order.getProduct[0].image_one"
                      alt="Image"
                    />
                  </v-flex>
                  <v-flex xs4 md2>
                    <span>{{
                      order.getProduct[0].product_title_in_english
                    }}</span>
                    <br /><span>Quantity: {{ order.quantity }}</span>
                  </v-flex>
                  <v-flex xs4 md2>
                    <span>
                      {{ order.getProduct[0].getSupplier[0].storename }}</span
                    >
                    <br /><span>
                      {{
                        order.getProduct[0].getSupplier[0].store_telephone
                      }}</span
                    >
                  </v-flex>
                  <!-- 
              <v-checkbox
                v-if="order.order_id != clickedOrderId"
                v-model="checkbox"
                :label="`Assign order`"
                :id="`${order.order_id}`"
               
              ></v-checkbox> -->

                  <v-btn
                    :loading="deliveryLoading"
                    :small="true"
                    dark
                    text
                    class="green mt-2 mb-2"
                    @click="assignRight(order.order_id)"
                    v-if="order.order_id != clickedOrderId"
                  >
                    <span class="text-capitalize">Assign order</span>
                  </v-btn>

                  <v-flex
                    xs5
                    md2
                    v-if="
                      order.order_id == clickedOrderId &&
                      deliveryName.length > 0
                    "
                  >
                    <v-select
                      v-model="selectedDelivery"
                      :items="deliveryName"
                      label="Delivery type"
                      dense
                      outlined
                      @change="selectDrivers"
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs5
                    md2
                    v-if="
                      order.order_id == clickedOrderId &&
                      deliveryName.length > 0
                    "
                  >
                    <v-select
                      :loading="assignLoading"
                      v-model="selectedDriver"
                      :items="driverList"
                      label="Driver"
                      dense
                      outlined
                      @change="assignDriver(order)"
                      :clearable="true"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12>
                    <v-divider class="grey--text"></v-divider>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="reviewOrderOnOff = false"
                  ><span class="text-capitalize">Close</span></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </div>

      <v-dialog v-model="dateDialog" persistent max-width="420">
        <v-card class="">
          <v-card-title>
            Choose date range <v-spacer></v-spacer>
            <v-btn
              class="text-capitalize"
              dark
              color="blue"
              text
              @click="
                dateDialog = false;
                if (date1 != '' && date2 != '') {
                  getOrders('Custom Range');
                  loadingCount == 0;
                }
              "
            >
              GO
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" lg="6">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date1"
                      label="Date 1"
                      hint="YYYY-MM-DD format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date1"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" lg="6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date2"
                      label="Date 2"
                      hint="YYYY-MM-DD format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date2"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>
<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      isNeedDelivery: "needs",
      date1: "",
      date2: "",
      menu1: false,
      menu2: false,
      group: null,
      dateDialog: false,
      choosenDate: "Today",
      choosedDateRange: "",
      itemsd: [
        "Today",
        "Yesterday",
        "Last 15 days",
        "Last 7 days",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],
      paidOrders: [],
      notPaidOrders: [],
      readyOrders: [],

      paidOrdersON: [],
      notPaidOrdersON: [],
      completedOrdersON: [],

      assignedOrders: [],
      onDeliverOrders: [],
      completedOrders: [],
      paymentReview: [],
      orderReview: [],
      deliveryList: [],
      deliveryName: [],
      driverList: [],
      selectedDelivery: "",
      selectedDriver: "",
      totalItemMoneyPaid: 0,
      totalDeliveryFeeePaid: 0,
      totalServiceFeeePaid: 0,
      totalMoneyPaid: 0,
      loadingCount: 0,
      acceptLoading: false,
      cancelLoading: false,
      newOrderLoading: false,
      reviewPaymentOnOff: false,
      reviewOrderOnOff: false,
      deliveryLoading: false,
      assignLoading: false,
      checkbox: false,
      clickedOrderId: 0,
      chooser: "Unpaid Orders",

      tab: null,
      items: ["Unpaid Orders", "Pending Orders", "Completed"],
    };
  },
  methods: {
    clickTab(event) {
      if (event.currentTarget.id == "Pending Orders") {
        // this.getNewOrders();
        this.chooser = "Pending Orders";
      }

      if (event.currentTarget.id == "Completed") {
        // this.getCompletedOrders();
        this.chooser = "Completed";
      }
      if (event.currentTarget.id == "Unpaid Orders") {
        // this.getCompletedOrders();
        this.chooser = "Unpaid Orders";
      }
    },

    reviewPayment(recieptNumber) {
      this.paymentReview = [];
      this.totalMoneyPaid = 0;
      for (let i = 0; i < this.notPaidOrders.length; i++) {
        if (recieptNumber == this.notPaidOrders[i].reciept_number) {
          this.paymentReview.push(this.notPaidOrders[i]);
          this.totalMoneyPaid =
            this.totalMoneyPaid +
            this.notPaidOrders[i].total_price +
            this.notPaidOrders[i].delivery_fee +
            this.notPaidOrders[i].service_fee;
        }
      }
      this.reviewPaymentOnOff = true;
    },

    reviewOrder(recieptNumber) {
      this.orderReview = [];
      this.totalItemMoneyPaid = 0;
      this.totalDeliveryFeeePaid = 0;
      this.totalServiceFeeePaid = 0;
      for (let i = 0; i < this.readyOrders.length; i++) {
        if (recieptNumber == this.readyOrders[i].reciept_number) {
          this.orderReview.push(this.readyOrders[i]);
          this.totalItemMoneyPaid =
            this.totalItemMoneyPaid + this.readyOrders[i].total_price;

          this.totalDeliveryFeeePaid =
            this.totalDeliveryFeeePaid + this.readyOrders[i].delivery_fee;
          this.totalServiceFeeePaid =
            this.totalServiceFeeePaid + this.readyOrders[i].service_fee;
        }
      }

      this.reviewOrderOnOff = true;
    },

    async assignRight(event) {
      if (this.deliveryList.length == 0) {
        await this.getDeliveries();
      }
      this.clickedOrderId = event;
    },

    async acceptPayment(recieptNumber) {
      const toAccept = confirm("Are you sure you want to accept this payment?");
      if (toAccept) {
        this.acceptLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                          acceptOrderPayment(reciept_number : "${recieptNumber}"){
                          statusMessage
                              }
                        }
                `,
            },
          });

          var response = result.data.data.acceptOrderPayment;
          if (response[0].statusMessage) {
            this.reviewPaymentOnOff = false;
          }
        } catch (err) {
          alert(err);
        }
        this.acceptLoading = false;
      }
    },

    async declinePayment(recieptNumber) {
      const toDecline = confirm(
        "Are you sure you want to cancel this payment?"
      );
      if (toDecline) {
        this.cancelLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                          cancelOrderPayment(reciept_number : "${recieptNumber}"){
                          statusMessage
                              }
                        }
                `,
            },
          });

          var response = result.data.data.cancelOrderPayment;
          if (response[0].statusMessage) {
            this.reviewPaymentOnOff = false;
          }
        } catch (err) {
          alert(err);
        }
        this.cancelLoading = false;
      }
    },

    async completeOrder(order) {
      const complete = confirm("Are you sure you want to complete this order?");
      if (complete) {
        this.newOrderLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                          completeOrder(order_number : "${order.order_number}",
                          order_date : "${order.order_date}"
                          ){
                          statusMessage
                              }
                        }
                `,
            },
          });

          var response = result.data.data.completeOrder;
          if (response[0].statusMessage) {
            this.getOrders(this.choosenDate);
          }
        } catch (err) {
          alert(err);
        }
        this.cancelLoading = false;
        this.newOrderLoading = false;
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    async getOrders(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 15 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.choosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.choosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }
      if (this.loadingCount == 0) this.newOrderLoading = true;
      try {
        var newOrdersResult = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `{
              getOrderByDate(date1:"${date1}", date2:"${date2}"){
                    order_id
                    product_id
                    is_cancelled
                    user_id
                    quantity
                    payment_type
                    total_price
                    delivery_fee
                    order_date
                    delivery_status
                    order_number
                    shipping_address
                    order_description
                    isPaid
                    needs_delivery
                    

                    getProducts{
                            product_id
                            sub_category_id
                            title_amharic
                            title_english
                            image
                            blurhash
                            price
                            description_english
                            description_amharic
                            posted_date
                            colors
                    sizes
                    stocks
                          }

                    getUser{
                      user_id
                      full_name
                      phone_number
                      user_address
                    }
                  }

              }
              `,
          },
        });

        var newOrders = newOrdersResult.data.data.getOrderByDate;
        this.notPaidOrders = [];
        this.paidOrders = [];
        this.readyOrders = [];
        this.assignedOrders = [];
        this.onDeliverOrders = [];
        this.completedOrders = [];
        var pendingList = [],
          unpaidList = [],
          completeList = [];
        for (let i = 0; i < newOrders.length; i++) {
          if (newOrders[i].delivery_status == "pl") {
            if (newOrders[i].isPaid == 1) {
              this.paidOrders.push(newOrders[i]);
              pendingList.push(newOrders[i].order_number);
            } else {
              this.notPaidOrders.push(newOrders[i]);
              unpaidList.push(newOrders[i].order_number);
            }
          } else if (newOrders[i].delivery_status == "cl") {
            this.completedOrders.push(newOrders[i]);
            completeList.push(newOrders[i].order_number);
          }
        }

        this.paidOrdersON = [...new Set(pendingList)];
        this.notPaidOrdersON = [...new Set(unpaidList)];
        this.completedOrdersON = [...new Set(completeList)];
      } catch (err) {
        if (this.loadingCount == 0) alert(err);
        this.loadingCount++;
      }

      if (this.loadingCount == 0) this.newOrderLoading = false;
      this.loadingCount++;
    },

    async getDeliveries() {
      this.deliveryLoading = true;
      try {
        var deliveryResult = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `{
               getAllDeliveries{
                  delivery_id
                  delivery_type
                  getDrivers{
                    driver_id
                    first_name
                    last_name
                    driver_telephone
                    getDeliveryType{
                      delivery_type
                    }
                  }   
                }
              }
              `,
          },
        });

        this.deliveryList = deliveryResult.data.data.getAllDeliveries;
        this.deliveryName = [];
        for (let i = 0; i < this.deliveryList.length; i++) {
          this.deliveryName.push(this.deliveryList[i].delivery_type);
        }
      } catch (err) {
        alert(err);
      }
      this.deliveryLoading = false;
    },

    selectDrivers() {
      this.selectedDriver = "";
      for (let i = 0; i < this.deliveryList.length; i++) {
        if (this.selectedDelivery == this.deliveryList[i].delivery_type) {
          this.driverList = [];
          for (let j = 0; j < this.deliveryList[i].getDrivers.length; j++) {
            this.driverList.push(
              this.deliveryList[i].getDrivers[j].first_name +
                " " +
                this.deliveryList[i].getDrivers[j].last_name +
                "," +
                this.deliveryList[i].getDrivers[j].driver_telephone +
                "," +
                this.deliveryList[i].getDrivers[j].driver_id
            );
          }
        }
      }
    },

    async assignDriver(order) {
      const assignConfirmation = confirm(
        "Are you sure you want to assign this oprder to this driver?"
      );
      if (assignConfirmation) {
        this.assignLoading = true;
        try {
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `mutation{
                          addAssignedJob(order_id: ${
                            order.order_id
                          }, driver_id: ${this.selectedDriver
                .split(",")[2]
                .trim()}) {
                             statusMessage
                           }
                        }
                `,
            },
          });

          var response = result.data.data.addAssignedJob;
          if (response[0].statusMessage) {
            this.orderReview.splice(this.orderReview.indexOf(order), 1);
          }
        } catch (err) {
          alert(err);
        }
        this.assignLoading = false;
      }
      this.selectedDriver = "";
      if (this.orderReview.length == 0) this.reviewOrderOnOff = false;
    },
  },
  async created() {
    if (this.$store.state.admin != "") {
      this.getOrders(this.choosenDate);
      setInterval(() => {
        this.getOrders(this.choosenDate);
      }, 120000);
    } else this.$router.push({ name: "login" });

    //  this. getReadyOrders();
  },
};
</script>
<style scoped>
#dashboard {
  max-width: 600px;
  margin: auto;
}

#report {
  max-width: 1110px;
  margin: auto;
}

.outer {
  display: flex;
  flex: 1;
  height: 100%;

  flex-flow: row nowrap;
  align-items: center;

  width: fit-content;
  max-width: 500px;
  display: flex;
  overflow-x: auto;
}
.inner-content {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
}
.inner-content:not(:first-of-type) {
  margin-left: 30px;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
