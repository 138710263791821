import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";

import moment from "moment";

import VueBlurHash from "vue-blurhash";

Vue.use(VueBlurHash);

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
  }
});

Vue.filter("formatDateNoT", function (value) {
  if (value) {
    return moment(new Date(value)).format("YYYY-MM-DD");
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    // firebase.initializeApp({
    //   apiKey: "AIzaSyCpYX6V6Lp1IdWNntoaoNklBk2Js6ROd6A",
    //   authDomain: "gofereapp.firebaseapp.com",
    //   projectId: "gofereapp",
    //   storageBucket: "gofereapp.appspot.com",
    //   messagingSenderId: "744373517082",
    //   appId: "1:744373517082:web:f6036228a8a666bb804aa3",
    //   measurementId: "G-SC5ENJXLMD",
    // });

    firebase.initializeApp({
      apiKey: "AIzaSyCjP-94w5a7MAOLfSwnHgkE7c-j0YlFSPI",
      authDomain: "jodan-c3ddb.firebaseapp.com",
      projectId: "jodan-c3ddb",
      storageBucket: "jodan-c3ddb.appspot.com",
      messagingSenderId: "368424568525",
      appId: "1:368424568525:web:bf3925266954eb9c840b84",
      measurementId: "G-ME2Y8HQ206",
    });
  },
}).$mount("#app");
