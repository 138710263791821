var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$store.state.admin.privilage == 'Super Admin' ||
    _vm.$store.state.admin.privilage == 'Sales Manager' ||
    _vm.$store.state.admin.privilage == 'Sales'
  )?_c('div',{staticClass:"container",staticStyle:{"font-size":"13px"}},[_c('p',{staticClass:"headline white--text ml-5"},[_vm._v("Trendy Products")]),(_vm.trendyProductsLoading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"grey","indeterminate":""}})],1):_vm._e(),(!_vm.trendyProductsLoading)?_c('v-layout',{staticClass:"container white pa-16 pl-10 pr-10 pt-1",staticStyle:{"border-radius":"3px","font-size":"13px"},attrs:{"wrap":"","row":"","id":"report"}},_vm._l((_vm.trendyProducts),function(product,i){return _c('v-flex',{key:i,staticClass:"pr-1",attrs:{"xs6":"","md2":""}},[_c('br'),_c('blur-hash-image',{attrs:{"hash":product.getProducts[0].blurhash.split(',pimg')[0].trim(),"src":product.getProducts[0].image.split(',pimg')[0].trim(),"height":"150"}}),_c('br'),_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"170px"}},[_vm._v(" "+_vm._s(product.getProducts[0].title_english))]),_c('br'),_c('span',[_vm._v(" "+_vm._s(product.getProducts[0].price)+" Birr")]),_c('br'),_c('br'),_c('div',{staticClass:"mt-2"},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.removeTrendyProduct(product)}}},[_c('span',[_c('v-icon',{staticClass:"red--text",attrs:{"small":""}},[_vm._v("delete")])],1)]),(_vm.removeLoading && _vm.productId == product.productId)?_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}}):_vm._e()],1)],1)}),1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"1200px"},on:{"click:outside":function($event){_vm.allProducts = [];
      _vm.searchQuery = '';}},model:{value:(_vm.searchProduct),callback:function ($$v) {_vm.searchProduct=$$v},expression:"searchProduct"}},[_c('v-container',{staticClass:"white pb-15"},[_c('v-flex',{staticClass:"mt-2 mb-10",attrs:{"xs3":""}},[_c('v-text-field',{attrs:{"color":"deep-purple accent-4","label":"Search products","append-icon":"search","hide-details":true,"clearable":"","outlined":"","required":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),(_vm.loadingProducts)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":2,"color":"grey","indeterminate":""}})],1):_vm._e(),(!_vm.loadingProducts && _vm.allProducts.length > 0)?_c('v-row',_vm._l((_vm.allProducts),function(product,i){return _c('v-flex',{key:i,staticClass:"pl-2 mt-2",attrs:{"xs2":""}},[_c('v-card',[_c('blur-hash-image',{attrs:{"hash":product.blurhash.split(',pimg')[0].trim(),"src":product.image.split(',pimg')[0].trim(),"height":"150px","contain":"","width":"120"}}),_c('v-card-text',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"140px","font-size":"16px"}},[_vm._v(_vm._s(product.title_english)+" "),_c('br'),_vm._v(" "+_vm._s(product.price)+" ETB ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"blue--text text-capitalize",attrs:{"text":"","small":""},on:{"click":function($event){_vm.trendyProduct = product;
                  _vm.addToTrendy();}}},[_vm._v(" Add to trendy ")])],1)],1)],1)}),1):_vm._e()],1)],1),_c('v-btn',{staticClass:"fab grey lighten-2 text-capitalize",attrs:{"fab":""},on:{"click":function($event){_vm.searchProduct = true}}},[_c('v-icon',[_vm._v(" add ")])],1)],1):_c('div',{staticClass:"text-center mt-16",attrs:{"id":"error"}},[_c('p',{staticClass:"red--text text-center"},[_vm._v("Access Denied")])])
}
var staticRenderFns = []

export { render, staticRenderFns }