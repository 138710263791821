<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager'
    "
  >
    <p class="headline white--text ml-5">Add UI</p>
    <!-- dialog for adding -->
    <v-dialog v-model="addingDialog" persistent width="300">
      <v-card color="primary text-center" dark>
        <v-card-text>
          {{ addingStatus }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dialog for adding ends -->

    <v-form
      class="container white pa-16 pl-10 pr-10 pt-1"
      id="report"
      style="border-radius: 3px; font-size: 13px"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row justify="center">
        <v-flex xs12 md6>
          <v-card
            elevation="0"
            light
            @dragend="OnDragEnter"
            @dragleave="OnDragLeave"
            @dragover.prevent
            @drop="OnDrop"
            class="text-center ma-5"
          >
            <input
              type="file"
              accept="image/*"
              ref="selectImage"
              style="display: none"
              @change="OnInputFile"
            />
            <input
              type="file"
              accept="image/*"
              ref="changeImage"
              style="display: none"
              @change="OnInputFile"
            />

            <v-img
              @mouseenter="changeCursor"
              id="imageCamera"
              class="ml-5 mr-5"
              :src="image1"
              height="142"
              contain
              v-if="image1 != ''"
              @click="changeImage()"
            ></v-img>

            <div
              @mouseenter="changeCursor"
              id="imageCamera"
              class="ml-5 mr-5 text-center"
              v-if="image1 == ''"
              @click="simulateInputfileClicked"
            >
              <!-- <v-btn centered outlined large fab class="ma-4"> -->
              <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
              <br />
              <br />
              <br />
              <p class="text-center grey--text">Select Image</p>
              <!-- </v-btn> -->
            </div>
          </v-card>
        </v-flex>
      </v-row>

      <v-row justify="center">
        <v-flex xs12 sm6 md5>
          <v-text-field
            v-model="main_title_in_english"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'title is required']"
            label="Enter title"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 md5>
          <v-text-field
            v-model="main_title_in_amharic"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'title is required']"
            label="Enter title in amharic"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>
        </v-flex>
      </v-row>

      <v-row justify="center">
        <v-flex xs12 sm6 md5>
          <v-text-field
            v-model="description_in_english"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'description is required']"
            label="Enter description"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm6 md5>
          <v-text-field
            v-model="description_in_amharic"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'Description in amharic is required']"
            label="Enter description in amharic"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>
        </v-flex>
      </v-row>

      <v-row justify="center">
        <v-flex xs12 sm6 md5>
          <v-select
            class="mx-5"
            style="font-size: 11px"
            v-model="selectedSubdept"
            :items="allSubdepts"
            :rules="[(v) => !!v || 'Sub category is required']"
            label="Select Sub category"
            outlined
            clearable
            attach
            dense
          />
        </v-flex>
        <v-flex xs12 sm6 md5>
          <v-select
            class="mx-5"
            style="font-size: 11px"
            v-model="selectedViewStyle"
            :items="viewStyles"
            :rules="[(v) => !!v || 'Box-Type is required']"
            label="Select Box-Type"
            outlined
            clearable
            attach
            dense
          />
        </v-flex>
      </v-row>

      <v-row justify="center">
        <v-btn
          text
          class="px-10 grey lighten-2 text-capitalize"
          small
          @click="addUi"
        >
          Save UI
        </v-btn>
      </v-row>
    </v-form>
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase";
import * as blurhash from "blurhash";

export default {
  data() {
    return {
      main_title_in_english: "",
      main_title_in_amharic: "",
      description_in_english: "",
      description_in_amharic: "",
      image1: "",
      imageUrl1: "",
      blurhash1: "",
      files: [],
      allSubdepts: [],
      selectedSubdept: "",
      valid: true,
      viewStyles: ["Box-Type 1", "Box-Type 2", "Box-Type 3", "Slider"],
      selectedViewStyle: "",
      addingStatus: "",
      addingDialog: false,
    };
  },
  methods: {
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();
      console.log("entering");
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      console.log("leaving");
    },
    OnDrop(e) {
      console.log("dropping");
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      console.log(files);
      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = [];
      Array.from(files).forEach((file) => this.addImage(file));
      console.log(this.files);
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        console.log(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    async uploadImage() {
      var storageReference = firebase
        .storage()
        .ref("adminimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(this.files[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl1 = url;
          console.log(this.imageUrl + "  url1");
        });
    },
    async getAllSubdepts() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                   {
                    getAllSubCategories{
                      sub_category_id
                      title_english
                    }
                  }
                `,
          },
        });

        for (let i = 0; i < result.data.data.getAllSubCategories.length; i++) {
          this.allSubdepts.push(
            result.data.data.getAllSubCategories[i].title_english +
              "," +
              result.data.data.getAllSubCategories[i].sub_category_id
          );
        }
      } catch (err) {
        alert(err);
      }
    },
    async addUi() {
      if (this.files.length < 1) {
        alert("Please add image");
        return;
      }

      if (this.$refs.form.validate()) {
        try {
          this.addingStatus = "uploading image";
          this.addingDialog = true;
          await this.uploadImage();
          this.blurhash1 = await this.decodeImagetoBlurhash(this.image1);
        } catch (error) {
          console.log(error);
        }

        try {
          this.addingStatus = "adding data";
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                 mutation {
                  addUI(
                   title_english:"${this.main_title_in_english}"
                    title_amharic:"${this.main_title_in_amharic}"
                    description_english:"${this.description_in_english}"
                    description_amharic:"${this.description_in_amharic}"

                    image:"${this.imageUrl1}"
                  

                    blurhash:"${this.blurhash1}"
                   


                    url_id:"${parseInt(this.selectedSubdept.split(",")[1])}"
                   
                    box_type:"${this.selectedViewStyle}"
                   

                    ){
                        statusMessage
                    }
                    }
              `,
            },
          });
          if (result.data.data.addUI[0].statusMessage) {
            this.addingDialog = false;
            this.addingStatus = "";

            alert("UI Added Successfully");
            this.$router.push({ name: "ui" }).catch((err) => {
              if (err.name != "NavigationDuplicated") throw err;
            });
          } else {
            this.addingDialog = false;
            this.addingStatus = "";

            alert("Something went wrong, please try again");
          }
        } catch (err) {
          this.addingDialog = false;
          this.addingStatus = "";

          alert(err);
        }
      }
    },
    async decodeImagetoBlurhash(url) {
      const loadImage = async (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (...args) => reject(args);
          img.src = src;
          img.crossOrigin = "";
        });

      const getImageData = (image) => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;

        canvas.height = image.height;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0);

        return context.getImageData(0, 0, image.width, image.height);
      };

      const image = await loadImage(url);
      const imageData = getImageData(image);

      return blurhash.encode(
        imageData.data,
        imageData.width,
        imageData.height,
        4,
        3
      );
    },
  },
  created() {
    if (this.$store.state.admin != "") {
      this.getAllSubdepts();
    } else this.$router.push({ name: "login" });
  },
};
</script>

<style scoped>
#imageCamera {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(189, 159, 159);
  font-size: 10px;
}

#report {
  max-width: 1110px;
  margin: auto;
}
</style>
