<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="$store.state.admin.privilage == 'Super Admin'"
  >
    <p class="headline white--text ml-5">Categories</p>

    <v-dialog
      v-model="addMainDeptDialog"
      max-width="400"
      @click:outside="
        isEditingMaindept = false;

        maindept_title_in_english = '';
        maindept_title_in_amharic = '';

        $refs.form.reset();
      "
    >
      <v-container class="white">
        <v-card
          elevation="0"
          light
          @dragend="OnDragEnter"
          @dragleave="OnDragLeave"
          @dragover.prevent
          @drop="OnDrop"
          class="text-center mx-15 pl-9 pr-9"
        >
          <input
            type="file"
            accept="image/*"
            ref="selectImage"
            style="display: none"
            @change="OnInputFile"
          />
          <input
            type="file"
            accept="image/*"
            ref="changeImage"
            style="display: none"
            @change="OnInputFile"
          />

          <v-img
            @mouseenter="changeCursor"
            id="imageCamera"
            class="ml-5 mr-5"
            :src="image1 == '' ? imageUrl : image1"
            height="142"
            contain
            v-if="image1 != '' || isEditingMaindept"
            @click="changeImage()"
          ></v-img>

          <div
            @mouseenter="changeCursor"
            id="imageCamera"
            class="ml-5 mr-5 text-center"
            v-if="image1 == '' && !isEditingMaindept"
            @click="simulateInputfileClicked"
          >
            <!-- <v-btn centered outlined large fab class="ma-4"> -->
            <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
            <br />
            <br />
            <br />
            <p class="text-center grey--text">Select Image</p>
            <!-- </v-btn> -->
          </div>
        </v-card>

        <v-form
          class="mt-5 text-center"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="maindept_title_in_english"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'title is required']"
            label="Title in English"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>

          <v-text-field
            v-model="maindept_title_in_amharic"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'amharic title is required']"
            label="Title in Amharic"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>

          <v-btn
            text
            v-if="!isEditingMaindept"
            color=""
            class="px-10 grey lighten-2 text-capitalize"
            @click="addMainDept()"
          >
            Add
          </v-btn>
          <v-btn
            text
            v-else
            class="px-10 grey lighten-2 text-capitalize"
            @click="editMainDept()"
          >
            Edit
          </v-btn>
        </v-form>
      </v-container>
    </v-dialog>

    <v-dialog
      v-model="addSubDeptDialog"
      v-if="selectedDepartment != undefined"
      max-width="400px"
      @click:outside="
        isEditingSubdept = false;

        subdept_title = '';
        subdept_title_in_amharic = '';
        editedId = undefined;
        imageUrl = '';
        image1 = '';
        $refs.subdeptform.reset();
      "
    >
      <v-container class="white">
        <v-card
          elevation="0"
          light
          @dragend="OnDragEnter"
          @dragleave="OnDragLeave"
          @dragover.prevent
          @drop="OnDrop"
          class="text-center pl-1 pr-1"
        >
          <input
            type="file"
            accept="image/*"
            ref="selectImage"
            style="display: none"
            @change="OnInputFile"
          />
          <input
            type="file"
            accept="image/*"
            ref="changeImage"
            style="display: none"
            @change="OnInputFile"
          />

          <v-img
            @mouseenter="changeCursor"
            id="subimageCamera"
            class="ml-10 mr-10"
            :src="image1 == '' ? imageUrl : image1"
            height="142"
            contain
            v-if="image1 != '' || isEditingSubdept"
            @click="changeImage()"
          ></v-img>

          <div
            @mouseenter="changeCursor"
            id="subimageCamera"
            class="ml-10 mr-10 text-center"
            v-if="image1 == '' && !isEditingSubdept"
            @click="simulateInputfileClicked"
          >
            <!-- <v-btn centered outlined large fab class="ma-4"> -->
            <v-icon centered class="mt-10 grey--text">camera_alt</v-icon>
            <br />
            <br />
            <br />
            <p class="text-center grey--text">Select Image</p>
            <!-- </v-btn> -->
          </div>
        </v-card>
        <v-form
          class="mt-5 text-center"
          ref="subdeptform"
          v-model="valid"
          lazy-validation
        >
          <v-btn
            text
            width="200"
            color=""
            class="px-10 mb-3 text-capitalize"
            small
          >
            Category: {{ selectedDepartment.title_english }}</v-btn
          >

          <br />
          <v-text-field
            v-model="subdept_title"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'title is required']"
            label="Sub Category Title"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>

          <v-text-field
            v-model="subdept_title_in_amharic"
            style="font-size: 11px"
            :rules="[(v) => !!v || 'Sub Category title in amharic is required']"
            label="Sub category title in amharic"
            required
            dense
            outlined
            class="mx-5"
          ></v-text-field>

          <v-btn
            v-if="!isEditingSubdept"
            text
            class="px-10 grey lighten-2 text-capitalize"
            @click="addSubDept"
          >
            Add
          </v-btn>
          <v-btn
            v-else
            text
            class="px-10 grey lighten-2 text-capitalize"
            @click="editSubDept"
          >
            Edit
          </v-btn>
        </v-form>
      </v-container>
    </v-dialog>

    <v-dialog v-model="deleteMainDeptPopUp" max-width="290">
      <v-card>
        <v-card-title class="headline">
          <strong> Delete Category </strong>
        </v-card-title>
        <v-card-text>Are You Sure You want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteMainDept()">
            <v-icon>delete</v-icon>Yes
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteMainDeptPopUp = false"
          >
            <v-icon>arrow_back_ios</v-icon> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteSubDeptPopUp" max-width="290">
      <v-card>
        <v-card-title class="headline">
          <strong> Delete Sub Category </strong>
        </v-card-title>
        <v-card-text>Are You Sure to delete this sub category ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteSubDept()">
            <v-icon>delete</v-icon>Yes
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="deleteSubDeptPopUp = false"
          >
            <v-icon>arrow_back_ios</v-icon> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addingDialog" persistent width="300">
      <v-card color="primary text-center" dark>
        <v-card-text>
          {{ addingStatus }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deletePopUp" max-width="290">
      <v-card>
        <v-card-title class="headline">
          <strong> Delete Product </strong>
        </v-card-title>
        <v-card-text>Are You Sure to delete this product ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="deleteProduct()">
            <v-icon>delete</v-icon>Yes
          </v-btn>
          <v-btn color="green darken-1" text @click="deletePopUp = false">
            <v-icon>arrow_back_ios</v-icon> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="trendyPopUp" max-width="290">
      <v-card>
        <v-card-title class="headline">
          <strong> Trendy </strong>
        </v-card-title>
        <v-card-text>Are You Sure to make this product trendy ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="addToTrendy()">
            <v-icon>check</v-icon>Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="trendyPopUp = false">
            <v-icon>arrow_back_ios</v-icon> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-flex sm12 md3 class="text-center" v-if="loadingDepartments">
      <v-progress-circular
        :size="70"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
    </v-flex>

    <v-row
      class="white pa-16 pl-0 pr-2 pt-1"
      style="border-radius: 3px"
      v-if="!loadingDepartments"
      id="report"
    >
      <v-flex sm12 md3 class="mr-4">
        <v-col>
          <v-btn
            x-small
            fab
            color="grey lighten-2"
            @click="addMainDeptDialog = true"
          >
            <v-icon> add </v-icon>
          </v-btn>
          <v-row justify="center">
            <h1 class="ml-12 text-center">{{ mainDepts.length }}</h1>
          </v-row>
          <v-row justify="center" class="title text-center ml-12">
            Total Categories</v-row
          >
          <br />
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in mainDepts"
                :key="i"
                @click="getSubdepts(item)"
              >
                <v-list-item-avatar height="30" width="30">
                  <v-img
                    contain
                    :src="item.image"
                    height="100%"
                    width="100%"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{
                    item.title_english
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar
                  height="30"
                  width="30"
                  class="grey lighten-3 pl-4"
                >
                  {{ item.getSubCategory.length }}
                </v-list-item-avatar>
                <!-- <v-list-item-icon>
                  <v-icon> arrow_forward_ios </v-icon>
                </v-list-item-icon> -->

                <v-list-item-icon>
                  <v-menu offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="red darken-3">
                        more_horiz
                      </v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item
                        @click="
                          selectedDepartment = item;
                          setupEditMaindept();
                        "
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          deleteMainDeptPopUp = true;
                          deleteId = item.category_id;
                          deleteTitle = item.title_english;
                        "
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-flex>
      <v-flex sm12 md3 class="mr-4" v-if="loadingSubdept">
        <v-progress-circular
          :size="70"
          :width="2"
          color="grey"
          indeterminate
        ></v-progress-circular>
      </v-flex>
      <v-flex
        sm12
        md3
        class="mr-4"
        v-if="selectedSubDept != undefined && !loadingSubdept"
      >
        <v-col>
          <v-btn
            x-small
            fab
            color="grey lighten-2"
            @click="addSubDeptDialog = true"
          >
            <v-icon> add </v-icon>
          </v-btn>
          <v-row justify="center"
            ><h1 class="ml-12 text-center">{{ selectedSubDept.length }}</h1>
          </v-row>
          <v-row justify="center" class="title">
            Sub Categories in
            {{ selectedDepartment.title_english }}</v-row
          >
          <br />
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in selectedSubDept"
                :key="i"
                @click="selectSubdeptProducts(item.getProducts)"
              >
                <v-list-item-avatar height="30" width="30">
                  <v-img
                    contain
                    :src="item.image"
                    height="100%"
                    width="100%"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.title_english
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar
                  height="30"
                  width="30"
                  class="grey lighten-3 pl-4"
                >
                  {{ item.getProducts.length }}
                </v-list-item-avatar>
                <!-- <v-list-item-icon>
                  <v-icon> arrow_forward_ios </v-icon>
                </v-list-item-icon> -->
                <v-list-item-icon>
                  <v-menu offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" color="red darken-3">
                        more_horiz
                      </v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item @click="setupEditSubdept(item)">
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          deleteSubDeptPopUp = true;
                          deleteId = item.sub_category_id;
                        "
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-flex>

      <v-flex
        sm12
        md5
        class="mr-1 subdeptProduct pt-2"
        v-if="selecedSubdeptProducts != undefined"
      >
        <v-row>
          <v-flex
            xs4
            v-for="(product, i) in selecedSubdeptProducts"
            :key="i"
            class="pl-2 mt-2"
          >
            <v-card>
              <blur-hash-image
                :hash="product.blurhash.split(',pimg')[0].trim()"
                :src="product.image.split(',pimg')[0].trim()"
              />

              <v-card-text
                class="d-inline-block text-truncate"
                style="max-width: 140px; font-size: 16px"
                >{{ product.title_english }}
                <br />
                {{ product.title_amharic }}
              </v-card-text>
              <v-card-subtitle class="subtitle-1">
                {{ product.price }} ETB
              </v-card-subtitle>

              <v-divider class="mx-4"></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="blue--text"
                  text
                  fab
                  small
                  @click="(trendyPopUp = true), (trendyProduct = product)"
                >
                  <v-icon>trending_up</v-icon>
                </v-btn>
                <v-btn
                  class="red--text"
                  text
                  fab
                  small
                  @click="(deletePopUp = true), (deletingProduct = product)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            <!-- <v-card>
              <div>
                <blur-hash-image
                  :hash="product.blurhash_one"
                  :src="product.image_one"
                />
                

                <p
                  class="d-inline-block text-truncate ml-2"
                  style="max-width: 140px"
                >
                  {{ product.product_title_in_english }}
                  <br />
                  {{ product.product_title_in_amharic }}
                  <br />
                  <span class="subtitle-1"> {{ product.price }} ETB </span>
                </p>
                <v-row class="ma-0 pa-0">
                  <v-btn
                    color="deep-purple lighten-2 mr-4"
                    text
                    fab
                    small
                    @click="(deletePopUp = true), (deletingProduct = product)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-row>
              </div>
            </v-card> -->
          </v-flex>
        </v-row>
      </v-flex>
    </v-row>
    <!-- <v-btn
      class="fab"
      fab
      color="primary"
      v-if="!loadingDepartments"
      @click="addMainDeptDialog = true"
    >
      <v-icon> add </v-icon>
    </v-btn> -->
  </div>
  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>
<script>
import axios from "axios";
import firebase from "firebase";
import * as blurhash from "blurhash";

export default {
  data: () => ({
    loadingDepartments: false,
    loadingSubdept: false,
    addMainDeptDialog: false,
    addSubDeptDialog: false,
    addingDialog: false,
    deletePopUp: false,
    trendyPopUp: false,
    isEditingMaindept: false,
    isEditingSubdept: false,
    mainDepts: [],
    selectedDepartment: undefined,
    selectedSubDept: undefined,
    allSubdepts: [],
    selectedChips: [],
    selecedSubdeptProducts: undefined,
    image1: "",
    files: [],
    valid: true,
    maindept_title_in_english: "",
    maindept_title_in_amharic: "",
    tab1_title: "",
    tab2_title: "",
    tab3_title: "",
    tab1_title_in_amharic: "",
    tab2_title_in_amharic: "",
    tab3_title_in_amharic: "",
    selectedTab: "",
    imageUrl: "",
    addingStatus: "",
    deleteMainDeptPopUp: false,
    deleteSubDeptPopUp: false,
    deleteId: undefined,
    deleteTitle: undefined,
    editedId: undefined,
    deletingProduct: undefined,
    trendyProduct: undefined,
    selectedStyleView: "Choose Style View",
    subdept_title: "",
    subdept_title_in_amharic: "",
    blurhash: "",
  }),
  methods: {
    log() {
      console.log(this.isEditingMaindept);
    },
    changeCursor(e) {
      e.target.style.cursor = "pointer";
    },
    OnDragEnter(e) {
      e.preventDefault();
      console.log("entering");
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      console.log("leaving");
    },
    OnDrop(e) {
      console.log("dropping");
      e.preventDefault();
      e.stopPropagation();
      const files = e.dataTransfer.files;
      console.log(files);
      Array.from(files).forEach((file) => this.addImage(file));
    },
    OnInputFile(e) {
      const files = e.target.files;
      this.files = [];
      Array.from(files).forEach((file) => this.addImage(file));
    },
    simulateInputfileClicked() {
      this.$refs.selectImage.click();
    },
    addImage(file) {
      if (!file.type.match("image.*")) {
        console.log(`${file.name} is not an image`);
        return;
      }
      this.files.push(file);
      const reader = new FileReader();
      reader.onload = (f) => {
        this.image1 = f.target.result;
      };
      reader.readAsDataURL(file);
    },
    changeImage() {
      this.$refs.changeImage.click();
    },
    navigator(route) {
      this.$router.push(route).catch((err) => {
        if (err.name != "NavigationDuplicated") throw err;
      });
      window.scrollTo(0, 0);
    },
    async getMainDepts() {
      this.loadingDepartments = true;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                    {
                    getAllCategories {
                      category_id
                      blurhash
                       title_english
                       title_amharic
                        image
                        blurhash
                        getSubCategory{
                          sub_category_id
                          category_id
                          title_amharic
                          title_english
                          image
                          blurhash
                          getProducts{
                            product_id
                            sub_category_id
                            title_amharic
                            title_english
                            image
                            blurhash
                            price
                            description_english
                            description_amharic
                            posted_date
                          }
                          }
                    }
                    }
                `,
          },
        });

        this.mainDepts = result.data.data.getAllCategories;
        this.loadingDepartments = false;
      } catch (err) {
        alert(err);
        this.loadingDepartments = false;
      }
    },
    async getSubdepts(selectedMainDept) {
      this.selecedSubdeptProducts = undefined;
      this.selectedDepartment = selectedMainDept;

      this.selectedSubDept = this.selectedDepartment.getSubCategory;
    },

    async getAllSubdepts() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                   {
                    getSubDepts{
                      subdept_title_in_english
                    }
                  }
                `,
          },
        });

        for (let i = 0; i < result.data.data.getSubDepts.length; i++) {
          this.allSubdepts.push(
            result.data.data.getSubDepts[i].subdept_title_in_english
          );
        }
      } catch (err) {
        alert(err);
      }
    },

    selectSubdeptProducts(item) {
      this.selecedSubdeptProducts = undefined;
      this.selecedSubdeptProducts = item;
    },
    async uploadImage() {
      var storageReference = firebase
        .storage()
        .ref("adminimages/image" + Math.random() + ".jpg");

      await (await storageReference.put(this.files[0])).ref
        .getDownloadURL()
        .then((url) => {
          this.imageUrl = url;
        });
    },

    async decodeImagetoBlurhash(url) {
      const loadImage = async (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = (...args) => reject(args);
          img.src = src;
          img.crossOrigin = "";
        });

      const getImageData = (image) => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;

        canvas.height = image.height;

        const context = canvas.getContext("2d");

        context.drawImage(image, 0, 0);

        return context.getImageData(0, 0, image.width, image.height);
      };

      const image = await loadImage(url);
      const imageData = getImageData(image);

      return blurhash.encode(
        imageData.data,
        imageData.width,
        imageData.height,
        4,
        3
      );
    },

    async addMainDept() {
      if (this.files.length < 1) {
        alert("Please add image");
        return;
      }

      if (this.$refs.form.validate()) {
        try {
          this.addingStatus = "Uploading image...";
          this.addingDialog = true;

          await this.uploadImage();
          this.blurhash = await this.decodeImagetoBlurhash(this.image1);
        } catch (error) {
          console.log(error);
        }
        this.image1 = this.imageUrl1;
        this.files = [];
        try {
          this.addingStatus = "Adding data...";
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                 mutation  {
                  addCategory(
                    title_english:"${this.maindept_title_in_english}",
                    title_amharic: "${this.maindept_title_in_amharic}",
                    blurhash: "${this.blurhash}",
                    image: "${this.imageUrl}") 
                  {
                    statusMessage
                  }
                  }
              `,
            },
          });

          if (result.data.data.addCategory[0].statusMessage) {
            this.addingDialog = false;
            this.addingStatus = "";
            this.addMainDeptDialog = false;
            alert("Added Successfully");
            await this.getMainDepts();
            this.imageUrl = "";
            this.image1 = "";
          } else {
            this.addingDialog = false;
            this.addingStatus = "";

            alert("Something went wrong, please try again");
          }
        } catch (err) {
          this.addingDialog = false;
          this.addingStatus = "";

          alert(err);
        }
      }
      this.imageUrl = "";
      this.image1 = "";
    },
    async addSubDept() {
      if (this.files.length < 1) {
        alert("Please add image");
        return;
      }
      if (this.$refs.subdeptform.validate()) {
        try {
          this.addingStatus = "Uploading image...";
          this.addingDialog = true;

          await this.uploadImage();
          this.blurhash = await this.decodeImagetoBlurhash(this.image1);
        } catch (error) {
          console.log(error);
        }
        this.image1 = this.imageUrl;
        this.files = [];
        try {
          this.addingStatus = "adding data";
          this.addingDialog = true;

          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                 mutation {
                  addSubCategory(
                    title_english: "${this.subdept_title}", 
                    title_amharic: "${this.subdept_title_in_amharic}", 
                    category_id: ${parseInt(
                      this.selectedDepartment.category_id
                    )},                   
                    
                    image: "${this.image1}", 
                    blurhash: "${this.blurhash}"
                   ) {
                      statusMessage
                    }
                  }
              `,
            },
          });

          if (result.data.data.addSubCategory[0].statusMessage) {
            this.addingDialog = false;
            this.addingStatus = "";
            this.addSubDeptDialog = false;
            alert("Sub Category Added Successfully");
            this.getMainDepts();
            this.selectedSubDept = undefined;
            this.imageUrl = "";
            this.image1 = "";
          } else {
            this.addingDialog = false;
            this.addingStatus = "";

            alert("Something went wrong, please try again");
          }
        } catch (err) {
          this.addingDialog = false;
          this.addingStatus = "";

          alert(err);
        }
      }
    },
    async deleteMainDept() {
      this.deleteMainDeptPopUp = false;
      this.addingStatus = "Deleteing Category";
      this.addingDialog = true;

      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                 mutation{
                  deleteCategory(category_id: ${this.deleteId}){
                        statusMessage
                      }
                    }
                `,
          },
        });
        if (result.data.data.deleteCategory[0].statusMessage) {
          alert("Category Deleted Successfully");
          this.addingDialog = false;
          this.getMainDepts();
          this.selectedSubDept = undefined;
        } else {
          alert("Something went wrong, please try again!");
          this.addingDialog = false;
        }
      } catch (err) {
        alert(err);
        this.addingDialog = false;
      }
    },
    async deleteSubDept() {
      this.deleteSubDeptPopUp = false;
      this.addingStatus = "Deleteing sub category";
      this.addingDialog = true;

      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                 mutation{
                  deleteSubCategory(sub_category_id: ${this.deleteId}){
                        statusMessage
                      }
                    }
                `,
          },
        });
        if (result.data.data.deleteSubCategory[0].statusMessage) {
          alert("Sub Category Deleted Successfully");
          this.addingDialog = false;
          this.getMainDepts();
          this.selectedSubDept = undefined;
        } else {
          alert("Something went wrong, please try again!");
          this.addingDialog = false;
        }
      } catch (err) {
        alert(err);
        this.addingDialog = false;
      }
    },

    setupEditMaindept() {
      this.isEditingMaindept = true;

      this.maindept_title_in_english = this.selectedDepartment.title_english;
      this.maindept_title_in_amharic = this.selectedDepartment.title_amharic;
      this.imageUrl = this.selectedDepartment.image;
      this.blurhash = this.selectedDepartment.blurhash;
      this.addMainDeptDialog = true;
    },
    setupEditSubdept(item) {
      this.isEditingSubdept = true;
      this.editedId = item.sub_category_id;

      this.subdept_title = item.title_english;
      this.subdept_title_in_amharic = item.title_amharic;
      this.imageUrl = item.image;
      this.blurhash = item.blurhash;

      this.addSubDeptDialog = true;
    },

    async editMainDept() {
      if (this.$refs.form.validate()) {
        this.addingDialog = true;

        if (this.image1 != "") {
          try {
            this.addingStatus = "uploading image";
            await this.uploadImage();
            this.blurhash = await this.decodeImagetoBlurhash(this.image1);
          } catch (error) {
            console.log(error);
          }
        }
        this.image1 = this.imageUrl1;
        this.files = [];
        try {
          this.addingStatus = "adding data";
          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                 mutation  {
                  updateCategory(
                    category_id: ${this.selectedDepartment.category_id},
                    title_english:"${this.maindept_title_in_english}",
                    title_amharic: "${this.maindept_title_in_amharic}",
                    blurhash: "${this.blurhash}",
                    image: "${this.imageUrl}") 
                  {
                    statusMessage
                  }
                  }
              `,
            },
          });

          if (result.data.data.updateCategory[0].statusMessage) {
            this.addingDialog = false;
            this.addingStatus = "";
            this.addMainDeptDialog = false;
            this.imageUrl = "";
            this.image1 = "";
            alert("Category Edited Successfully");
            this.getMainDepts();
          } else {
            this.addingDialog = false;
            this.addingStatus = "";
            this.imageUrl = "";
            this.image1 = "";
            alert("Something went wrong, please try again");
          }
        } catch (err) {
          this.addingDialog = false;
          this.addingStatus = "";
          this.imageUrl = "";
          this.image1 = "";
          alert(err);
        }
      }
    },
    async editSubDept() {
      if (this.$refs.subdeptform.validate()) {
        this.addingDialog = true;
        if (this.image1 != "") {
          try {
            this.addingStatus = "uploading image";
            await this.uploadImage();
            this.blurhash = await this.decodeImagetoBlurhash(this.image1);
          } catch (error) {
            console.log(error);
          }
        }
        this.image1 = this.imageUrl1;
        this.files = [];
        try {
          this.addingStatus = "adding data";

          var result = await axios({
            method: "POST",
            url: this.$store.state.url,
            data: {
              query: `
                 mutation {
                  updateSubCategory(
                    sub_category_id: ${this.editedId},
                    title_english:"${this.subdept_title}",
                    title_amharic: "${this.subdept_title_in_amharic}",
                    blurhash: "${this.blurhash}",
                    image: "${this.imageUrl}") {
                      statusMessage
                    }
                  }
              `,
            },
          });

          if (result.data.data.updateSubCategory[0].statusMessage) {
            this.addingDialog = false;
            this.addingStatus = "";
            this.addSubDeptDialog = false;
            alert("Sub Category Updated Successfully");
            this.getMainDepts();
            this.selectedSubDept = undefined;
            this.imageUrl = "";
            this.image1 = "";
          } else {
            this.addingDialog = false;
            this.addingStatus = "";
            this.imageUrl = "";
            this.image1 = "";
            alert("Something went wrong, please try again");
          }
        } catch (err) {
          this.addingDialog = false;
          this.addingStatus = "";
          this.imageUrl = "";
          this.image1 = "";

          alert(err);
        }
      }
    },
    async deleteProduct() {
      this.deletePopUp = false;
      this.addingDialog = true;
      this.addingStatus = "deleting Product";
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                 mutation {
                      deleteProduct(product_id: ${this.deletingProduct.product_id}) {
                        statusMessage
                      }
                    }
                `,
          },
        });
        if (result.data.data.deleteProduct[0].statusMessage) {
          this.addingDialog = false;
          this.addingStatus = "";
          this.deletePopUp = false;
          alert("Product Delted Successfully");
          this.selectedSubDept = undefined;
          this.selecedSubdeptProducts = undefined;
          this.getMainDepts();
        } else {
          this.addingDialog = false;
          this.addingStatus = "";

          alert("Something went wrong, please try again");
        }
      } catch (err) {
        this.addingDialog = false;
        this.addingStatus = "";

        alert(err);
      }
    },
    async addToTrendy() {
      this.trendyPopUp = false;
      this.addingDialog = true;
      this.addingStatus = "Adding trendy";
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                  mutation {
                    addTrendy(product_id: ${this.trendyProduct.product_id}) {
                        statusMessage
                      }
                    }
                `,
          },
        });
        if (result.data.data.addTrendy[0].statusMessage) {
          this.addingDialog = false;
          this.addingStatus = "";
          this.trendyPopUp = false;
          alert("Added Successfully");
        } else {
          this.addingDialog = false;
          this.addingStatus = "";

          alert("Something went wrong, please try again");
        }
      } catch (err) {
        this.addingDialog = false;
        this.addingStatus = "";

        alert(err);
      }
    },
  },
  async created() {
    if (this.$store.state.admin != "") {
      await this.getMainDepts();
    } else this.$router.push({ name: "login" });
  },
};
</script>

<style scoped>
.fab {
  position: fixed;
  padding: 2em;
  left: 5%;
  bottom: 10%;
}

.subdeptProduct {
  height: 100vh;
  overflow: auto;
}

#imageCamera {
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  border-color: rgb(189, 159, 159);
  font-size: 10px;
}

#subimageCamera {
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgb(189, 159, 159);
  font-size: 10px;
}

#report {
  max-width: 1110px;
  margin: auto;
}
</style>
