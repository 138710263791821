<template>
  <div
    class="container"
    style="font-size: 13px"
    v-if="
      $store.state.admin.privilage == 'Super Admin' ||
      $store.state.admin.privilage == 'Sales Manager' ||
      $store.state.admin.privilage == 'Sales'
    "
  >
    <p class="headline white--text ml-5">Product Detail</p>
    <v-snackbar v-model="snackbar">
      {{ status }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="(snackbar = false), (status = '')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div
      class="container white pa-16 pl-0 pr-2 pt-1"
      id="report"
      style="border-radius: 3px; font-size: 13px"
      v-if="detailProduct"
    >
      <v-card-title class="" style="font-size: 16px">
        <v-row>
          <v-flex xs3 class="pa-2">
            Title:&nbsp;&nbsp; {{ detailProduct.title_english }}
            <br />
            ስም፡ &nbsp;&nbsp;{{ detailProduct.title_amharic }} <br />

            {{ detailProduct.getSubCategory[0].getCategory[0].title_english }}
            <br />
            <v-btn
              class="blue--text pl-0 pr-0"
              text
              small
              @click="editProduct()"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-dialog v-model="deletePopUp" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="red--text"
                  text
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  <strong> Delete Product </strong>
                </v-card-title>
                <v-card-text>Are You Sure to delete this product ?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red darken-1" text @click="deleteProduct()">
                    <v-icon>delete</v-icon>Yes
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deletePopUp = false"
                  >
                    <v-icon>arrow_back_ios</v-icon> No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
          <v-flex xs3 class="pa-2">
            Price:&nbsp;&nbsp; <strong> {{ detailProduct.price }}</strong>

            ETB
            <br />
            Total Stock:&nbsp;&nbsp; <strong> {{ detailProduct.stock }}</strong>
          </v-flex>
          <v-flex xs5 class="pa-2">
            Description:&nbsp;&nbsp; {{ detailProduct.description_english }}
            <br />
            {{ detailProduct.description_amharic }}
          </v-flex>
          <v-flex xs1>
            <v-btn
              color="deep-purple lighten-2"
              text
              fab
              @click="$router.go(-1)"
            >
              <v-icon> clear </v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs12 class="pt-0 pb-0">
            <v-divider></v-divider>
          </v-flex>
        </v-row>
      </v-card-title>

      <span
        v-for="(productColor, i) in detailProduct.colors.split(',')"
        :key="i + 100"
        class="pl-3 pr-2"
      >
        <v-layout
          row
          wrap
          v-if="selectedColor.trim() == productColor.trim()"
          class="ma-1 mt-0"
        >
          <v-flex xs6 sm3 md2 class="pa-2 pt-0">
            <blur-hash-image
              :hash="
                detailProduct.blurhash
                  .split('*product#')
                  [i].split(',pimg')[0]
                  .trim()
              "
              :src="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[0]
                  .trim()
              "
              height="50"
              width="50"
              v-if="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[0]
                  .trim() != ''
              "
            />
            <!-- <v-img :src="detailProduct.image_one" height="200"></v-img> -->
          </v-flex>
          <v-flex xs6 sm3 md2 class="pa-2 pt-0">
            <blur-hash-image
              :hash="
                detailProduct.blurhash
                  .split('*product#')
                  [i].split(',pimg')[1]
                  .trim()
              "
              :src="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[1]
                  .trim()
              "
              height="50"
              width="50"
              v-if="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[1]
                  .trim() != ''
              "
            />
            <!-- <v-img :src="detailProduct.image_two" height="200"></v-img> -->
          </v-flex>
          <v-flex xs6 sm3 md2 class="pa-2 pt-0">
            <blur-hash-image
              :hash="
                detailProduct.blurhash
                  .split('*product#')
                  [i].split(',pimg')[2]
                  .trim()
              "
              :src="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[2]
                  .trim()
              "
              height="50"
              width="50"
              v-if="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[2]
                  .trim() != ''
              "
            />
            <!-- <v-img
                      :src="detailProduct.image_three"
                      height="200"
                    ></v-img> -->
          </v-flex>
          <v-flex xs6 sm3 md2 class="pa-2 pt-0">
            <blur-hash-image
              :hash="
                detailProduct.blurhash
                  .split('*product#')
                  [i].split(',pimg')[3]
                  .trim()
              "
              :src="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[3]
                  .trim()
              "
              height="50"
              width="50"
              v-if="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[3]
                  .trim() != ''
              "
            />
            <!-- <v-img
                      :src="detailProduct.image_four"
                      height="200"
                    ></v-img> -->
          </v-flex>

          <v-flex xs6 sm3 md2 class="pa-2 pt-0">
            <blur-hash-image
              :hash="
                detailProduct.blurhash
                  .split('*product#')
                  [i].split(',pimg')[4]
                  .trim()
              "
              :src="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[4]
                  .trim()
              "
              height="50"
              width="50"
              v-if="
                detailProduct.image
                  .split('*product#')
                  [i].split(',pimg')[4]
                  .trim() != ''
              "
            />
            <!-- <v-img :src="detailProduct.image_five" height="200"></v-img> -->
          </v-flex>
          <v-flex xs6 sm3 md2 class="pa-2 text-center pt-0">
            <v-layout
              row
              wrap
              v-for="(size, k) in detailProduct.sizes
                .split('*product#')
                [i].split(',')"
              :key="k"
            >
              <v-flex xs6>
                <span>{{ size }}</span>
              </v-flex>
              <v-flex xs6>
                <span>{{
                  detailProduct.stocks.split("*product#")[i].split(",")[k]
                }}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </span>
      <v-card-text class="mt-10 ml-15">
        <v-row>
          <v-flex
            xs1
            v-for="(color, k) in detailProduct.colors.split(',')"
            :key="100 + k"
          >
            <v-btn @click="selectedColor = color.trim()" :color="color.trim()">
            </v-btn>
          </v-flex>
        </v-row>
      </v-card-text>

      <!-- <v-divider></v-divider> -->
    </div>
  </div>

  <div v-else id="error" class="text-center mt-16">
    <p class="red--text text-center">Access Denied</p>
  </div>
</template>

<script>
import axios from "axios";
import noAccess from "@/components/noAccess";

export default {
  components: {
    noAccess,
  },
  props: ["detailProduct"],
  data() {
    return {
      colors: [],
      colorNames: [],
      hexColors: [],
      size: [],
      stocks: [],
      stockPrice: [],
      selectedColor: "",
      detailProductPopUp: true,
      headers: [
        {
          text: "Product/ ክምችት",
          align: "start",
          sortable: false,
          value: "amount",
        },
        {
          text: "Price/ ዋጋ",
          align: "start",
          sortable: false,
          value: "price",
        },
      ],
      tableContent: [],
      comments: [],
      rate: 0,
      rateLength: 0,
      star1: 0,
      star2: 0,
      star3: 0,
      star4: 0,
      star5: 0,
      deletePopUp: false,
      status: "",
      snackbar: false,
    };
  },
  methods: {
    createStocks() {
      this.stocks = [];
      this.stockPrice = [];
      this.tableContent = [];
      this.stocks = this.detailProduct.stock.split(",");
      this.stockPrice = this.detailProduct.stockPrice.split(",");

      for (let i = 0; i < this.stocks.length; i++) {
        this.tableContent.push({
          amount: this.stocks[i].trim(),
          price: this.stockPrice[i].trim(),
        });
      }
    },
    async fetchCommentsAndReviews() {
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                   query {
                      getReviewByProductId(product_id: ${this.detailProduct.product_id}, tableName: "${this.detailProduct.tableName}") {
                             comment
                             rating_value
                            }
                    }

                  
                `,
          },
        });
        this.comments = [];

        this.rate = 0.0;
        this.rateLength = 0;
        this.star1 = 0;
        this.star2 = 0;
        this.star3 = 0;
        this.star4 = 0;
        this.star5 = 0;
        for (let i = 0; i < result.data.data.getReviewByProductId.length; i++) {
          if (result.data.data.getReviewByProductId[i]["rating_value"] > 0) {
            this.rate =
              this.rate +
              result.data.data.getReviewByProductId[i]["rating_value"];
            this.rateLength++;
            switch (result.data.data.getReviewByProductId[i]["rating_value"]) {
              case 1:
                this.star1++;
                break;
              case 2:
                this.star2++;
                break;
              case 3:
                this.star3++;
                break;
              case 4:
                this.star4++;
                break;
              case 5:
                this.star5++;
                break;
            }
          }
          if (result.data.data.getReviewByProductId[i]["comment"] != "")
            this.comments.push(
              result.data.data.getReviewByProductId[i]["comment"]
            );

          // rate = rate~/repository.length;
        }
        this.rate = Math.round(this.rate / this.rateLength);
      } catch (err) {
        alert(err);
      }
    },
    async deleteProduct() {
      this.deletePopUp = false;
      try {
        var result = await axios({
          method: "POST",
          url: this.$store.state.url,
          data: {
            query: `
                 mutation {
                      deleteProduct(product_id: ${this.detailProduct.product_id}) {
                        statusMessage
                      }
                    }
                `,
          },
        });
        if (result.data.data.deleteProduct[0].statusMessage) {
          alert("Product Deleted Successfully");
          this.$router
            .push({
              name: "products",
            })
            .catch((err) => {});
        } else {
          alert("Something went wrong, please try again!");
        }
      } catch (err) {
        alert(err);
      }
    },
    editProduct() {
      this.$router
        .push({
          name: "editProduct",
          params: {
            editProduct: this.detailProduct,
          },
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  // mounted() {
  //   if (this.detailProduct.product_color != "") {
  //     this.hexColors = [];
  //     this.colors = [];
  //     this.colorNames = [];
  //     this.hexColors = this.detailProduct.product_color.split(",");
  //     this.colorNames = this.detailProduct.product_color_name.split(",");

  //     for (let i = 0; i < this.hexColors.length; i++) {
  //       this.colors.push("#" + this.hexColors[i].trim().substring(4, 10));
  //     }
  //   }
  //   if (this.detailProduct.measure_type != "") {
  //     this.size = [];
  //     this.size = this.detailProduct.measure_type.split(",");
  //   }
  // },
  created() {
    if (this.$store.state.admin != "") {
      if (this.detailProduct == "" || this.detailProduct == undefined)
        this.$router.push({ name: "products" });
      else this.selectedColor = this.detailProduct.colors.split(",")[0];
    } else this.$router.push({ name: "login" });
    // this.createStocks();
    // this.fetchCommentsAndReviews();
  },
};
</script>
<style scoped>
#report {
  max-width: 1110px;
  margin: auto;
}
</style>
